import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, ArrowRight, PartyPopper, DollarSign } from 'lucide-react';
import { motion } from 'framer-motion';
import confetti from 'canvas-confetti';
import type { Sale } from '../../../types';
import { formatCurrency, formatEuro } from '../../../utils/format';
import { formatDateDisplay } from '../../../utils/date';
import BlurredNumber from '../../../components/ui/BlurredNumber';

interface LastSaleCardProps {
  sale: Sale;
  commission: number;
}

export default function LastSaleCard({ sale, commission }: LastSaleCardProps) {
  const confettiRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (confettiRef.current) {
      const rect = confettiRef.current.getBoundingClientRect();
      const x = (rect.left + rect.right) / 2;
      const y = (rect.top + rect.bottom) / 2;

      confetti({
        particleCount: 100,
        spread: 70,
        origin: { 
          x: x / window.innerWidth,
          y: y / window.innerHeight
        },
        colors: ['#60A5FA', '#34D399', '#A78BFA'],
        zIndex: 999
      });
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl border border-gray-700 p-6 text-white relative overflow-hidden"
    >
      {/* Confetti Container */}
      <div ref={confettiRef} className="absolute inset-0" />

      {/* Glowing Orb Background Effect */}
      <div className="absolute top-0 right-0 w-64 h-64 bg-blue-500/20 rounded-full blur-3xl" />
      <div className="absolute bottom-0 left-0 w-64 h-64 bg-purple-500/20 rounded-full blur-3xl" />

      {/* Content */}
      <div className="relative">
        <div className="flex items-center gap-4 mb-6">
          <div className="p-3 bg-gray-800/50 rounded-xl border border-gray-700">
            <PartyPopper className="w-8 h-8 text-yellow-500" />
          </div>
          <div>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <h3 className="text-lg font-medium text-gray-200">Dernière vente</h3>
              <p className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                {sale.project}
              </p>
            </motion.div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <div>
            <p className="text-sm text-gray-400 mb-1">Prix de vente</p>
            <motion.p
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.3 }}
              className="text-2xl font-bold text-white"
            >
              <BlurredNumber value={formatCurrency(sale.propertyPrice)} />
            </motion.p>
            <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-blue-200 bg-blue-500/10 border border-blue-500/20 rounded-full">
              <BlurredNumber value={formatEuro(sale.propertyPrice)} />
            </span>
          </div>

          <div>
            <p className="text-sm text-gray-400 mb-1">Commission</p>
            <motion.p
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.4 }}
              className="text-2xl font-bold text-white"
            >
              <BlurredNumber value={formatCurrency(commission)} />
            </motion.p>
            <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-green-200 bg-green-500/10 border border-green-500/20 rounded-full">
              <BlurredNumber value={formatEuro(commission)} />
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 text-gray-400">
            <Calendar className="w-4 h-4" />
            <span>{formatDateDisplay(sale.saleDate)}</span>
          </div>

          
        </div>
      </div>
    </motion.div>
  );
}