import React from 'react';
import { Gift, User } from 'lucide-react';
import { useAgents } from '../../hooks/useAgents';
import { formatCurrency } from '../../utils/format';

interface BonusSectionProps {
  formData: {
    bonus?: {
      amount: number;
      secondAgentId?: string;
      paid?: boolean;
    };
    agentId: string;
  };
  onChange: (data: { bonus?: { amount: number; secondAgentId?: string; paid?: boolean } }) => void;
}

export default function BonusSection({ formData, onChange }: BonusSectionProps) {
  const { agents } = useAgents();

  // Filter out the current agent from second agent options
  const secondAgentOptions = agents.filter(agent => agent.id !== formData.agentId);

  const calculateSplits = () => {
    if (!formData.bonus?.amount) return null;

    const amount = formData.bonus.amount;
    if (formData.bonus.secondAgentId) {
      return {
        agent1: formatCurrency(amount * 0.3),
        agent2: formatCurrency(amount * 0.3),
        agency: formatCurrency(amount * 0.4)
      };
    }
    return {
      agent: formatCurrency(amount * 0.6),
      agency: formatCurrency(amount * 0.4)
    };
  };

  const splits = calculateSplits();

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <Gift className="w-5 h-5 text-purple-600" />
          <h3 className="font-medium">Bonus</h3>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Montant du bonus
          </label>
          <input
            type="number"
            value={formData.bonus?.amount || ''}
            onChange={(e) => onChange({
              bonus: {
                ...formData.bonus,
                amount: Number(e.target.value)
              }
            })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Montant"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Agent secondaire (optionnel)
          </label>
          <select
            value={formData.bonus?.secondAgentId || ''}
            onChange={(e) => onChange({
              bonus: {
                ...formData.bonus,
                secondAgentId: e.target.value || undefined
              }
            })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
          >
            <option value="">Sélectionner un agent</option>
            {secondAgentOptions.map(agent => (
              <option key={agent.id} value={agent.id}>{agent.name}</option>
            ))}
          </select>
        </div>
      </div>

      {splits && (
        <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
          <h4 className="text-sm font-medium text-purple-900 mb-3">Répartition du bonus</h4>
          <div className="space-y-2">
            {formData.bonus?.secondAgentId ? (
              <>
                <div className="flex justify-between text-sm">
                  <span>Agent principal (30%)</span>
                  <span className="font-medium">{splits.agent1}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Agent secondaire (30%)</span>
                  <span className="font-medium">{splits.agent2}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Agence (40%)</span>
                  <span className="font-medium">{splits.agency}</span>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-between text-sm">
                  <span>Agent (60%)</span>
                  <span className="font-medium">{splits.agent}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span>Agence (40%)</span>
                  <span className="font-medium">{splits.agency}</span>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}