import React, { memo, useMemo } from 'react';
import { getInitials, getAvatarColor, AvatarSize, sizeClasses } from '../../utils/avatar';

interface AvatarProps {
  name: string;
  size?: AvatarSize;
  className?: string;
  imgUrl?: string;
}

const Avatar = memo(({ name, size = 'md', className = '', imgUrl }: AvatarProps) => {
  // Memoize initials and color calculations
  const { initials, color } = useMemo(() => ({
    initials: getInitials(name),
    color: getAvatarColor(name)
  }), [name]);

  if (imgUrl) {
    return (
      <div 
        className={`${sizeClasses[size]} rounded-full overflow-hidden flex-shrink-0 ${className}`}
      >
        <img 
          src={imgUrl} 
          alt={name}
          className="w-full h-full object-cover"
          loading="lazy"
        />
      </div>
    );
  }

  return (
    <div 
      className={`${sizeClasses[size]} rounded-full flex items-center justify-center text-white font-bold select-none flex-shrink-0 ${className}`}
      style={{ 
        backgroundColor: color,
        willChange: 'transform',
        transition: 'none' // Disable color transition
      }}
    >
      {initials}
    </div>
  );
});

Avatar.displayName = 'Avatar';

export default Avatar;