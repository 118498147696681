import React, { useState } from 'react';
import { X, Save, Bot, MessageSquare, RefreshCw } from 'lucide-react';
import type { Settings } from '../../types';
import { useToast } from '../../contexts/ToastContext';

interface AutomationSettingsProps {
  settings: Settings | null;
  onClose: () => void;
}

export default function AutomationSettings({ settings, onClose }: AutomationSettingsProps) {
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    zapier: {
      webhookUrl: settings?.zapier?.webhookUrl || '',
      enabled: settings?.zapier?.enabled || false
    },
    synthflow: {
      apiKey: settings?.synthflow?.apiKey || '',
      enabled: settings?.synthflow?.enabled || false,
      callScript: settings?.synthflow?.callScript || `Bonjour, je suis l'assistant IA de Palm Dubai Immo.
Je vous appelle suite à votre demande d'information concernant nos biens immobiliers à Dubai.
Quand seriez-vous disponible pour un rendez-vous avec l'un de nos agents ?`
    },
    openai: {
      apiKey: settings?.openai?.apiKey || '',
      enabled: settings?.openai?.enabled || false,
      messagePrompt: settings?.openai?.messagePrompt || `Tu es un agent immobilier professionnel à Dubai.
Génère un message WhatsApp personnalisé pour {{name}} qui s'intéresse à l'immobilier à Dubai.
Le message doit être courtois, professionnel et encourager une prise de rendez-vous.`
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Update settings in Firebase
      showToast('success', 'Paramètres d\'automatisation mis à jour');
      onClose();
    } catch (error) {
      showToast('error', 'Erreur lors de la mise à jour des paramètres');
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-start justify-center z-50 overflow-y-auto">
      <div className="w-full max-w-4xl bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl m-4 sm:m-6">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Bot className="w-6 h-6 text-blue-600" />
              <h2 className="text-xl font-semibold">Paramètres d'automatisation</h2>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-8">
          {/* Zapier Integration */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <RefreshCw className="w-5 h-5 text-purple-600" />
                <h3 className="font-medium">Intégration Zapier</h3>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.zapier.enabled}
                  onChange={(e) => setFormData({
                    ...formData,
                    zapier: { ...formData.zapier, enabled: e.target.checked }
                  })}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <input
              type="url"
              value={formData.zapier.webhookUrl}
              onChange={(e) => setFormData({
                ...formData,
                zapier: { ...formData.zapier, webhookUrl: e.target.value }
              })}
              placeholder="URL du webhook Zapier"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Synthflow Integration */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Bot className="w-5 h-5 text-green-600" />
                <h3 className="font-medium">Intégration Synthflow (Appels IA)</h3>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.synthflow.enabled}
                  onChange={(e) => setFormData({
                    ...formData,
                    synthflow: { ...formData.synthflow, enabled: e.target.checked }
                  })}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <input
              type="password"
              value={formData.synthflow.apiKey}
              onChange={(e) => setFormData({
                ...formData,
                synthflow: { ...formData.synthflow, apiKey: e.target.value }
              })}
              placeholder="Clé API Synthflow"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <textarea
              value={formData.synthflow.callScript}
              onChange={(e) => setFormData({
                ...formData,
                synthflow: { ...formData.synthflow, callScript: e.target.value }
              })}
              placeholder="Script d'appel"
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* OpenAI Integration */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <MessageSquare className="w-5 h-5 text-blue-600" />
                <h3 className="font-medium">Intégration OpenAI (Messages)</h3>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.openai.enabled}
                  onChange={(e) => setFormData({
                    ...formData,
                    openai: { ...formData.openai, enabled: e.target.checked }
                  })}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <input
              type="password"
              value={formData.openai.apiKey}
              onChange={(e) => setFormData({
                ...formData,
                openai: { ...formData.openai, apiKey: e.target.value }
              })}
              placeholder="Clé API OpenAI"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <textarea
              value={formData.openai.messagePrompt}
              onChange={(e) => setFormData({
                ...formData,
                openai: { ...formData.openai, messagePrompt: e.target.value }
              })}
              placeholder="Prompt pour la génération de messages"
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          <div className="flex justify-end pt-6 border-t border-gray-200">
            <button
              type="submit"
              className="px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center gap-2"
            >
              <Save className="w-4 h-4" />
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}