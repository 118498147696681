import { 
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  Timestamp,
  addDoc,
  setDoc,
  serverTimestamp
} from 'firebase/firestore';
import { db, auth, COLLECTIONS } from '../lib/firebase';
import type { User } from '../types';

export async function verifyCollections() {
  try {
    // Create collections if they don't exist
    for (const collectionName of Object.values(COLLECTIONS)) {
      const collectionRef = collection(db, collectionName);
      await getDocs(collectionRef);
    }

    return true;
  } catch (error) {
    console.error('Error verifying collections:', error);
    throw error;
  }
}

export async function getDatabaseStats() {
  try {
    const stats = {
      collections: {} as Record<string, { count: number; size: number }>,
      totalSize: 0,
      lastBackup: new Date().toISOString() // Mock data
    };

    // Get stats for each collection
    for (const collectionName of Object.values(COLLECTIONS)) {
      const snapshot = await getDocs(collection(db, collectionName));
      const size = JSON.stringify(snapshot.docs.map(doc => doc.data())).length;
      
      stats.collections[collectionName] = {
        count: snapshot.size,
        size
      };
      stats.totalSize += size;
    }

    return stats;
  } catch (error) {
    console.error('Error getting database stats:', error);
    throw error;
  }
}

export async function getLoginLogs() {
  try {
    // Get logs from the last 30 days
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const logsRef = collection(db, COLLECTIONS.LOGIN_LOGS);
    const q = query(
      logsRef,
      where('timestamp', '>=', Timestamp.fromDate(thirtyDaysAgo)),
      orderBy('timestamp', 'desc'),
      limit(30)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      ...doc.data(),
      timestamp: doc.data().timestamp.toDate().toISOString()
    }));
  } catch (error) {
    console.error('Error fetching login logs:', error);
    return [];
  }
}