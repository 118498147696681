import React from 'react';
import { motion } from 'framer-motion';
import { X, Trophy, Calendar, Crown, Clock, Gift } from 'lucide-react';
import { formatCurrency } from '../../../utils/format';
import BlurredNumber from '../../../components/ui/BlurredNumber';
import { useEffect, useState } from 'react';
import { useSales } from '../../../hooks/useSales';
import { useAgents } from '../../../hooks/useAgents';

interface Winner {
  month: string;
  year: number;
  agentName: string;
  volume: number;
}

interface WinnersHistoryModalProps {
  onClose: () => void;
  winners: Winner[];
  currentMonth: string;
  daysRemaining: number;
}

export default function WinnersHistoryModal({ 
  onClose, 
  currentMonth,
  daysRemaining
}: WinnersHistoryModalProps) {
  const { sales } = useSales();
  const { agents } = useAgents();
  const [winners, setWinners] = useState<Winner[]>([]);

  useEffect(() => {
    // Calculate winners for each month since December 2024
    const calculateWinners = () => {
      const startDate = new Date(2024, 11); // December 2024
      const currentDate = new Date();
      const monthlyWinners: Winner[] = [];

      let date = new Date(startDate);
      while (date <= currentDate) {
        const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
        const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        // Get sales for this month
        const monthSales = sales.filter(sale => {
          const saleDate = new Date(sale.saleDate);
          return saleDate >= monthStart && saleDate <= monthEnd;
        });

        // Calculate volume per agent
        const agentVolumes = new Map<string, number>();
        monthSales.forEach(sale => {
          const currentVolume = agentVolumes.get(sale.agentId) || 0;
          agentVolumes.set(sale.agentId, currentVolume + sale.propertyPrice);
        });

        // Find winner
        let maxVolume = 0;
        let winnerId = '';
        agentVolumes.forEach((volume, agentId) => {
          if (volume > maxVolume) {
            maxVolume = volume;
            winnerId = agentId;
          }
        });

        const winner = agents.find(a => a.id === winnerId);
        if (maxVolume > 0 && winner) {
          monthlyWinners.push({
            month: date.toLocaleString('fr-FR', { month: 'long' }),
            year: date.getFullYear(),
            agentName: winner.name,
            volume: maxVolume
          });
        }

        date.setMonth(date.getMonth() + 1);
      }

      setWinners(monthlyWinners.reverse());
    };

    calculateWinners();
  }, [sales, agents]);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="w-full max-w-2xl bg-gradient-to-br from-purple-900 to-indigo-900 rounded-xl border border-purple-700/50 m-4 text-white relative overflow-hidden"
      >
        {/* Animated background elements */}
        <motion.div
          className="absolute top-0 right-0 w-64 h-64 bg-purple-500/20 rounded-full blur-3xl"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <motion.div
          className="absolute bottom-0 left-0 w-64 h-64 bg-blue-500/20 rounded-full blur-3xl"
          animate={{
            scale: [1.2, 1, 1.2],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />

        <div className="p-6 border-b border-white/10 relative">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <motion.div
                animate={{
                  rotate: [0, 10, 0, -10, 0],
                  scale: [1, 1.1, 1],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                className="p-3 bg-gradient-to-br from-yellow-400 to-yellow-500 rounded-xl shadow-lg"
              >
                <Trophy className="w-6 h-6 text-white" />
              </motion.div>
              <h2 className="text-xl font-semibold text-white">Historique des gagnants</h2>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-white/10 rounded-lg transition-colors text-white/80 hover:text-white"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="p-6 relative">
          {/* Current Month Status */}
          <div className="mb-8 p-4 bg-white/10 backdrop-blur-sm rounded-xl border border-white/20">
            <div className="flex items-center gap-3 mb-3">
              <Calendar className="w-5 h-5 text-white" />
              <h3 className="font-medium text-white">{currentMonth}</h3>
            </div>
            <p className="text-sm text-white/80">
              {daysRemaining} jours restants pour atteindre l'objectif
            </p>
          </div>

          {/* Winners List */}
          <div className="space-y-4">
            {winners.map((winner, index) => (
              <motion.div
                key={`${winner.month}-${winner.year}`}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className={`p-4 rounded-xl border backdrop-blur-sm ${
                  index === 0
                    ? 'bg-yellow-500/10 border-yellow-500/30'
                    : 'bg-white/5 border-white/10'
                }`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <div className={`p-2 rounded-lg ${
                      index === 0 ? 'bg-yellow-500/20' : 'bg-white/10'
                    }`}>
                      {index === 0 ? (
                        <Crown className="w-5 h-5 text-yellow-400" />
                      ) : (
                        <Trophy className="w-5 h-5 text-white/80" />
                      )}
                    </div>
                    <div>
                      <div className="space-y-1">
                        <h4 className="font-medium text-white flex items-center gap-2">
                          {winner.agentName}
                          {index === 0 && (
                            <span className="px-2 py-0.5 bg-yellow-500/20 text-yellow-300 text-xs rounded-full border border-yellow-500/30">
                              Dernier gagnant
                            </span>
                          )}
                        </h4>
                    
                        <div className="flex items-center gap-2 mt-2">
                          <Gift className="w-4 h-4 text-purple-300" />
                          <p className="text-sm text-purple-200">
                            iPhone 16 Pro Max
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                
              
                </div>
              </motion.div>
            ))}

            {winners.length === 0 && (
              <div className="text-center py-12 bg-white/5 backdrop-blur-sm rounded-xl border border-white/10">
                <Trophy className="w-12 h-12 text-white/40 mx-auto mb-4" />
                <p className="text-white/60">Le premier gagnant sera annoncé le 1er janvier 2025</p>
                <p className="text-sm text-white/40 mt-2">Les résultats sont calculés automatiquement chaque 1er du mois</p>
              </div>
            )}
          </div>
          {/* Reward Info */}
          <div className="mt-8 p-4 bg-gradient-to-br from-purple-500/10 to-purple-600/5 rounded-xl border border-purple-500/20">
            <div className="flex items-center gap-3 mb-3">
              <Gift className="w-5 h-5 text-purple-300" />
              <h3 className="font-medium text-white">Informations sur les récompenses</h3>
            </div>
            <div className="space-y-3">
              <div className="flex items-center gap-2 text-white/80">
                <Clock className="w-4 h-4 flex-shrink-0" />
                <p className="text-sm">
                  Les récompenses sont distribuées après réception du paiement promoteur
                </p>
              </div>
              <p className="text-sm text-white/60 pl-6">
                Le délai habituel est de 30 à 90 jours après la signature du SPA
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}