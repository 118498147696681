import { useState, useEffect } from 'react';
import { useSales } from './useSales';

export function useAutocomplete() {
  const { sales } = useSales();
  const [projects, setProjects] = useState<string[]>([]);
  const [clients, setClients] = useState<string[]>([]);

  useEffect(() => {
    // Get unique projects
    const uniqueProjects = Array.from(new Set(sales.map(sale => sale.project)))
      .filter(Boolean)
      .sort();
    setProjects(uniqueProjects);

    // Get unique client names
    const uniqueClients = Array.from(new Set(sales.map(sale => sale.client.name)))
      .filter(Boolean)
      .sort();
    setClients(uniqueClients);
  }, [sales]);

  return {
    projects,
    clients
  };
}