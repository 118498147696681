import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, ArrowRight, PartyPopper, Building, User } from 'lucide-react';
import { motion } from 'framer-motion';
import confetti from 'canvas-confetti';
import type { Sale } from '../types';
import { formatCurrency, formatEuro } from '../utils/format';
import { formatDateDisplay } from '../utils/date';
import { useAgents } from '../hooks/useAgents';
import Avatar from './ui/Avatar';
import BlurredNumber from './ui/BlurredNumber';

interface LastSaleCardProps {
  sale: Sale;
}

export default function LastSaleCard({ sale }: LastSaleCardProps) {
  const confettiRef = useRef<HTMLDivElement>(null);
  const { agents } = useAgents();
  const navigate = useNavigate();
  
  const agent = agents.find(a => a.id === sale.agentId);
  const referrer = sale.referrerId ? agents.find(a => a.id === sale.referrerId) : null;

  useEffect(() => {
    if (confettiRef.current) {
      const rect = confettiRef.current.getBoundingClientRect();
      const x = (rect.left + rect.right) / 2;
      const y = (rect.top + rect.bottom) / 2;

      confetti({
        particleCount: 100,
        spread: 70,
        origin: { 
          x: x / window.innerWidth,
          y: y / window.innerHeight
        },
        colors: ['#60A5FA', '#34D399', '#A78BFA'],
        zIndex: 999
      });
    }
  }, []);

  const handleAgentClick = (e: React.MouseEvent, agentId: string) => {
    e.stopPropagation();
    navigate(`/agents/${agentId}`);
  };

  const handleCardClick = () => {
    navigate(`/sales/${sale.id}`);
  };

  return (
    <motion.div
      onClick={handleCardClick}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl overflow-hidden shadow-xl relative min-h-[400px] cursor-pointer"
    >
      {/* Confetti Container */}
      <div ref={confettiRef} className="absolute inset-0" />

      {/* Glowing Orb Background Effect */}
      <div className="absolute top-0 right-0 w-64 h-64 bg-blue-500/20 rounded-full blur-3xl" />
      <div className="absolute bottom-0 left-0 w-64 h-64 bg-purple-500/20 rounded-full blur-3xl" />

      {/* Content */}
      <div className="relative p-6 h-full flex flex-col">
        <div className="flex items-center gap-4 mb-6">
          <div className="p-3 bg-gray-800/50 rounded-xl border border-gray-700">
            <PartyPopper className="w-8 h-8 text-yellow-500" />
          </div>
          <div>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
            >
              <h3 className="text-lg font-medium text-gray-200">Dernière vente</h3>
              <p className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                {sale.project}
              </p>
            </motion.div>
          </div>
        </div>

        <div className="flex-1">
          <div className="grid grid-cols-2 gap-6 mb-6">
            <div>
              <p className="text-sm text-gray-400 mb-1">Prix de vente</p>
              <motion.p
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.3 }}
                className="text-2xl font-bold text-white"
              >
                <BlurredNumber value={formatCurrency(sale.propertyPrice)} />
              </motion.p>
              <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-blue-200 bg-blue-500/10 border border-blue-500/20 rounded-full">
                <BlurredNumber value={formatEuro(sale.propertyPrice)} />
              </span>
            </div>

            <div>
              <p className="text-sm text-gray-400 mb-1">Unité</p>
              <p className="text-2xl font-bold text-white">{sale.unitNumber}</p>
            </div>
          </div>

          {/* Agents Section */}
          <div className="p-4 bg-gray-800/50 rounded-xl border border-gray-700/50 backdrop-blur-sm mb-6">
            <div className="grid grid-cols-2 gap-4">
              {agent && (
                <div className="flex items-center gap-3">
                  <Avatar name={agent.name} size="sm" />
                  <div>
                    <button
                      onClick={(e) => handleAgentClick(e, agent.id)}
                      className="text-sm font-medium text-blue-400 hover:text-blue-300"
                    >
                      {agent.name}
                    </button>
                    <p className="text-xs text-gray-500">Agent principal</p>
                  </div>
                </div>
              )}

              {referrer && (
                <div className="flex items-center gap-3">
                  <Avatar name={referrer.name} size="sm" />
                  <div>
                    <button
                      onClick={(e) => handleAgentClick(e, referrer.id)}
                      className="text-sm font-medium text-amber-400 hover:text-amber-300"
                    >
                      {referrer.name}
                    </button>
                    <p className="text-xs text-gray-500">Parrain</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-auto pt-4 border-t border-gray-700/50 flex items-center justify-between">
          <div className="flex items-center gap-2 text-gray-400">
            <Calendar className="w-4 h-4" />
            <span className="text-sm">{formatDateDisplay(sale.saleDate)}</span>
          </div>

          <div className="inline-flex items-center gap-2 px-4 py-2 bg-blue-500/10 text-blue-300 rounded-lg hover:bg-blue-500/20 transition-colors border border-blue-500/20">
            <span>Voir les détails</span>
            <ArrowRight className="w-4 h-4" />
          </div>
        </div>
      </div>
    </motion.div>
  );
}