// Hardcoded agent credentials for demo
export const agentCredentials = [
  {
    id: 'NEYk4DuOwlzsfEh41rLH',
    email: 'sharaf@palmdubai.fr',
    password: 'palm2024',
    name: 'Sharaf',
    role: 'agent',
    directAccess: true
  },
  {
    id: 'agent2',
    email: 'jeremy@palmdubai.fr',
    password: 'palm2024',
    name: 'Jeremy',
    role: 'agent',
    directAccess: true
  },
  {
    id: 'agent3',
    email: 'test@palmdubai.fr',
    password: 'palm2024',
    name: 'Test Agent',
    role: 'agent',
    directAccess: true
  }
];

export function verifyAgentCredentials(email: string, password: string) {
  const agent = agentCredentials.find(a => 
    a.email.toLowerCase() === email.toLowerCase() && 
    a.password === password
  );
  
  // Log successful login
  if (agent) {
    console.log(`Agent ${agent.name} logged in successfully`);
  }
  
  return agent || null;
}