import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Building } from 'lucide-react';
import type { Sale } from '../../../types';

interface AgentSalesChartProps {
  sales: Sale[];
}

export default function AgentSalesChart({ sales }: AgentSalesChartProps) {
  const monthlyData = sales.reduce((acc, sale) => {
    const date = new Date(sale.saleDate);
    const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
    
    if (!acc[monthKey]) {
      acc[monthKey] = {
        month: monthKey,
        count: 0
      };
    }
    
    acc[monthKey].count += 1;
    
    return acc;
  }, {} as Record<string, { month: string; count: number; }>);

  const data = Object.values(monthlyData).slice(-6);

  return (
    <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
      <div className="p-6 border-b border-gray-200/50">
        <div className="flex items-center gap-3">
          <Building className="w-6 h-6 text-blue-600" />
          <h2 className="text-lg font-semibold">Volume des ventes</h2>
        </div>
      </div>
      
      <div className="p-6">
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis 
                dataKey="month" 
                tick={{ fontSize: 12 }}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                tick={{ fontSize: 12 }}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value} ventes`}
              />
              <Tooltip 
                formatter={(value: number) => [`${value} ventes`, 'Nombre de ventes']}
              />
              <Bar
                dataKey="count"
                fill="#3B82F6"
                radius={[4, 4, 0, 0]}
                name="Ventes"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}