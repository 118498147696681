import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { useSales } from '../../hooks/useSales';
import { DollarSign, Search, Calendar, Gift } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function AgentCommissions() {
  const { agent, slug } = useAgentSlug();
  const { sales, calculateCommission } = useSales();
  const [activeTab, setActiveTab] = useState<'pending' | 'paid'>('pending');
  const [searchQuery, setSearchQuery] = useState('');

  if (!agent) return null;

  // Calculate total stats
  const stats = sales
    .filter(sale => sale.agentId === agent.id || sale.referrerId === agent.id)
    .reduce((acc, sale) => {
      const commission = calculateCommission(sale);
      const isMainAgent = sale.agentId === agent.id;
      const myCommission = isMainAgent ? commission.agent : commission.referrer || 0;
      const isPaid = isMainAgent ? sale.paymentStatus.agent : sale.paymentStatus.referrer;

      acc.total += myCommission;
      if (isPaid) {
        acc.received += myCommission;
      } else {
        acc.pending += myCommission;
      }

      // Add bonus if exists
      if (sale.bonus) {
        const bonus = isMainAgent ? commission.bonus.agent : commission.bonus.referrer;
        acc.total += bonus;
        if (sale.paymentStatus.bonus) {
          acc.received += bonus;
        } else {
          acc.pending += bonus;
        }
      }

      return acc;
    }, { total: 0, received: 0, pending: 0 });

  // Filter sales for this agent
  const agentSales = sales
    .filter(sale => sale.agentId === agent.id || sale.referrerId === agent.id)
    .map(sale => {
      const commission = calculateCommission(sale);
      const isMainAgent = sale.agentId === agent.id;
      return {
        ...sale,
        commission: isMainAgent ? commission.agent : commission.referrer || 0,
        bonus: isMainAgent ? commission.bonus.agent : commission.bonus.referrer,
        isPaid: isMainAgent ? sale.paymentStatus.agent : sale.paymentStatus.referrer
      };
    })
    .filter(sale => {
      // Filter by search query
      if (searchQuery) {
        const query = searchQuery.toLowerCase();
        return (
          sale.project.toLowerCase().includes(query) ||
          sale.unitNumber.toLowerCase().includes(query)
        );
      }
      // Filter by tab
      return activeTab === 'paid' ? sale.isPaid : !sale.isPaid;
    })
    .sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <DollarSign className="w-6 h-6 text-blue-600" />
              <div>
                <h2 className="text-xl font-semibold">Mes Commissions</h2>
                <div className="flex items-center gap-4 mt-2 text-sm">
                  <div>
                    <span className="text-gray-500">Total :</span>{' '}
                    <span className="font-medium">
                      <BlurredNumber value={formatCurrency(stats.total)} />
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-500">Reçu :</span>{' '}
                    <span className="font-medium text-green-600">
                      <BlurredNumber value={formatCurrency(stats.received)} />
                    </span>
                  </div>
                  <div>
                    <span className="text-gray-500">En attente :</span>{' '}
                    <span className="font-medium text-amber-600">
                      <BlurredNumber value={formatCurrency(stats.pending)} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Tabs & Search */}
        <div className="p-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex gap-2">
              <button
                onClick={() => setActiveTab('pending')}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  activeTab === 'pending'
                    ? 'bg-amber-50 text-amber-700 border border-amber-200'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                En attente
              </button>
              <button
                onClick={() => setActiveTab('paid')}
                className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                  activeTab === 'paid'
                    ? 'bg-green-50 text-green-700 border border-green-200'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                Payées
              </button>
            </div>
            <div className="flex-1">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Rechercher une vente..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sales List */}
      <div className="space-y-4">
        {agentSales.map(sale => (
          <Link
            key={sale.id}
            to={`/agentcrm/${slug}/sales/${sale.id}`}
            className="block bg-white hover:bg-gradient-to-br hover:from-blue-50/50 hover:to-blue-100/20 border border-gray-200/50 hover:border-blue-200/50 rounded-xl overflow-hidden transition-all duration-200"
          >
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <h3 className="font-medium">{sale.project}</h3>
                  <p className="text-sm text-gray-500">Unité {sale.unitNumber}</p>
                  <div className="flex items-center gap-2 mt-2 text-sm text-gray-500">
                    <Calendar className="w-4 h-4" />
                    <span>{formatDateDisplay(sale.saleDate)}</span>
                  </div>
                </div>
                <div className="text-right">
                  <div className="flex items-center gap-2 mb-2">
                    <DollarSign className="w-4 h-4 text-blue-600" />
                    <p className="text-lg font-bold">
                      <BlurredNumber value={formatCurrency(sale.commission)} />
                    </p>
                  </div>
                  <p className="text-sm text-gray-500">
                    <BlurredNumber value={formatEuro(sale.commission)} />
                  </p>
                  {sale.bonus > 0 && (
                    <div className="flex items-center justify-end gap-1 mt-2 text-purple-600">
                      <Gift className="w-4 h-4" />
                      <span className="text-sm font-medium">
                        +<BlurredNumber value={formatCurrency(sale.bonus)} />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Link>
        ))}

        {agentSales.length === 0 && (
          <div className="text-center py-12 bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl">
            <DollarSign className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">
              Aucune commission {activeTab === 'paid' ? 'payée' : 'en attente'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}