import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Users, LogOut, ChevronDown, Eye, EyeOff } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../hooks/useAuth';
import { useVisibilityStore } from '../../store/visibilityStore';

export default function UserMenu() {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { hideNumbers, toggleNumberVisibility } = useVisibilityStore();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={menuRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-3 p-2 lg:p-4 rounded-xl hover:bg-gray-50 transition-colors w-full"
      >
        <div className="p-2 bg-gray-100 rounded-lg">
          <Users className="w-5 h-5 text-gray-600" />
        </div>
        <div className="hidden lg:block flex-1 min-w-0 text-left">
          <p className="text-sm font-medium text-gray-900 truncate">
            {user?.name}
          </p>
          <p className="text-xs text-gray-500 truncate">
            {user?.email}
          </p>
        </div>
        <ChevronDown className={`w-4 h-4 text-gray-400 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute z-50 right-0 mt-2 lg:mt-0 lg:bottom-full lg:mb-2 w-56 bg-white rounded-xl shadow-lg border border-gray-200/50 overflow-hidden"
          >
            <div className="p-2 space-y-1">
              <div className="lg:hidden px-3 py-2 border-b border-gray-200/50 mb-2">
                <p className="font-medium text-gray-900">{user?.name}</p>
                <p className="text-sm text-gray-500">{user?.email}</p>
              </div>
              
              <Link
                to="/profile"
                className="flex items-center gap-2 px-3 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                onClick={() => setIsOpen(false)}
              >
                <Users className="w-4 h-4" />
                Mon profil
              </Link>
              
              <button
                onClick={() => {
                  toggleNumberVisibility();
                  setIsOpen(false);
                }}
                className="flex items-center gap-2 px-3 py-2 text-sm text-gray-700 rounded-lg hover:bg-gray-50 transition-colors w-full"
              >
                {hideNumbers ? (
                  <>
                    <Eye className="w-4 h-4" />
                    Afficher les montants
                  </>
                ) : (
                  <>
                    <EyeOff className="w-4 h-4" />
                    Masquer les montants
                  </>
                )}
              </button>

              <button
                onClick={() => {
                  logout();
                  setIsOpen(false);
                }}
                className="flex items-center gap-2 px-3 py-2 text-sm text-red-600 rounded-lg hover:bg-red-50 transition-colors w-full"
              >
                <LogOut className="w-4 h-4" />
                Déconnexion
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}