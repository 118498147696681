import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface VisibilityState {
  hideNumbers: boolean;
  toggleNumberVisibility: () => void;
}

export const useVisibilityStore = create<VisibilityState>()(
  persist(
    (set) => ({
      hideNumbers: false,
      toggleNumberVisibility: () => set((state) => ({ hideNumbers: !state.hideNumbers })),
    }),
    {
      name: 'visibility-storage',
      getStorage: () => localStorage,
    }
  )
);