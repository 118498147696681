import React, { useState, useEffect } from 'react';
import { useSettings } from '../hooks/useSettings';
import { 
  Database, Shield, History, Save, Bell, Key, 
  Webhook, RefreshCw, Settings as SettingsIcon, 
  Download, HardDrive, Gauge, Mail, Send, Lock, ImageIcon, Target,
  FileText, DollarSign, Gift, Trophy
} from 'lucide-react';
import { verifyCollections, getLoginLogs, getDatabaseStats } from '../services/firebaseService';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useLanguage } from '../contexts/LanguageContext';
import { sendTestEmail } from '../services/emailService';
import { resetAllAgentPasswords } from '../utils/resetPasswords';
import { useToast } from '../contexts/ToastContext';
import EmailTemplateEditor from '../components/EmailTemplateEditor';

const EMAIL_TEMPLATES = {
  newSale: {
    name: 'Nouvelle vente',
    template: `
      <h2>Nouvelle vente enregistrée</h2>
      <p>Bonjour \${to_name},</p>
      <p>Une nouvelle vente a été enregistrée :</p>
      <ul>
        <li>Projet : \${project}</li>
        <li>Unité : \${unit}</li>
        <li>Prix : \${price}</li>
        <li>Date : \${date}</li>
      </ul>
      <p>Félicitations !</p>
    `,
    variables: {
      to_name: 'Nom du destinataire',
      project: 'Nom du projet',
      unit: 'Numéro d\'unité',
      price: 'Prix de vente',
      date: 'Date de la vente'
    }
  },
  commissionReceived: {
    name: 'Commission reçue',
    template: `
      <h2>Commission reçue</h2>
      <p>Bonjour \${to_name},</p>
      <p>La commission pour votre vente a été reçue :</p>
      <ul>
        <li>Projet : \${project}</li>
        <li>Commission : \${commission}</li>
      </ul>
      <p>Le paiement sera traité dans les plus brefs délais.</p>
    `,
    variables: {
      to_name: 'Nom du destinataire',
      project: 'Nom du projet',
      commission: 'Montant de la commission'
    }
  },
  newAgent: {
    name: 'Nouvel agent',
    template: `
      <h2>Bienvenue chez Palm Dubai Immo</h2>
      <p>Bonjour \${to_name},</p>
      <p>Voici vos identifiants de connexion :</p>
      <ul>
        <li>Email : \${email}</li>
        <li>Mot de passe : \${password}</li>
      </ul>
      <p>Connectez-vous sur : \${app_url}</p>
    `,
    variables: {
      to_name: 'Nom du destinataire',
      email: 'Email de l\'agent',
      password: 'Mot de passe',
      app_url: 'URL de l\'application'
    }
  }
};

export default function Settings() {
  const { settings, updateSettings } = useSettings();
  const { t } = useLanguage();
  const { showToast } = useToast();
  const [activeTab, setActiveTab] = useState<'general' | 'notifications' | 'database' | 'apis' | 'templates' | 'goals'>('general');
  const [isSaving, setIsSaving] = useState(false);
  const [isSendingTest, setIsSendingTest] = useState(false);
  const [isResettingPasswords, setIsResettingPasswords] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState<string | null>(null);
  const [showAgentIds, setShowAgentIds] = useState(false);
  const [dbStats, setDbStats] = useState<{
    collections: Record<string, { count: number; size: number }>;
    totalSize: number;
    lastBackup?: string;
  } | null>(null);
  const [monthlyGoal, setMonthlyGoal] = useState({
    volumeTarget: settings?.monthlyGoal?.volumeTarget || 3500000,
    reward: settings?.monthlyGoal?.reward || {
      type: 'car_rental',
      description: '2 mois de location de voiture offerts 🚗'
    }
  });

  const [formData, setFormData] = useState({
    name: settings?.name || 'PalmCRM',
    email: settings?.email || 'contact@palmdubaiimmo.com',
    notifications: settings?.notifications || {
      newSale: true,
      commissionReceived: true,
      agentCreated: true,
      loginAttempt: true
    },
    apis: {
      emailjs: {
        serviceId: settings?.emailjs?.serviceId || '',
        templateId: settings?.emailjs?.templateId || '',
        publicKey: settings?.emailjs?.publicKey || '',
      },
      whatsapp: {
        apiKey: settings?.whatsapp?.apiKey || '',
        instanceId: settings?.whatsapp?.instanceId || '',
      }
    },
    templates: settings?.templates || EMAIL_TEMPLATES
  });

  useEffect(() => {
    if (settings) {
      setFormData({
        name: settings.name,
        email: settings.email,
        notifications: settings.notifications,
        apis: {
          emailjs: settings.emailjs || { serviceId: '', templateId: '', publicKey: '' },
          whatsapp: settings.whatsapp || { apiKey: '', instanceId: '' }
        },
        templates: settings.templates || EMAIL_TEMPLATES
      });
    }
  }, [settings]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);
    setSuccess(null);

    try {
      await updateSettings({
        name: formData.name,
        email: formData.email,
        monthlyGoal: monthlyGoal,
        notifications: formData.notifications,
        emailjs: formData.apis.emailjs,
        whatsapp: formData.apis.whatsapp,
        templates: formData.templates
      });
      
      showToast('success', 'Paramètres enregistrés avec succès');
      setSuccess('Paramètres enregistrés avec succès');
    } catch (err) {
      console.error('Error saving settings:', err);
      setError('Erreur lors de la sauvegarde des paramètres');
      showToast('error', 'Erreur lors de la sauvegarde');
    } finally {
      setIsSaving(false);
    }
  };

  const handleTestEmail = async () => {
    setIsSendingTest(true);
    try {
      await sendTestEmail(
        formData.apis.emailjs.serviceId,
        formData.apis.emailjs.templateId,
        formData.apis.emailjs.publicKey
      );
      showToast('success', 'Email de test envoyé avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de l\'envoi de l\'email de test');
    } finally {
      setIsSendingTest(false);
    }
  };

  const handleResetPasswords = async () => {
    if (!window.confirm('Êtes-vous sûr de vouloir réinitialiser tous les mots de passe des agents ?')) {
      return;
    }

    setIsResettingPasswords(true);
    setError(null);
    setSuccess(null);

    try {
      await resetAllAgentPasswords();
      showToast('success', 'Tous les mots de passe ont été réinitialisés avec succès');
      setSuccess('Les mots de passe ont été réinitialisés à : LovePalmImmo');
    } catch (error) {
      console.error('Error resetting passwords:', error);
      setError('Erreur lors de la réinitialisation des mots de passe');
      showToast('error', 'Erreur lors de la réinitialisation des mots de passe');
    } finally {
      setIsResettingPasswords(false);
    }
  };

  const handleVerifyDatabase = async () => {
    setIsVerifying(true);
    try {
      await verifyCollections();
      const stats = await getDatabaseStats();
      setDbStats(stats);
      showToast('success', 'Base de données vérifiée avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de la vérification de la base de données');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleTemplateChange = (templateId: string, newContent: string) => {
    setFormData(prev => ({
      ...prev,
      templates: {
        ...prev.templates,
        [templateId]: {
          ...prev.templates[templateId],
          template: newContent
        }
      }
    }));
  };

  const tabs = [
    { id: 'general', label: 'Général', icon: SettingsIcon },
    { id: 'notifications', label: 'Notifications', icon: Bell },
    { id: 'goals', label: 'Objectifs', icon: Target },
    { id: 'templates', label: 'Templates', icon: FileText },
    { id: 'instagram', label: 'Instagram', icon: ImageIcon },
    { id: 'apis', label: 'APIs', icon: Webhook },
    { id: 'database', label: 'Base de données', icon: Database }
  ] as const;

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-3">
            <SettingsIcon className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Paramètres</h2>
          </div>
        </div>

        {error && (
          <div className="mx-6 mt-6 p-4 bg-red-50 text-red-600 rounded-lg">
            {error}
          </div>
        )}

        {success && (
          <div className="mx-6 mt-6 p-4 bg-green-50 text-green-600 rounded-lg">
            {success}
          </div>
        )}

        <div className="p-6">
          {/* Responsive Tab Navigation */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2 mb-6">
            {tabs.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setActiveTab(id as typeof activeTab)}
                className={`flex items-center justify-center gap-2 px-4 py-3 rounded-lg text-sm font-medium transition-all ${
                  activeTab === id
                    ? 'bg-blue-50 text-blue-600 border border-blue-200'
                    : 'bg-gray-50 text-gray-600 hover:bg-gray-100 border border-gray-200'
                }`}
              >
                <Icon className="w-4 h-4" />
                <span>{label}</span>
              </button>
            ))}
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {activeTab === 'general' && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Nom de l'application
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email de contact
                  </label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

                <div className="pt-6 border-t border-gray-200">
                  <div className="flex items-center gap-3 mb-4">
                    <Lock className="w-6 h-6 text-red-600" />
                    <h3 className="text-lg font-semibold">Réinitialisation des mots de passe</h3>
                  </div>
                  
                  <div className="p-4 bg-red-50 rounded-xl border border-red-200">
                    <p className="text-sm text-red-700 mb-4">
                      Cette action réinitialisera les mots de passe de tous les agents à : LovePalmImmo
                    </p>
                    <button
                      type="button"
                      onClick={handleResetPasswords}
                      disabled={isResettingPasswords}
                      className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                    >
                      <Key className="w-4 h-4" />
                      {isResettingPasswords ? 'Réinitialisation...' : 'Réinitialiser tous les mots de passe'}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'notifications' && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {Object.entries(formData.notifications).map(([key, value]) => (
                    <div key={key} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                      <span className="text-sm font-medium text-gray-700">
                        {key === 'newSale' ? 'Nouvelle vente' :
                         key === 'commissionReceived' ? 'Commission reçue' :
                         key === 'agentCreated' ? 'Nouvel agent' :
                         'Tentative de connexion'}
                      </span>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={value}
                          onChange={(e) => setFormData({
                            ...formData,
                            notifications: {
                              ...formData.notifications,
                              [key]: e.target.checked
                            }
                          })}
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {activeTab === 'goals' && (
              <div className="space-y-6">
                <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
                  <div className="flex items-center gap-3 mb-4">
                    <Target className="w-5 h-5 text-purple-600" />
                    <h3 className="font-medium">Objectif mensuel</h3>
                  </div>
                  
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Objectif de volume (AED)
                      </label>
                      <div className="relative">
                        <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                        <input
                          type="number"
                          value={monthlyGoal.volumeTarget}
                          onChange={(e) => setMonthlyGoal({
                            ...monthlyGoal,
                            volumeTarget: Number(e.target.value)
                          })}
                          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                          placeholder="Ex: 3500000"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Description de la récompense
                      </label>
                      <div className="relative">
                        <Gift className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
                        <textarea
                          value={monthlyGoal.reward.description}
                          onChange={(e) => setMonthlyGoal({
                            ...monthlyGoal,
                            reward: {
                              ...monthlyGoal.reward,
                              description: e.target.value
                            }
                          })}
                          rows={3}
                          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                          placeholder="Ex: 2 mois de location de voiture offerts 🚗"
                        />
                      </div>
                    </div>

                    <div className="p-4 bg-gradient-to-br from-yellow-50 to-yellow-100/50 rounded-lg border border-yellow-200/50">
                      <div className="flex items-center gap-2 text-yellow-800">
                        <Trophy className="w-5 h-5" />
                        <p className="text-sm font-medium">
                          L'objectif sera automatiquement mis à jour pour tous les agents
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'templates' && (
              <div className="space-y-6">
                <div className="grid grid-cols-1 gap-6">
                  {Object.entries(formData.templates).map(([id, template]) => (
                    <div key={id} className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="font-medium">{template.name}</h3>
                        <button
                          type="button"
                          onClick={() => setEditingTemplate(editingTemplate === id ? null : id)}
                          className="px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                        >
                          {editingTemplate === id ? 'Fermer' : 'Modifier'}
                        </button>
                      </div>

                      {editingTemplate === id && (
                        <EmailTemplateEditor
                          template={template.template}
                          onChange={(content) => handleTemplateChange(id, content)}
                          onClose={() => setEditingTemplate(null)}
                          variables={template.variables}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {activeTab === 'apis' && (
              <div className="space-y-6">
                <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
                  <div className="flex items-center gap-3 mb-4">
                    <Mail className="w-5 h-5 text-blue-600" />
                    <h3 className="font-medium">Configuration EmailJS</h3>
                  </div>
                  
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Service ID
                      </label>
                      <input
                        type="text"
                        value={formData.apis.emailjs.serviceId}
                        onChange={(e) => setFormData({
                          ...formData,
                          apis: {
                            ...formData.apis,
                            emailjs: {
                              ...formData.apis.emailjs,
                              serviceId: e.target.value
                            }
                          }
                        })}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Template ID
                      </label>
                      <input
                        type="text"
                        value={formData.apis.emailjs.templateId}
                        onChange={(e) => setFormData({
                          ...formData,
                          apis: {
                            ...formData.apis,
                            emailjs: {
                              ...formData.apis.emailjs,
                              templateId: e.target.value
                            }
                          }
                        })}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Public Key
                      </label>
                      <input
                        type="text"
                        value={formData.apis.emailjs.publicKey}
                        onChange={(e) => setFormData({
                          ...formData,
                          apis: {
                            ...formData.apis,
                            emailjs: {
                              ...formData.apis.emailjs,
                              publicKey: e.target.value
                            }
                          }
                        })}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </div>

                    <button
                      type="button"
                      onClick={handleTestEmail}
                      disabled={isSendingTest}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 flex items-center gap-2"
                    >
                      <Send className="w-4 h-4" />
                      {isSendingTest ? 'Envoi...' : 'Envoyer un email test'}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'database' && (
              <div className="space-y-6">
                <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-3">
                      <Database className="w-5 h-5 text-blue-600" />
                      <h3 className="font-medium">État de la base de données</h3>
                    </div>
                    <button
                      type="button"
                      onClick={handleVerifyDatabase}
                      disabled={isVerifying}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 flex items-center gap-2"
                    >
                      <RefreshCw className={`w-4 h-4 ${isVerifying ? 'animate-spin' : ''}`} />
                      {isVerifying ? 'Vérification...' : 'Vérifier'}
                    </button>
                  </div>

                  {dbStats && (
                    <div className="space-y-4">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {Object.entries(dbStats.collections).map(([name, stats]) => (
                          <div key={name} className="p-4 bg-white rounded-lg border border-gray-200">
                            <h4 className="font-medium mb-2">{name}</h4>
                            <div className="space-y-1 text-sm text-gray-600">
                              <p>Documents : {stats.count}</p>
                              <p>Taille : {(stats.size / 1024).toFixed(2)} KB</p>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="flex items-center justify-between p-4 bg-white rounded-lg border border-gray-200">
                        <div>
                          <h4 className="font-medium">Taille totale</h4>
                          <p className="text-sm text-gray-600">
                            {(dbStats.totalSize / (1024 * 1024)).toFixed(2)} MB
                          </p>
                        </div>
                        {dbStats.lastBackup && (
                          <div className="text-right">
                            <h4 className="font-medium">Dernière sauvegarde</h4>
                            <p className="text-sm text-gray-600">
                              {formatDistanceToNow(new Date(dbStats.lastBackup), {
                                locale: fr,
                                addSuffix: true
                              })}
                            </p>
                          </div>
                        )}
                      </div>

                      <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
                        <div className="flex items-center justify-between mb-4">
                          <h3 className="font-medium">Liste des agents et leurs IDs</h3>
                          <button
                            onClick={() => setShowAgentIds(!showAgentIds)}
                            className="text-sm text-blue-600 hover:text-blue-700"
                          >
                            {showAgentIds ? 'Masquer' : 'Afficher'} les IDs
                          </button>
                        </div>
                        {showAgentIds && (
                          <div className="space-y-2">
                            {agents.map(agent => (
                              <div key={agent.id} className="p-2 bg-white rounded-lg border border-gray-200">
                                <p className="font-medium">{agent.name}</p>
                                <p className="text-sm text-gray-500">ID: {agent.id}</p>
                                <p className="text-xs text-gray-400">URL: /agentcrm/{agent.name.toLowerCase().replace(/\s+/g, '-')}-{agent.id}</p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="flex justify-end pt-6 border-t border-gray-200">
              <button
                type="submit"
                disabled={isSaving}
                className="px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center gap-2"
              >
                <Save className="w-4 h-4" />
                {isSaving ? 'Enregistrement...' : 'Enregistrer'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}