import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, TrendingUp, Clock, Gift } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../../utils/format';
import { calculateCommission } from '../../../utils/commission';
import type { Agent, Sale } from '../../../types';
import BlurredNumber from '../../../components/ui/BlurredNumber';

interface AgentStatsProps {
  agent: Agent;
  sales: Sale[];
}

export default function AgentStats({ agent, sales }: AgentStatsProps) {
  const stats = sales.reduce((acc, sale) => {
    const commission = calculateCommission(sale);
    const isMainAgent = sale.agentId === agent.id;
    
    if (isMainAgent) {
      acc.totalCommission += commission.agent;
      if (sale.paymentStatus.agent) {
        acc.receivedCommission += commission.agent;
      } else {
        acc.pendingCommission += commission.agent;
      }
    } else if (sale.referrerId === agent.id && commission.referrer) {
      acc.totalCommission += commission.referrer;
      if (sale.paymentStatus.referrer) {
        acc.receivedCommission += commission.referrer;
      } else {
        acc.pendingCommission += commission.referrer;
      }
    }

    if (sale.bonus) {
      if (isMainAgent) {
        acc.totalBonus += commission.bonus.agent;
        if (sale.paymentStatus.bonus) {
          acc.receivedBonus += commission.bonus.agent;
        }
      } else if (sale.bonus.secondAgentId === agent.id) {
        acc.totalBonus += commission.bonus.referrer;
        if (sale.paymentStatus.bonus) {
          acc.receivedBonus += commission.bonus.referrer;
        }
      }
    }

    return acc;
  }, {
    totalCommission: 0,
    receivedCommission: 0,
    pendingCommission: 0,
    totalBonus: 0,
    receivedBonus: 0
  });

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="p-6 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50"
      >
        <div className="flex items-center gap-3 mb-3">
          <div className="p-2 bg-blue-100 rounded-lg">
            <TrendingUp className="w-5 h-5 text-blue-600" />
          </div>
          <span className="text-sm font-medium text-blue-900">Commission totale</span>
        </div>
        <div className="space-y-1">
          <p className="text-2xl font-bold text-blue-900">
            <BlurredNumber value={formatCurrency(stats.totalCommission)} />
          </p>
          <p className="text-sm text-blue-600">
            <BlurredNumber value={formatEuro(stats.totalCommission)} />
          </p>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="p-6 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50"
      >
        <div className="flex items-center gap-3 mb-3">
          <div className="p-2 bg-green-100 rounded-lg">
            <DollarSign className="w-5 h-5 text-green-600" />
          </div>
          <span className="text-sm font-medium text-green-900">Commission reçue</span>
        </div>
        <div className="space-y-1">
          <p className="text-2xl font-bold text-green-900">
            <BlurredNumber value={formatCurrency(stats.receivedCommission)} />
          </p>
          <p className="text-sm text-green-600">
            <BlurredNumber value={formatEuro(stats.receivedCommission)} />
          </p>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="p-6 bg-gradient-to-br from-amber-50 to-amber-100/50 rounded-xl border border-amber-200/50"
      >
        <div className="flex items-center gap-3 mb-3">
          <div className="p-2 bg-amber-100 rounded-lg">
            <Clock className="w-5 h-5 text-amber-600" />
          </div>
          <span className="text-sm font-medium text-amber-900">Commission en attente</span>
        </div>
        <div className="space-y-1">
          <p className="text-2xl font-bold text-amber-900">
            <BlurredNumber value={formatCurrency(stats.pendingCommission)} />
          </p>
          <p className="text-sm text-amber-600">
            <BlurredNumber value={formatEuro(stats.pendingCommission)} />
          </p>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="p-6 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50"
      >
        <div className="flex items-center gap-3 mb-3">
          <div className="p-2 bg-purple-100 rounded-lg">
            <Gift className="w-5 h-5 text-purple-600" />
          </div>
          <span className="text-sm font-medium text-purple-900">Bonus reçu</span>
        </div>
        <div className="space-y-1">
          <p className="text-2xl font-bold text-purple-900">
            <BlurredNumber value={formatCurrency(stats.receivedBonus)} />
          </p>
          <p className="text-sm text-purple-600">
            <BlurredNumber value={formatEuro(stats.receivedBonus)} />
          </p>
        </div>
      </motion.div>
    </div>
  );
}