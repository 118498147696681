import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Trophy, Calendar, History, Gift, TrendingUp } from 'lucide-react';
import { formatCurrency } from '../../../utils/format';
import confetti from 'canvas-confetti';
import type { Sale } from '../../../types';
import BlurredNumber from '../../../components/ui/BlurredNumber';
import WinnersHistoryModal from './WinnersHistoryModal';

interface MonthlyGoalCardProps {
  volumeTarget: number;
  reward: {
    type: string;
    description: string;
  };
  sales: Sale[];
}

export default function MonthlyGoalCard({ volumeTarget, reward, sales }: MonthlyGoalCardProps) {
  const confettiRef = useRef<HTMLDivElement>(null);
  const [showWinners, setShowWinners] = useState(false);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Calculate current month's volume
  const currentVolume = sales.reduce((total, sale) => {
    const saleDate = new Date(sale.saleDate);
    if (saleDate.getMonth() === currentMonth && saleDate.getFullYear() === currentYear) {
      return total + sale.propertyPrice;
    }
    return total;
  }, 0);

  const progressPercentage = Math.min((currentVolume / volumeTarget) * 100, 100);

  // Calculate days remaining in current month
  const lastDay = new Date(currentYear, currentMonth + 1, 0);
  const daysRemaining = lastDay.getDate() - currentDate.getDate();

  // Mock winners data - In production this would come from your backend
  const winners = [
    { month: 'Janvier', year: 2024, agentName: 'Sarah Miller', volume: 4200000 },
    { month: 'Décembre', year: 2023, agentName: 'John Davis', volume: 3800000 },
    { month: 'Novembre', year: 2023, agentName: 'Emma Wilson', volume: 3900000 }
  ];

  // Trigger confetti if goal is reached
  useEffect(() => {
    if (currentVolume >= volumeTarget) {
      if (confettiRef.current) {
        const rect = confettiRef.current.getBoundingClientRect();
        const x = (rect.left + rect.right) / 2;
        const y = (rect.top + rect.bottom) / 2;

        confetti({
          particleCount: 100,
          spread: 70,
          origin: { 
            x: x / window.innerWidth,
            y: y / window.innerHeight
          },
          colors: ['#60A5FA', '#34D399', '#A78BFA'],
          zIndex: 999
        });
      }
    }
  }, [currentVolume, volumeTarget]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-gradient-to-br from-purple-900 to-indigo-900 rounded-xl border border-purple-700/50 p-6 text-white relative overflow-hidden"
      >
        {/* Confetti container */}
        <div ref={confettiRef} className="absolute inset-0" />
        
        {/* Animated background elements */}
        <motion.div
          className="absolute top-0 right-0 w-64 h-64 bg-purple-500/20 rounded-full blur-3xl"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <motion.div
          className="absolute bottom-0 left-0 w-64 h-64 bg-blue-500/20 rounded-full blur-3xl"
          animate={{
            scale: [1.2, 1, 1.2],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />

        {/* Content */}
        <div className="relative">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-4">
              <motion.div
                className="flex-shrink-0"
                animate={{
                  rotate: [0, 10, 0, -10, 0],
                  scale: [1, 1.1, 1],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                className="p-3 bg-gradient-to-br from-yellow-400 to-yellow-500 rounded-xl shadow-lg"
              >
                <Trophy className="w-8 h-8 text-white" />
              </motion.div>
              <div className="min-w-0">
                <h3 className="text-xl font-semibold">Objectif du mois</h3>
                <p className="text-purple-200 truncate">
                  {daysRemaining} jours restants
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => setShowWinners(true)}
                className="flex items-center gap-2 px-3 py-1.5 bg-white/10 hover:bg-white/20 rounded-lg transition-colors border border-white/10 text-sm"
              >
                <History className="w-4 h-4" />
                <span>Voir l'historique</span>
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <p className="text-purple-200 mb-2">Volume à atteindre</p>
              <p className="text-3xl font-bold">
                <BlurredNumber value={formatCurrency(volumeTarget)} />
              </p>
              <p className="text-sm text-purple-200/80 mt-2">
                Environ {Math.ceil(volumeTarget / 700000)} studios à {formatCurrency(700000)}
              </p>
              <div className="mt-4 p-3 bg-white/10 backdrop-blur-sm rounded-lg border border-white/20">
                <div className="flex items-center gap-2 mb-2">
                  <Gift className="w-5 h-5 text-yellow-400" />
                  <h4 className="font-semibold text-yellow-300">Récompense</h4>
                </div>
                <p className="text-white/90 font-medium">{reward.description}</p>
              </div>
            </div>

            <div>
              <p className="text-purple-200 mb-2">Volume actuel</p>
              <p className="text-3xl font-bold">
                <BlurredNumber value={formatCurrency(currentVolume)} />
              </p>
              <p className="text-sm text-purple-200/80 mt-2">
                Environ {Math.floor(currentVolume / 700000)} studios vendus sur {Math.ceil(volumeTarget / 700000)}
              </p>
              <div className="flex items-center gap-2 mt-2">
                <TrendingUp className="w-5 h-5 text-purple-300" />
                <p className="text-purple-200">
                  {progressPercentage.toFixed(1)}% de l'objectif
                </p>
              </div>
            </div>
          </div>

          {/* Progress info */}
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between text-sm text-purple-200 mb-3">
            <p>Progression vers l'objectif</p>
            <p className="font-medium">{progressPercentage.toFixed(1)}%</p>
          </div>

          {/* Progress bar */}
          <div className="h-3 bg-white/10 rounded-full overflow-hidden">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: `${progressPercentage}%` }}
              transition={{ duration: 1, ease: "easeOut" }}
              className="h-full bg-gradient-to-r from-blue-500 to-purple-500 rounded-full shadow-lg"
            />
          </div>
        </div>
      </motion.div>

      {/* Winners History Modal */}
      {showWinners && (
        <WinnersHistoryModal
          onClose={() => setShowWinners(false)}
          winners={winners}
          currentMonth={new Date().toLocaleString('fr-FR', { month: 'long', year: 'numeric' })}
          daysRemaining={daysRemaining}
        />
      )}
    </>
  );
}