import React from 'react';
import { Bell } from 'lucide-react';

interface NotificationSectionProps {
  formData: {
    notifications: {
      email: boolean;
      whatsapp: boolean;
    };
  };
  onChange: (data: { notifications: NotificationSectionProps['formData']['notifications'] }) => void;
}

export default function NotificationSection({ formData, onChange }: NotificationSectionProps) {
  return (
    <div className="bg-white rounded-xl border border-gray-200 p-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Bell className="w-5 h-5 text-blue-600" />
        </div>
        <h3 className="text-lg font-semibold text-gray-900">Notifications</h3>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center gap-3 bg-blue-50/50 p-4 rounded-lg border border-blue-100">
          <input
            type="checkbox"
            id="sendEmail"
            checked={formData.notifications.email}
            onChange={(e) => onChange({
              notifications: { ...formData.notifications, email: e.target.checked }
            })}
            className="w-5 h-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <label htmlFor="sendEmail" className="text-sm text-gray-700">
            Envoyer par email
          </label>
        </div>
        <div className="flex items-center gap-3 bg-green-50/50 p-4 rounded-lg border border-green-100">
          <input
            type="checkbox"
            id="sendWhatsapp"
            checked={formData.notifications.whatsapp}
            onChange={(e) => onChange({
              notifications: { ...formData.notifications, whatsapp: e.target.checked }
            })}
            className="w-5 h-5 rounded border-gray-300 text-green-600 focus:ring-green-500"
          />
          <label htmlFor="sendWhatsapp" className="text-sm text-gray-700">
            Envoyer par WhatsApp
          </label>
        </div>
      </div>
    </div>
  );
}