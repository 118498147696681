// src/components/analytics/TopProjects.tsx
import React, { useMemo } from 'react';
import { useSales } from '../../hooks/useSales';
import { Building } from 'lucide-react';
import BlurredNumber from '../../components/ui/BlurredNumber';
import { formatCurrency } from '../../utils/format';

export default function TopProjects() {
  const { sales, calculateCommission } = useSales();

  const projectStats = useMemo(() => {
    const stats = sales.reduce((acc, sale) => {
      if (!acc[sale.project]) {
        acc[sale.project] = {
          name: sale.project,
          totalSales: 0,
          agencyCommission: 0,
          count: 0,
        };
      }

      const commission = calculateCommission(sale);
      acc[sale.project].totalSales += 1;
      acc[sale.project].agencyCommission += commission.agency;
      if (sale.bonus) {
        acc[sale.project].agencyCommission += commission.bonus.agency;
      }
      acc[sale.project].count += 1;

      return acc;
    }, {} as Record<string, {
      name: string;
      totalSales: number;
      agencyCommission: number;
      count: number;
    }>);

    return Object.values(stats)
      .sort((a, b) => b.agencyCommission - a.agencyCommission)
      .slice(0, 5);
  }, [sales]);

  return (
    <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
      <div className="p-6 border-b border-gray-200/50">
        <div className="flex items-center gap-3">
          <Building className="w-6 h-6 text-blue-600" />
          <h3 className="text-xl font-semibold">Top 5 Projets</h3>
        </div>
      </div>

      <div className="divide-y divide-gray-100">
        {projectStats.map((project, index) => (
          <div key={project.name} className="p-4 hover:bg-gray-50 transition-all duration-200">
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-4">
                <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-blue-50 text-blue-600 font-bold">
                  {index + 1}
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">{project.name}</h4>
                  <p className="text-sm text-gray-500">{project.count} ventes</p>
                </div>
              </div>
              <div className="text-right">
                <p className="text-lg font-bold text-gray-900">
                  <BlurredNumber value={formatCurrency(project.agencyCommission)} />
                </p>
                <p className="text-sm text-gray-500">Commission agence</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
