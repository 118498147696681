// src/components/auth/ResetPasswordForm.tsx
import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { hashPassword } from '../../utils/crypto';
import { generatePassword } from '../../utils/crypto';
import { sendEmailNotification } from '../../services/notificationService';
import { X, Key, Send } from 'lucide-react';
import type { Agent } from '../../types';

interface ResetPasswordFormProps {
  agent: Agent;
  onClose: () => void;
}

export default function ResetPasswordForm({ agent, onClose }: ResetPasswordFormProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleResetPassword = async () => {
    setIsSubmitting(true);
    setError(null);
    
    try {
      // Generate new password
      const newPassword = generatePassword(12);
      
      // Hash the new password
      const hashedPassword = await hashPassword(newPassword);
      
      // Update agent document with new password hash
      const agentRef = doc(db, 'agents', agent.id);
      await updateDoc(agentRef, {
        password: hashedPassword,
        updatedAt: new Date().toISOString()
      });

      // Send email notification with new password
      await sendEmailNotification(agent, newPassword);
      
      setSuccess('Nouveau mot de passe envoyé par email');
      
      // Close modal after 2 seconds
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      console.error('Error resetting password:', err);
      setError('Erreur lors de la réinitialisation du mot de passe');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl w-full max-w-md p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <Key className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Réinitialiser le mot de passe</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 text-gray-400 hover:text-gray-500 rounded-lg transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 text-red-600 rounded-lg">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-6 p-4 bg-green-50 text-green-600 rounded-lg">
            {success}
          </div>
        )}

        <p className="text-gray-600 mb-6">
          Un nouveau mot de passe sera généré et envoyé à l'adresse email : 
          <span className="font-medium text-gray-900 ml-1">{agent.email}</span>
        </p>

        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            disabled={isSubmitting}
          >
            Annuler
          </button>
          <button
            type="button"
            onClick={handleResetPassword}
            disabled={isSubmitting}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 flex items-center gap-2"
          >
            <Send className="w-4 h-4" />
            {isSubmitting ? 'Envoi en cours...' : 'Envoyer nouveau mot de passe'}
          </button>
        </div>
      </div>
    </div>
  );
}
