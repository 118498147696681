import React, { useState } from 'react';
import { X, Info } from 'lucide-react';

interface EmailTemplateEditorProps {
  template: string;
  onChange: (content: string) => void;
  onClose: () => void;
  variables: Record<string, string>;
}

export default function EmailTemplateEditor({ template, onChange, onClose, variables }: EmailTemplateEditorProps) {
  const [showPreview, setShowPreview] = useState(false);
  const [content, setContent] = useState(template);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
    onChange(e.target.value);
  };

  const insertVariable = (variable: string) => {
    const textArea = document.getElementById('template-editor') as HTMLTextAreaElement;
    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;
    const newContent = content.substring(0, start) + `\${${variable}}` + content.substring(end);
    setContent(newContent);
    onChange(newContent);
  };

  const renderPreview = () => {
    let previewContent = content;
    Object.entries(variables).forEach(([key, value]) => {
      previewContent = previewContent.replace(new RegExp(`\\$\\{${key}\\}`, 'g'), value);
    });
    return previewContent;
  };

  return (
    <div className="mt-6 border-t pt-6">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Éditeur de modèle</h3>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X className="w-5 h-5" />
        </button>
      </div>

      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Code HTML
            </label>
            <textarea
              id="template-editor"
              value={content}
              onChange={handleChange}
              className="w-full h-96 font-mono text-sm p-4 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div className="flex justify-between">
            <button
              type="button"
              onClick={() => setShowPreview(!showPreview)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              {showPreview ? 'Masquer' : 'Afficher'} l'aperçu
            </button>
          </div>
        </div>

        <div>
          <div className="bg-gray-50 p-4 rounded-lg mb-4">
            <div className="flex items-center gap-2 mb-2">
              <Info className="w-4 h-4 text-blue-600" />
              <h4 className="font-medium">Variables disponibles</h4>
            </div>
            <p className="text-sm text-gray-600 mb-4">
              Cliquez sur une variable pour l'insérer dans le modèle.
            </p>
            <div className="space-y-2">
              {Object.entries(variables).map(([key, description]) => (
                <button
                  key={key}
                  onClick={() => insertVariable(key)}
                  className="block w-full text-left px-3 py-2 text-sm bg-white rounded border border-gray-200 hover:bg-gray-50"
                >
                  <span className="font-mono text-blue-600">${`{${key}}`}</span>
                  <span className="block text-gray-600 text-xs mt-1">{description}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {showPreview && (
        <div className="mt-6 border-t pt-6">
          <h4 className="font-medium mb-4">Aperçu</h4>
          <div className="bg-white border rounded-lg p-6">
            <div dangerouslySetInnerHTML={{ __html: renderPreview() }} />
          </div>
        </div>
      )}
    </div>
  );
}