import React, { useState } from 'react';
import { X, Building, User, DollarSign, Bell, Gift } from 'lucide-react';
import { PropertySection, ClientSection, FinancialSection, NotificationSection } from './forms';
import BonusSection from './forms/BonusSection';
import { useToast } from '../contexts/ToastContext';
import { sendSaleNotification } from '../services/notificationService';
import { useAgents } from '../hooks/useAgents';
import type { Sale } from '../types';

interface SaleFormProps {
  onSubmit: (sale: Omit<Sale, 'id' | 'createdAt' | 'updatedAt'>) => Promise<void>;
  onClose: () => void;
  initialData?: Sale;
}

export default function SaleForm({ onSubmit, onClose, initialData }: SaleFormProps) {
  const { agents } = useAgents();
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [formData, setFormData] = useState({
    propertyPrice: initialData?.propertyPrice || 0,
    developerCommissionPercentage: initialData?.developerCommissionPercentage || 6,
    developerCommission: initialData?.developerCommission || 0,
    client: {
      name: initialData?.client.name || '',
      phone: initialData?.client.phone || '',
      email: initialData?.client.email || '',
      payments: initialData?.client.payments || {
        eoi: { status: false },
        downPayment: { status: false },
        spa: { status: false }
      }
    },
    leadSource: initialData?.leadSource || '',
    project: initialData?.project || '',
    unitNumber: initialData?.unitNumber || '',
    expectedDeliveryYear: initialData?.expectedDeliveryYear || new Date().getFullYear() + 1,
    agentId: initialData?.agentId || '',
    referrerId: initialData?.referrerId || '',
    status: initialData?.status || 'pending',
    commissionType: initialData?.commissionType || 'standard',
    saleDate: initialData?.saleDate || new Date().toISOString().split('T')[0],
    paymentStatus: {
      developer: initialData?.paymentStatus?.developer || false,
      agent: initialData?.paymentStatus?.agent || false,
      referrer: initialData?.paymentStatus?.referrer || false,
      bonus: initialData?.paymentStatus?.bonus || false
    },
    notifications: {
      email: true,
      whatsapp: true
    },
    manualCommissions: initialData?.manualCommissions || {
      agent: 0,
      agency: 0
    },
    bonus: initialData?.bonus || undefined
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await onSubmit(formData);

      // Send notifications to agent
      const agent = agents.find(a => a.id === formData.agentId);
      if (agent && formData.notifications.email) {
        try {
          await sendSaleNotification(
            { ...formData, id: initialData?.id || '' } as Sale,
            agent,
            initialData ? 'status_change' : 'new'
          );
          showToast('success', 'Notification envoyée à l\'agent');
        } catch (notifError) {
          console.error('Error sending notification:', notifError);
          showToast('error', 'Erreur lors de l\'envoi de la notification');
        }
      }

      showToast('success', initialData ? 'Vente modifiée avec succès' : 'Vente créée avec succès');
      onClose();
    } catch (err) {
      console.error('Error submitting form:', err);
      if (err instanceof Error) {
        setError(err.message);
        showToast('error', err.message);
      } else {
        setError('Une erreur est survenue');
        showToast('error', 'Une erreur est survenue');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateFormData = (updates: Partial<typeof formData>) => {
    setFormData(prev => ({ ...prev, ...updates }));
  };

  const sections = [
    {
      id: 'property',
      icon: Building,
      title: 'Information du bien',
      component: PropertySection
    },
    {
      id: 'client',
      icon: User,
      title: 'Information client',
      component: ClientSection
    },
    {
      id: 'financial',
      icon: DollarSign,
      title: 'Information financière',
      component: FinancialSection
    },
    {
      id: 'bonus',
      icon: Gift,
      title: 'Bonus',
      component: BonusSection
    }
  ];

  return (
    <div className="fixed inset-0 bg-black/50 flex items-start justify-center z-50 overflow-y-auto">
      <div className="min-h-screen w-full py-8 px-4">
        <div className="bg-white/95 backdrop-blur-xl rounded-xl shadow-xl w-full max-w-4xl mx-auto">
          {/* Header */}
          <div className="sticky top-0 z-10 px-4 sm:px-6 py-4 bg-white border-b border-gray-200/50 flex justify-between items-center rounded-t-xl">
            <h2 className="text-xl font-semibold text-gray-900">
              {initialData ? 'Modifier la vente' : 'Nouvelle vente'}
            </h2>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Scrollable Content */}
          <div className="max-h-[calc(100vh-8rem)] overflow-y-auto px-4 sm:px-6">
            <form onSubmit={handleSubmit} className="py-6 space-y-6">
              {error && (
                <div className="bg-red-50 text-red-600 p-4 rounded-lg text-sm">
                  {error}
                </div>
              )}

              {/* Form Sections */}
              <div className="space-y-6">
                {sections.map(({ id, icon: Icon, title, component: Component }) => (
                  <div key={id} className="bg-white rounded-xl border border-gray-200/50 overflow-hidden">
                    <div className="p-4 border-b border-gray-200/50">
                      <div className="flex items-center gap-3">
                        <div className="p-2 bg-blue-50 rounded-lg">
                          <Icon className="w-5 h-5 text-blue-600" />
                        </div>
                        <h3 className="font-medium text-gray-900">{title}</h3>
                      </div>
                    </div>
                    <div className="p-4 sm:p-6">
                      <Component
                        formData={formData}
                        onChange={updateFormData}
                        agents={agents}
                      />
                    </div>
                  </div>
                ))}

                {!initialData && (
                  <div className="bg-white rounded-xl border border-gray-200/50 overflow-hidden">
                    <div className="p-4 border-b border-gray-200/50">
                      <div className="flex items-center gap-3">
                        <div className="p-2 bg-blue-50 rounded-lg">
                          <Bell className="w-5 h-5 text-blue-600" />
                        </div>
                        <h3 className="font-medium text-gray-900">Notifications</h3>
                      </div>
                    </div>
                    <div className="p-4 sm:p-6">
                      <NotificationSection
                        formData={formData}
                        onChange={updateFormData}
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* Form Actions */}
              <div className="sticky bottom-0 bg-white border-t border-gray-200/50 mt-6 -mx-4 sm:-mx-6 px-4 sm:px-6 py-4 flex flex-col sm:flex-row justify-end gap-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="w-full sm:w-auto px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                  disabled={isSubmitting}
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full sm:w-auto px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 disabled:opacity-50"
                >
                  {isSubmitting ? 'Enregistrement...' : initialData ? 'Mettre à jour' : 'Ajouter la vente'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}