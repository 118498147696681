// src/components/analytics/AgencyRevenueHistory.tsx
import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, TrendingDown, Minus, DollarSign, Calculator } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../ui/BlurredNumber';

interface MonthlyRevenue {
  month: string;
  revenue: number;
  progression: number;
}

interface AgencyRevenueHistoryProps {
  data: MonthlyRevenue[];
}

export default function AgencyRevenueHistory({ data }: AgencyRevenueHistoryProps) {
  const monthlyAverage = useMemo(() => {
    if (data.length === 0) return 0;
    const total = data.reduce((sum, month) => sum + month.revenue, 0);
    return total / data.length;
  }, [data]);

  return (
    <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
      <div className="p-6 border-b border-gray-200/50">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold">Historique des revenus</h2>
          <div className="flex items-center gap-3 px-4 py-2 bg-blue-50 rounded-lg border border-blue-100">
            <Calculator className="w-5 h-5 text-blue-600" />
            <div>
              <p className="text-sm text-gray-600">Moyenne mensuelle</p>
              <p className="font-bold text-blue-900">
                <BlurredNumber value={formatCurrency(monthlyAverage)} />
                <span className="text-sm font-normal text-gray-500 ml-2">
                  (<BlurredNumber value={formatEuro(monthlyAverage)} />)
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="divide-y divide-gray-200/50">
        {data.map((month, index) => {
          const isPositive = month.progression > 0;
          const isNeutral = month.progression === 0;
          const hasRevenue = month.revenue > 0;

          return (
            <motion.div
              key={month.month}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`p-4 ${hasRevenue ? 'hover:bg-gray-50/50' : 'bg-red-50/50'}`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className={`p-2 rounded-lg ${
                    !hasRevenue ? 'bg-red-100' :
                    isPositive ? 'bg-green-100' :
                    isNeutral ? 'bg-gray-100' : 'bg-amber-100'
                  }`}>
                    {!hasRevenue ? (
                      <DollarSign className="w-5 h-5 text-red-600" />
                    ) : isPositive ? (
                      <TrendingUp className="w-5 h-5 text-green-600" />
                    ) : isNeutral ? (
                      <Minus className="w-5 h-5 text-gray-600" />
                    ) : (
                      <TrendingDown className="w-5 h-5 text-amber-600" />
                    )}
                  </div>
                  <div>
                    <h3 className="font-medium">{month.month}</h3>
                    {!isNeutral && !isNaN(month.progression) && month.month !== 'juillet 2024' && (
                      <div className={`flex items-center gap-1 mt-1 text-sm ${
                        isPositive ? 'text-green-600' : 'text-amber-600'
                      }`}>
                        {isPositive ? '+' : ''}{month.progression.toFixed(1)}%
                      </div>
                    )}
                  </div>
                </div>

                <motion.div 
                  className="text-right"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: index * 0.1 + 0.2 }}
                >
                  <p className={`text-lg font-bold ${!hasRevenue ? 'text-red-600' : 'text-gray-900'}`}>
                    <BlurredNumber value={formatCurrency(month.revenue)} />
                  </p>
                  <span className="inline-block text-sm text-gray-500">
                    <BlurredNumber value={formatEuro(month.revenue)} />
                  </span>
                </motion.div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
}
