const fr = {
  common: {
    loading: 'Chargement...',
    save: 'Enregistrer',
    cancel: 'Annuler',
    edit: 'Modifier',
    delete: 'Supprimer',
    close: 'Fermer',
    success: 'Opération réussie',
    error: 'Une erreur est survenue',
    required: 'Champ requis',
    search: 'Rechercher...',
    noResults: 'Aucun résultat trouvé',
    menu: {
      dashboard: 'Tableau de bord',
      sales: 'Ventes',
      agents: 'Agents',
      settings: 'Paramètres',
      profile: 'Profil',
      logout: 'Déconnexion'
    }
  },
  auth: {
    login: {
      title: 'Connexion',
      email: 'Email',
      password: 'Mot de passe',
      submit: 'Se connecter',
      forgotPassword: 'Mot de passe oublié ?',
      error: 'Email ou mot de passe incorrect'
    }
  },
  dashboard: {
    title: 'Tableau de bord',
    stats: {
      totalSales: 'Ventes totales',
      monthlyAverage: 'Moyenne mensuelle',
      activeAgents: 'Agents actifs'
    }
  },
  sales: {
    title: 'Ventes',
    new: 'Nouvelle vente',
    details: {
      title: 'Détails de la vente',
      property: 'Propriété',
      client: 'Client',
      agent: 'Agent',
      commission: 'Commission'
    },
    status: {
      pending: 'En attente',
      completed: 'Terminée',
      cancelled: 'Annulée'
    }
  },
  agents: {
    title: 'Agents',
    new: 'Nouvel agent',
    profile: {
      title: 'Profil de l\'agent',
      performance: 'Performance',
      sales: 'Ventes'
    }
  },
  profile: {
    title: 'Mon profil',
    personal: {
      title: 'Informations personnelles',
      name: 'Nom complet',
      email: 'Email',
      phone: 'Téléphone'
    },
    preferences: {
      title: 'Préférences',
      language: 'Langue',
      theme: 'Thème'
    },
    security: {
      title: 'Sécurité',
      password: 'Mot de passe',
      twoFactor: 'Double authentification'
    },
    notifications: {
      title: 'Notifications',
      email: 'Email',
      push: 'Push',
      sms: 'SMS'
    }
  },
  settings: {
    title: 'Paramètres',
    general: {
      title: 'Général',
      language: 'Langue',
      dateFormat: 'Format de date',
      timeZone: 'Fuseau horaire'
    },
    notifications: {
      title: 'Notifications',
      email: 'Email',
      push: 'Push',
      sms: 'SMS'
    },
    security: {
      title: 'Sécurité',
      password: 'Mot de passe',
      twoFactor: 'Double authentification'
    }
  }
};

export default fr;