import React, { useState, useEffect } from 'react';
import { 
  GitBranch, Filter, Phone, MessageSquare, Bot, Settings,
  RefreshCw, Plus, Users
} from 'lucide-react';
import { DndContext, DragEndEvent, closestCorners } from '@dnd-kit/core';
import { useLeads } from '../hooks/useLeads'; 
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import { useSettings } from '../hooks/useSettings';
import { formatDateDisplay } from '../utils/date';
import LeadForm from '../components/leads/LeadForm';
import PipelineStage from '../components/leads/PipelineStage';
import AutomationSettings from '../components/leads/AutomationSettings';
import { Lead, LeadStage } from '../types/leads';

export default function Pipelines() {
  const { leads, addLead, updateLead, deleteLead } = useLeads();
  const { agents } = useAgents();
  const { sales } = useSales();
  const { settings } = useSettings();
  
  const [showSettings, setShowSettings] = useState(false);
  const [showAddLead, setShowAddLead] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterBy, setFilterBy] = useState<'all' | 'source' | 'status' | 'existing'>('all');

  // Convert existing clients to leads
  useEffect(() => {
    const processExistingClients = async () => {
      const existingClients = sales
        .filter(sale => 
          sale.client.name && 
          (sale.client.email || sale.client.phone) &&
          !leads.some(lead => 
            lead.email === sale.client.email || 
            lead.phone === sale.client.phone
          )
        )
        .map(sale => ({
          name: sale.client.name || '',
          email: sale.client.email || '',
          phone: sale.client.phone || '',
          source: 'Ancien client',
          stage: 'won' as LeadStage,
          notes: `Client existant - Projet: ${sale.project}`,
          automationStatus: {
            call: false,
            message: false
          },
          createdAt: sale.createdAt,
          updatedAt: new Date().toISOString()
        }));

      for (const client of existingClients) {
        try {
          await addLead(client);
        } catch (error) {
          console.error('Error adding client as lead:', error);
        }
      }
    };

    processExistingClients();
  }, [sales, leads, addLead]);

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over) return;
    
    const leadId = active.id as string;
    const newStage = over.id as LeadStage;
    
    try {
      await updateLead(leadId, {
        stage: newStage,
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error updating lead stage:', error);
    }
  };

  const stages: LeadStage[] = [
    'new',
    'contacted',
    'meeting_scheduled',
    'negotiation',
    'won',
    'lost'
  ];

  const filteredLeads = leads.filter(lead => {
    const query = searchQuery.toLowerCase();
    switch (filterBy) {
      case 'source':
        return lead.source.toLowerCase().includes(query);
      case 'status':
        return lead.stage.toLowerCase().includes(query);
      case 'existing':
        return lead.source === 'Ancien client';
      default:
        return (
          lead.name.toLowerCase().includes(query) ||
          lead.email.toLowerCase().includes(query) ||
          lead.phone.toLowerCase().includes(query)
        );
    }
  });

  const leadsByStage = stages.reduce((acc, stage) => {
    acc[stage] = filteredLeads
      .filter(lead => lead.stage === stage)
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
    return acc;
  }, {} as Record<LeadStage, Lead[]>);

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <div className="flex-1">
              <div className="flex items-center gap-3">
                <GitBranch className="w-6 h-6 text-blue-600" />
                <div>
                  <h1 className="text-xl font-semibold">Pipeline</h1>
                  <p className="text-sm text-gray-500 mt-1">
                    {leads.length} leads • {leads.filter(l => l.stage === 'won').length} gagnés
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setShowSettings(true)}
                className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                title="Paramètres d'automatisation"
              >
                <Settings className="w-5 h-5" />
              </button>
              <button
                onClick={() => setShowAddLead(true)}
                className="px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center gap-2"
              >
                <Plus className="w-5 h-5" />
                Nouveau lead
              </button>
            </div>
          </div>
        </div>

        {/* Search & Filters */}
        <div className="p-4 sm:p-6">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <Filter className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Rechercher un lead..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <select
              value={filterBy}
              onChange={(e) => setFilterBy(e.target.value as 'all' | 'source' | 'status')}
              className="w-full sm:w-auto px-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
            >
              <option value="all">Tous les filtres</option>
              <option value="source">Source</option>
              <option value="status">Statut</option>
              <option value="existing">Clients existants</option>
            </select>
          </div>
        </div>

        {/* Automation Status */}
        <div className="px-6 pb-6">
          <div className="flex flex-wrap gap-4">
            <div className="flex items-center gap-2 px-3 py-1.5 bg-gradient-to-br from-green-50 to-green-100/50 text-green-700 rounded-full text-sm border border-green-200/50">
              <Bot className="w-4 h-4" />
              <span>Appels IA activés</span>
            </div>
            <div className="flex items-center gap-2 px-3 py-1.5 bg-gradient-to-br from-blue-50 to-blue-100/50 text-blue-700 rounded-full text-sm border border-blue-200/50">
              <MessageSquare className="w-4 h-4" />
              <span>Messages automatiques activés</span>
            </div>
            <div className="flex items-center gap-2 px-3 py-1.5 bg-gradient-to-br from-purple-50 to-purple-100/50 text-purple-700 rounded-full text-sm border border-purple-200/50">
              <RefreshCw className="w-4 h-4" />
              <span>Zapier connecté</span>
            </div>
            <div className="flex items-center gap-2 px-3 py-1.5 bg-gradient-to-br from-green-50 to-green-100/50 text-green-700 rounded-full text-sm border border-green-200/50">
              <Users className="w-4 h-4" />
              <span>{leads.filter(l => l.source === 'Ancien client').length} clients existants</span>
            </div>
          </div>
        </div>
      </div>

      {/* Pipeline Stages */}
      <div className="overflow-x-auto pb-6 -mx-8 px-8">
        <div className="flex gap-6 min-w-max">
          <DndContext
            onDragEnd={handleDragEnd}
            collisionDetection={closestCorners}
          >
            {stages.map((stage) => (
              <div key={stage} className="w-[350px] flex-shrink-0">
                <PipelineStage
                  stage={stage}
                  leads={leadsByStage[stage]}
                  onUpdateLead={updateLead}
                  onDeleteLead={deleteLead}
                  onAssignAgent={onAssignAgent}
                />
              </div>
            ))}
          </DndContext>
        </div>
      </div>

      {/* Modals */}
      {showSettings && (
        <AutomationSettings
          settings={settings}
          onClose={() => setShowSettings(false)}
        />
      )}

      {showAddLead && (
        <LeadForm
          onClose={() => setShowAddLead(false)}
        />
      )}
    </div>
  );
}