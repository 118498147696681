import { initializeApp, setLogLevel } from 'firebase/app';
import { 
  getFirestore, 
  enableIndexedDbPersistence, 
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  serverTimestamp
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD85I8BK2vBD92xKGkDK68DXYFnYXjfoK4",
  authDomain: "realestatecrm-84f20.firebaseapp.com",
  projectId: "realestatecrm-84f20",
  storageBucket: "realestatecrm-84f20.appspot.com",
  messagingSenderId: "93743705924",
  appId: "1:93743705924:web:a76ff1c551d93f6d497111"
};

// Initialize Firebase with memory management settings
const app = initializeApp(firebaseConfig);

// Enable debug logging in development
if (process.env.NODE_ENV === 'development') {
  setLogLevel('debug');
}

// Initialize Firestore with optimized settings
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  experimentalForceLongPolling: true,
  ignoreUndefinedProperties: true
});

// Enable offline persistence with error handling
try {
  enableIndexedDbPersistence(db, {
    forceOwnership: true
  }).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence enabled in first tab only');
    } else if (err.code === 'unimplemented') {
      console.warn('Browser doesn\'t support persistence');
    }
  });
} catch (error) {
  console.warn('Error enabling persistence:', error);
}

// Initialize Auth with custom settings
const auth = getAuth(app);
auth.useDeviceLanguage();

// Collection names as constants
export const COLLECTIONS = {
  USERS: 'users',
  AGENTS: 'agents',
  SALES: 'sales',
  SETTINGS: 'settings',
  LOGIN_LOGS: 'loginLogs'
};

// Verify and initialize Firebase setup with retry mechanism
export async function verifyFirebaseSetup(retryCount = 3): Promise<boolean> {
  for (let attempt = 1; attempt <= retryCount; attempt++) {
    try {
      // Verify collections exist
      for (const collectionName of Object.values(COLLECTIONS)) {
        const collectionRef = collection(db, collectionName);
        await getDocs(collectionRef);
      }

      // Initialize default settings if needed
      const settingsRef = doc(db, COLLECTIONS.SETTINGS, 'general');
      const settingsDoc = await getDoc(settingsRef);
      
      if (!settingsDoc.exists()) {
        await setDoc(settingsRef, {
          name: 'PalmCRM',
          email: 'contact@palmdubaiimmo.com',
          notifications: {
            newSale: true,
            commissionReceived: true,
            agentCreated: true,
            loginAttempt: true
          },
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      }

      return true;
    } catch (error) {
      console.error(`Firebase setup attempt ${attempt} failed:`, error);
      if (attempt === retryCount) {
        throw error;
      }
      // Wait before retrying (exponential backoff)
      await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
    }
  }
  return false;
}

export { db, auth };