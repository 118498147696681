import { useState, useEffect } from 'react';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  onSnapshot,
  query,
  orderBy,
  serverTimestamp
} from 'firebase/firestore';
import { db, COLLECTIONS } from '../lib/firebase';
import { calculateCommission } from '../utils/commission';
import { useToast } from '../contexts/ToastContext';
import { sendSaleNotification } from '../services/notificationService';
import type { Sale } from '../types';
import { useAgents } from './useAgents';

export function useSales() {
  const [sales, setSales] = useState<Sale[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { showToast } = useToast();
  const { agents } = useAgents();

  useEffect(() => {
    try {
      const q = query(
        collection(db, COLLECTIONS.SALES),
        orderBy('saleDate', 'desc')
      );

      const unsubscribe = onSnapshot(q, {
        next: (snapshot) => {
          const salesData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate?.()?.toISOString() || new Date().toISOString(),
            updatedAt: doc.data().updatedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
            saleDate: doc.data().saleDate || doc.data().createdAt?.toDate?.()?.toISOString().split('T')[0],
          })) as Sale[];
          setSales(salesData);
          setLoading(false);
        },
        error: (err) => {
          console.error('Error fetching sales:', err);
          setError('Failed to fetch sales');
          setLoading(false);
          showToast('error', 'Erreur lors du chargement des ventes');
        }
      });

      return () => unsubscribe();
    } catch (err) {
      console.error('Error setting up sales listener:', err);
      setError('Failed to initialize sales');
      setLoading(false);
      showToast('error', 'Erreur lors de l\'initialisation des ventes');
    }
  }, [showToast]);

  const addSale = async (saleData: Omit<Sale, 'id' | 'createdAt' | 'updatedAt'>) => {
    try {
      const docRef = await addDoc(collection(db, COLLECTIONS.SALES), {
        ...saleData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      // Send notifications
      const agent = agents.find(a => a.id === saleData.agentId);
      if (agent) {
        try {
          await sendSaleNotification(
            { ...saleData, id: docRef.id } as Sale,
            agent,
            'new'
          );
          showToast('success', 'Notifications envoyées avec succès');
        } catch (error) {
          console.error('Error sending notifications:', error);
          showToast('error', 'Erreur lors de l\'envoi des notifications');
        }
      }

      showToast('success', 'Vente ajoutée avec succès');
      return { id: docRef.id, ...saleData };
    } catch (error) {
      console.error('Error adding sale:', error);
      showToast('error', 'Erreur lors de l\'ajout de la vente');
      throw new Error('Failed to add sale');
    }
  };

  const updateSale = async (id: string, updates: Partial<Sale>) => {
    try {
      const saleRef = doc(db, COLLECTIONS.SALES, id);
      const updateData = {
        ...updates,
        updatedAt: serverTimestamp()
      };
      
      // Get current sale data
      const currentSale = sales.find(s => s.id === id);
      if (!currentSale) throw new Error('Sale not found');

      await updateDoc(saleRef, updateData);

      // Check if status has changed
      if (updates.status && updates.status !== currentSale.status) {
        const agent = agents.find(a => a.id === currentSale.agentId);
        if (agent) {
          try {
            await sendSaleNotification(
              { ...currentSale, ...updates },
              agent,
              'status_change',
              currentSale.status,
              updates.status
            );
            showToast('success', 'Notifications envoyées avec succès');
          } catch (error) {
            console.error('Error sending notifications:', error);
            showToast('error', 'Erreur lors de l\'envoi des notifications');
          }
        }
      }

      showToast('success', 'Vente mise à jour avec succès');
      return true;
    } catch (error) {
      console.error('Error updating sale:', error);
      showToast('error', 'Erreur lors de la mise à jour de la vente');
      throw new Error('Failed to update sale');
    }
  };

  const deleteSale = async (id: string) => {
    try {
      await deleteDoc(doc(db, COLLECTIONS.SALES, id));
      showToast('success', 'Vente supprimée avec succès');
      return true;
    } catch (error) {
      console.error('Error deleting sale:', error);
      showToast('error', 'Erreur lors de la suppression de la vente');
      throw new Error('Failed to delete sale');
    }
  };

  const getAgentSales = (agentId: string) => {
    return sales.filter(sale => sale.agentId === agentId || sale.referrerId === agentId);
  };

  return {
    sales,
    loading,
    error,
    addSale,
    updateSale,
    deleteSale,
    getAgentSales,
    calculateCommission
  };
}