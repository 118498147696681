import React from 'react';
import { CreditCard, FileText } from 'lucide-react';

interface ClientSectionProps {
  formData: {
    client: {
      name?: string;
      phone?: string;
      email?: string;
      payments?: {
        eoi?: {
          status: boolean;
          date?: string;
        };
        downPayment?: {
          status: boolean;
          date?: string;
        };
        spa?: {
          status: boolean;
          date?: string;
        };
      };
    };
  };
  onChange: (data: { client: ClientSectionProps['formData']['client'] }) => void;
}

export default function ClientSection({ formData, onChange }: ClientSectionProps) {
  const handlePaymentChange = (type: 'eoi' | 'downPayment' | 'spa', status: boolean) => {
    onChange({
      client: {
        ...formData.client,
        payments: {
          ...formData.client.payments,
          [type]: {
            status,
            date: status ? new Date().toISOString() : undefined
          }
        }
      }
    });
  };

  const formatDate = (date?: string) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('fr-FR');
  };

  return (
    <div className="bg-white rounded-xl border border-gray-200 p-6">
      {/* Client Info Fields */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Nom du client
          </label>
          <input
            type="text"
            value={formData.client.name || ''}
            onChange={(e) => onChange({
              client: { ...formData.client, name: e.target.value }
            })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Nom complet"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email
          </label>
          <input
            type="email"
            value={formData.client.email || ''}
            onChange={(e) => onChange({
              client: { ...formData.client, email: e.target.value }
            })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="email@example.com"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Téléphone
          </label>
          <input
            type="tel"
            value={formData.client.phone || ''}
            onChange={(e) => onChange({
              client: { ...formData.client, phone: e.target.value }
            })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="+971 XX XXX XXXX"
          />
        </div>
      </div>

      {/* Payment Status Section */}
      <div className="mt-6 pt-6 border-t border-gray-200">
        <h4 className="text-sm font-medium text-gray-700 mb-4">État des paiements</h4>
        <div className="grid grid-cols-3 gap-4">
          {/* EOI Payment */}
          <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <CreditCard className="w-4 h-4 text-gray-600" />
                <span className="text-sm font-medium">EOI</span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.client.payments?.eoi?.status || false}
                  onChange={(e) => handlePaymentChange('eoi', e.target.checked)}
                  className="sr-only peer"
                />
                <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
            {formData.client.payments?.eoi?.date && (
              <p className="text-xs text-gray-500">
                {formatDate(formData.client.payments.eoi.date)}
              </p>
            )}
          </div>

          {/* Down Payment */}
          <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <CreditCard className="w-4 h-4 text-gray-600" />
                <span className="text-sm font-medium">DP</span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.client.payments?.downPayment?.status || false}
                  onChange={(e) => handlePaymentChange('downPayment', e.target.checked)}
                  className="sr-only peer"
                />
                <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
            {formData.client.payments?.downPayment?.date && (
              <p className="text-xs text-gray-500">
                {formatDate(formData.client.payments.downPayment.date)}
              </p>
            )}
          </div>

          {/* SPA */}
          <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <FileText className="w-4 h-4 text-gray-600" />
                <span className="text-sm font-medium">SPA</span>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.client.payments?.spa?.status || false}
                  onChange={(e) => handlePaymentChange('spa', e.target.checked)}
                  className="sr-only peer"
                />
                <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
            {formData.client.payments?.spa?.date && (
              <p className="text-xs text-gray-500">
                {formatDate(formData.client.payments.spa.date)}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}