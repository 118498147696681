import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useAgents } from '../hooks/useAgents';
import { useAgentSlug } from '../hooks/useAgentSlug';
import { 
  LayoutDashboard, 
  Users, 
  DollarSign,
  GitBranch,
  Settings,
  PalmtreeIcon,
  Clock,
  Image
} from 'lucide-react';
import { motion } from 'framer-motion';
import UserMenu from './ui/UserMenu';
import AgentSelector from './AgentSelector';
import { useAgentViewStore } from './AgentSelector';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const location = useLocation();
  const { user } = useAuth();
  const { agent, slug } = useAgentSlug();
  const { agents } = useAgents(); 

  const getNavigation = () => {
    if (agent) {
      return [
        { name: 'Dashboard', href: `/agentcrm/${slug}`, icon: LayoutDashboard },
        { name: 'Mes ventes', href: `/agentcrm/${slug}/sales`, icon: DollarSign },
        { name: 'Mes commissions', href: `/agentcrm/${slug}/commissions`, icon: Clock },
        { name: 'Paramètres', href: `/agentcrm/${slug}/profile`, icon: Settings }
      ];
    }

    // Navigation admin par défaut
    return [
      { name: 'Dashboard', href: '/', icon: LayoutDashboard },
      { name: 'Agents', href: '/agents', icon: Users },
      { name: 'Ventes', href: '/sales', icon: DollarSign },
      { name: 'Pipeline', href: '/pipelines', icon: GitBranch },
      { name: 'Marketing', href: '/marketing', icon: Image },
      ...(user?.role === 'admin' ? [
        { name: 'Paramètres', href: '/settings', icon: Settings }
      ] : [])
    ];
  };

  const navigation = getNavigation();

  const isCurrentRoute = (href: string) => {
    if (agent) {
      // Pour la vue agent, comparer sans l'ID de l'agent
      const currentPath = location.pathname.replace(agent.id, ':agentId');
      const comparePath = href.replace(agent.id, ':agentId');
      return currentPath === comparePath;
    }
    return location.pathname === href;
  };

  return (
    <div className="min-h-screen bg-gradient-radial from-gray-50 to-gray-100/50">
      {/* Mobile Header */}
      <header className="fixed top-0 left-0 right-0 h-16 bg-white/95 backdrop-blur-xl border-b border-gray-200/50 lg:hidden z-40">
        <div className="flex items-center justify-between h-full px-4">
          <div className="flex items-center gap-3">
            <motion.div 
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
              className="p-2 bg-blue-50 rounded-xl"
            >
              <PalmtreeIcon className="w-6 h-6 text-blue-600" />
            </motion.div>
            <motion.h1 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="text-xl font-bold bg-gradient-to-br from-blue-600 to-blue-500 bg-clip-text text-transparent"
            >
              PalmCRM
            </motion.h1>
          </div>
          
          <UserMenu />
        </div>
      </header>

      {/* Mobile Bottom Navigation */}
      <nav className="fixed bottom-0 left-0 right-0 bg-white/95 backdrop-blur-xl border-t border-gray-200/50 lg:hidden z-40">
        <div className="flex justify-around">
          {navigation.map((item) => {
            const isActive = isCurrentRoute(item.href);
            return (
              <Link
                key={item.name}
                to={item.href}
                className={`
                  flex flex-col items-center gap-1 px-3 py-3 text-xs font-medium
                  transition-all duration-200 relative
                  ${isActive ? 'text-blue-600' : 'text-gray-600'}
                `}
              >
                <div className={`
                  p-2 rounded-lg transition-colors duration-200
                  ${isActive ? 'bg-blue-100/80' : 'bg-transparent'}
                `}>
                  <item.icon className="w-5 h-5" />
                </div>
                <span>{item.name}</span>
                {isActive && (
                  <motion.div
                    layoutId="activeMobileTab"
                    className="absolute -bottom-3 left-1/2 -translate-x-1/2 w-12 h-1 bg-blue-600 rounded-full"
                    transition={{ duration: 0.3 }}
                  />
                )}
              </Link>
            );
          })}
        </div>
      </nav>

      {/* Desktop Sidebar */}
      <aside className="hidden lg:flex fixed inset-y-0 left-0 z-30 w-72 bg-white/95 backdrop-blur-xl border-r border-gray-200/50">
        <div className="flex flex-col h-full w-full">
          {/* Logo */}
          <div className="h-16 flex items-center gap-3 px-6 border-b border-gray-200/50">
            <div className="p-2 bg-blue-50 rounded-xl">
              <PalmtreeIcon className="w-6 h-6 text-blue-600" />
            </div>
            <h1 className="text-2xl font-bold bg-gradient-to-br from-blue-600 to-blue-500 bg-clip-text text-transparent">
              PalmCRM
            </h1>
          </div>

          {/* Agent Selector */}
          {user?.role === 'admin' && (
            <div className="px-4 py-4">
              <AgentSelector />
            </div>
          )}
          {!user && agent && (
            <div className="px-4 py-4">
              <div className="p-4 bg-blue-50 rounded-xl border border-blue-200">
                <p className="font-medium text-blue-900">{agent.name}</p>
                <p className="text-sm text-blue-600">{agent.email}</p>
              </div>
            </div>
          )}

          {/* Navigation */}
          <nav className="flex-1 px-3 py-6 space-y-1 overflow-y-auto">
            {navigation.map((item) => {
              const isActive = isCurrentRoute(item.href);
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`
                    flex items-center gap-3 px-4 py-3 rounded-xl text-sm font-medium
                    transition-all duration-200 group relative w-full
                    ${isActive 
                      ? 'bg-gradient-to-r from-blue-50 to-blue-50/50 text-blue-600' 
                      : 'text-gray-600 hover:bg-gray-50/60 hover:text-gray-900'
                    }
                  `}
                >
                  <div className={`
                    p-2 rounded-lg transition-colors duration-200
                    ${isActive ? 'bg-blue-100/80' : 'bg-gray-100/50 group-hover:bg-gray-100'}
                  `}>
                    <item.icon className="w-5 h-5" />
                  </div>
                  <span>{item.name}</span>
                  {isActive && (
                    <motion.div
                      layoutId="activeTab"
                      className="absolute inset-0 rounded-xl border-2 border-blue-200/50"
                      transition={{ duration: 0.3 }}
                    />
                  )}
                </Link>
              );
            })}
          </nav>

          {/* User Menu */}
          {user ? (
            <div className="p-4 border-t border-gray-200/50">
              <UserMenu />
            </div>
          ) : agent && (
            <div className="p-4 border-t border-gray-200/50">
              <Link
                to="/login"
                className="flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Se connecter
              </Link>
            </div>
          )}
        </div>
      </aside>

      {/* Main Content */}
      <main className="lg:ml-72 min-h-screen p-4 lg:p-8 pb-24 lg:pb-8 mt-16 lg:mt-0">
        {children}
      </main>
    </div>
  );
}