import React, { forwardRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircle, XCircle, X } from 'lucide-react';

export interface Toast {
  id: string;
  type: 'success' | 'error';
  message: string;
}

interface ToastProps {
  toast: Toast;
  onClose: (id: string) => void;
}

const Toast = forwardRef<HTMLDivElement, ToastProps>(({ toast, onClose }, ref) => {
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      className={`
        flex items-center gap-3 p-4 rounded-lg shadow-lg border backdrop-blur-sm
        ${toast.type === 'success' 
          ? 'bg-green-50/95 border-green-200/50 text-green-800' 
          : 'bg-red-50/95 border-red-200/50 text-red-800'
        }
      `}
    >
      {toast.type === 'success' ? (
        <CheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
      ) : (
        <XCircle className="w-5 h-5 text-red-500 flex-shrink-0" />
      )}
      <p className="text-sm">{toast.message}</p>
      <button
        onClick={() => onClose(toast.id)}
        className="ml-auto p-1 hover:bg-black/5 rounded-full transition-colors"
      >
        <X className="w-4 h-4" />
      </button>
    </motion.div>
  );
});

Toast.displayName = 'Toast';

export function ToastContainer({ toasts, onClose }: { toasts: Toast[]; onClose: (id: string) => void }) {
  return (
    <div className="fixed bottom-4 right-4 z-50 flex flex-col gap-2">
      <AnimatePresence mode="sync">
        {toasts.map(toast => (
          <Toast key={toast.id} toast={toast} onClose={onClose} />
        ))}
      </AnimatePresence>
    </div>
  );
}

export default Toast;