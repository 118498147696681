import React from 'react';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { useSales } from '../../hooks/useSales';
import AgentStats from './components/AgentStats';
import AgentSalesChart from './components/AgentSalesChart';
import AgentCommissionChart from './components/AgentCommissionChart';
import MonthlyGoalCard from './components/MonthlyGoalCard';
import LastSaleCard from './components/LastSaleCard';
import TopAgents from './components/TopAgents';
import UnderperformingAlert from './components/UnderperformingAlert';
import InactivityAlert from './components/InactivityAlert';
import { Building, TrendingUp } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useSettings } from '../../hooks/useSettings';

export default function AgentDashboard() {
  const { agent } = useAgentSlug();
  const { sales, calculateCommission } = useSales();
  const { settings } = useSettings();

  if (!agent) return null;

  const agentSales = sales.filter(sale => 
    sale.agentId === agent.id || sale.referrerId === agent.id
  ).sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());

  // Get the most recent and first sale
  const lastSale = agentSales[0];
  const firstSale = agentSales[agentSales.length - 1];

  // Calculate days since last sale
  const daysSinceLastSale = lastSale 
    ? formatDistanceToNow(new Date(lastSale.saleDate), { locale: fr, addSuffix: true })
    : 'Aucune vente';

  // Calculate monthly average
  const calculateMonthlyAverage = () => {
    if (!firstSale || !lastSale) return 0;

    const firstDate = new Date(firstSale.saleDate);
    const lastDate = new Date(lastSale.saleDate);
    const monthsDiff = (lastDate.getFullYear() - firstDate.getFullYear()) * 12 + 
                      (lastDate.getMonth() - firstDate.getMonth()) + 1;

    return agentSales.length / Math.max(1, monthsDiff);
  };

  const monthlyAverage = calculateMonthlyAverage();

  const commission = lastSale ? calculateCommission(lastSale) : null;
  
  // Round monthly average to nearest whole number
  const roundedMonthlyAverage = Math.round(monthlyAverage);

  const agentCommission = lastSale ? (
    lastSale.agentId === agent.id ? commission?.agent : commission?.referrer
  ) : 0;

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-4 sm:p-6">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-4">
            <div className="flex items-start sm:items-center gap-3">
              <Building className="w-6 h-6 text-blue-600" />
              <div>
                <h1 className="text-2xl font-bold">Bienvenue, {agent.name}</h1>
                <p className="text-gray-500 mt-0.5 sm:mt-1 text-sm sm:text-base">
                  Dernière vente : {daysSinceLastSale}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2 text-sm sm:text-base ml-9 sm:ml-0">
              <TrendingUp className="w-5 h-5 text-green-600" />
              <span className="text-sm font-medium">
                Moyenne : {roundedMonthlyAverage} ventes/mois
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Alerts */}
      <InactivityAlert lastSaleDate={lastSale?.saleDate} />
      <UnderperformingAlert monthlyAverage={roundedMonthlyAverage} />

      {/* Monthly Goal Card */}
      {settings?.monthlyGoal && (
        <MonthlyGoalCard
          volumeTarget={settings.monthlyGoal.volumeTarget}
          reward={settings.monthlyGoal.reward}
          sales={agentSales}
        />
      )}
      
      {lastSale && agentCommission && (
        <LastSaleCard 
          sale={lastSale}
          commission={agentCommission}
        />
      )}

      {/* Stats Grid */}
      <AgentStats agent={agent} sales={agentSales} />

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <AgentSalesChart sales={agentSales} />
        <AgentCommissionChart sales={agentSales} />
      </div>

      {/* Top Agents */}
      <TopAgents currentAgentId={agent.id} />
    </div>
  );
}