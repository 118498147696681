import React, { useState, useMemo } from 'react';
import { useAgents } from '../hooks/useAgents';
import { useSales } from '../hooks/useSales';
import { Users, Plus, Search, Mail, Phone, Calendar, Edit, Trash2 } from 'lucide-react';
import AgentCard from '../components/AgentCard';
import AgentForm from '../components/AgentForm';
import Avatar from '../components/ui/Avatar';
import { Link } from 'react-router-dom';
import { formatCurrency, formatEuro } from '../utils/format';
import { formatDateDisplay } from '../utils/date';

export default function Agents() {
  const { agents, loading, error, deleteAgent, addAgent, updateAgent } = useAgents();
  const { sales, calculateCommission } = useSales();
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddAgent, setShowAddAgent] = useState(false);
  const [editingAgent, setEditingAgent] = useState<string | null>(null);

  // Calculate agent stats
  const agentsWithStats = useMemo(() => {
    return agents.map(agent => {
      const agentSales = sales.filter(sale => 
        sale.agentId === agent.id || sale.referrerId === agent.id
      );

      const stats = agentSales.reduce((acc, sale) => {
        const commission = calculateCommission(sale);
        if (sale.agentId === agent.id) {
          acc.agentCommission += commission.agent;
          acc.agencyCommission += commission.agency;
        } else if (sale.referrerId === agent.id && commission.referrer) {
          acc.agentCommission += commission.referrer;
        }
        return acc;
      }, {
        salesCount: agentSales.length,
        agentCommission: 0,
        agencyCommission: 0,
        lastSaleDate: agentSales.length > 0 ? 
          agentSales.sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime())[0].saleDate : 
          null
      });

      return {
        ...agent,
        stats
      };
    });
  }, [agents, sales, calculateCommission]);

  // Filter agents based on search query
  const filteredAgents = useMemo(() => {
    if (!searchQuery) return agentsWithStats;
    
    const query = searchQuery.toLowerCase();
    return agentsWithStats.filter(agent =>
      agent.name.toLowerCase().includes(query) ||
      agent.email.toLowerCase().includes(query) ||
      (agent.phone && agent.phone.toLowerCase().includes(query))
    );
  }, [agentsWithStats, searchQuery]);

  const handleDelete = async (id: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet agent ?')) {
      await deleteAgent(id);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-4 sm:p-6 border-b border-gray-200/50">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <div className="flex-1">
              <div className="flex items-center gap-3">
                <Users className="w-6 h-6 text-blue-600" />
                <h2 className="text-xl font-semibold">Agents</h2>
              </div>
            </div>
            <button
              onClick={() => setShowAddAgent(true)}
              className="w-full sm:w-auto px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center justify-center gap-2"
            >
              <Plus className="w-5 h-5" />
              Nouvel agent
            </button>
          </div>
        </div>

        {/* Search */}
        <div className="p-4 sm:p-6">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="text"
              placeholder="Rechercher un agent..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      {/* Agents Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pb-20 lg:pb-0">
        {filteredAgents.map((agent) => (
          <div
            key={agent.id}
            className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden hover:shadow-lg transition-all duration-200"
          >
            <div className="p-4 sm:p-6">
              <div className="flex items-center gap-4">
                <Avatar name={agent.name} size="lg" />
                <div className="flex-1 min-w-0">
                  <Link 
                    to={`/agents/${agent.id}`}
                    className="text-lg font-semibold text-gray-900 hover:text-blue-600"
                  >
                    {agent.name}
                  </Link>
                  <div className="mt-1 space-y-1">
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      <Mail className="w-4 h-4" />
                      <span className="truncate">{agent.email}</span>
                    </div>
                    {agent.phone && (
                      <div className="flex items-center gap-2 text-sm text-gray-500">
                        <Phone className="w-4 h-4" />
                        <span>{agent.phone}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bg-blue-100 text-blue-800 text-2xl font-bold px-4 py-2 rounded-lg">
                  {agent.stats.salesCount}
                </div>
              </div>

              <div className="mt-4 grid grid-cols-2 gap-3">
                <div className="p-3 bg-gradient-to-br from-green-50 to-green-100/50 rounded-lg border border-green-200/50">
                  <div className="text-sm text-gray-600 mb-1">Commission agent</div>
                  <div className="font-bold text-green-900">{formatCurrency(agent.stats.agentCommission)}</div>
                  <div className="text-xs text-green-700 mt-1">
                    Estimation EUR : {formatEuro(agent.stats.agentCommission)}
                  </div>
                </div>

                <div className="p-3 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-lg border border-purple-200/50">
                  <div className="text-sm text-gray-600 mb-1">Commission agence</div>
                  <div className="font-bold text-purple-900">{formatCurrency(agent.stats.agencyCommission)}</div>
                  <div className="text-xs text-purple-700 mt-1">
                    Estimation EUR : {formatEuro(agent.stats.agencyCommission)}
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 sm:px-6 py-3 bg-gray-50 border-t border-gray-200/50 flex flex-col sm:flex-row sm:items-center justify-between gap-3">
              <div className="flex items-center gap-2 text-sm text-gray-500">
                <Calendar className="w-4 h-4" />
                {agent.stats.lastSaleDate ? (
                  <span>Dernière vente : {formatDateDisplay(agent.stats.lastSaleDate)}</span>
                ) : (
                  <span>Aucune vente</span>
                )}
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setEditingAgent(agent.id)}
                  className="p-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  <Edit className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDelete(agent.id)}
                  className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {filteredAgents.length === 0 && (
          <div className="col-span-2 text-center py-12 bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl">
            <Users className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">Aucun agent trouvé</p>
          </div>
        )}
      </div>

      {/* Add/Edit Agent Modal */}
      {(showAddAgent || editingAgent) && (
        <AgentForm
          onSubmit={async (agentData) => {
            try {
              if (editingAgent) {
                await updateAgent(editingAgent, agentData);
              } else {
                await addAgent(agentData);
              }
              setShowAddAgent(false);
              setEditingAgent(null);
            } catch (err) {
              console.error('Error saving agent:', err);
              throw err;
            }
          }}
          onClose={() => {
            setShowAddAgent(false);
            setEditingAgent(null);
          }}
          initialData={editingAgent ? agents.find(a => a.id === editingAgent) : undefined}
          createAccount={!editingAgent}
        />
      )}
    </div>
  );
}