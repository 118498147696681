const en = {
  common: {
    loading: 'Loading...',
    save: 'Save',
    cancel: 'Cancel',
    edit: 'Edit',
    delete: 'Delete',
    close: 'Close',
    success: 'Operation successful',
    error: 'An error occurred',
    required: 'Required field',
    search: 'Search...',
    noResults: 'No results found',
    menu: {
      dashboard: 'Dashboard',
      sales: 'Sales',
      agents: 'Agents',
      settings: 'Settings',
      profile: 'Profile',
      logout: 'Logout'
    }
  },
  auth: {
    login: {
      title: 'Login',
      email: 'Email',
      password: 'Password',
      submit: 'Sign in',
      forgotPassword: 'Forgot password?',
      error: 'Invalid email or password'
    }
  },
  dashboard: {
    title: 'Dashboard',
    stats: {
      totalSales: 'Total Sales',
      monthlyAverage: 'Monthly Average',
      activeAgents: 'Active Agents'
    }
  },
  sales: {
    title: 'Sales',
    new: 'New Sale',
    details: {
      title: 'Sale Details',
      property: 'Property',
      client: 'Client',
      agent: 'Agent',
      commission: 'Commission'
    },
    status: {
      pending: 'Pending',
      completed: 'Completed',
      cancelled: 'Cancelled'
    }
  },
  agents: {
    title: 'Agents',
    new: 'New Agent',
    profile: {
      title: 'Agent Profile',
      performance: 'Performance',
      sales: 'Sales'
    }
  },
  profile: {
    title: 'My Profile',
    personal: {
      title: 'Personal Information',
      name: 'Full Name',
      email: 'Email',
      phone: 'Phone'
    },
    preferences: {
      title: 'Preferences',
      language: 'Language',
      theme: 'Theme'
    },
    security: {
      title: 'Security',
      password: 'Password',
      twoFactor: 'Two-Factor Authentication'
    },
    notifications: {
      title: 'Notifications',
      email: 'Email',
      push: 'Push',
      sms: 'SMS'
    }
  },
  settings: {
    title: 'Settings',
    general: {
      title: 'General',
      language: 'Language',
      dateFormat: 'Date Format',
      timeZone: 'Time Zone'
    },
    notifications: {
      title: 'Notifications',
      email: 'Email',
      push: 'Push',
      sms: 'SMS'
    },
    security: {
      title: 'Security',
      password: 'Password',
      twoFactor: 'Two-Factor Authentication'
    }
  }
};

export default en;