import { useState, useEffect } from 'react';
import { collection, onSnapshot, addDoc, updateDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Agent } from '../types';

export function useAgents() {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'agents'),
      (snapshot) => {
        const agentData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Agent[];
        setAgents(agentData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching agents:', err);
        setError('Failed to fetch agents');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const addAgent = async (agentData: Omit<Agent, 'id' | 'totalSales' | 'totalCommission'>) => {
    try {
      const docRef = await addDoc(collection(db, 'agents'), {
        ...agentData,
        totalSales: 0,
        totalCommission: 0,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      return docRef.id;
    } catch (error) {
      console.error('Error adding agent:', error);
      throw new Error('Failed to add agent');
    }
  };

  const updateAgent = async (id: string, updates: Partial<Agent>) => {
    try {
      const agentRef = doc(db, 'agents', id);
      await updateDoc(agentRef, {
        ...updates,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error updating agent:', error);
      throw new Error('Failed to update agent');
    }
  };

  const deleteAgent = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'agents', id));
    } catch (error) {
      console.error('Error deleting agent:', error);
      throw new Error('Failed to delete agent');
    }
  };

  return {
    agents,
    loading,
    error,
    addAgent,
    updateAgent,
    deleteAgent,
  };
}