// src/components/agent/AgentPerformanceReport.tsx
import React from 'react';
import { motion } from 'framer-motion';
import { Trophy, TrendingUp, Building, DollarSign, Calendar, Clock } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../ui/BlurredNumber';

interface AgentPerformanceReportProps {
  rank: number;
  totalAgents: number;
  agencyCommission: number;
  bonus: number;
  daysSinceLastSale: number | null;
  monthlyAverage: number;
  totalVolume: number;
  salesCount: number;
}

function roundMonthlyAverage(average: number): number {
  // If decimal part is >= 0.6, round up
  // If decimal part is < 0.6, round down
  return average % 1 >= 0.6 ? Math.ceil(average) : Math.floor(average);
}

export default function AgentPerformanceReport({
  rank,
  totalAgents,
  agencyCommission,
  bonus,
  daysSinceLastSale,
  monthlyAverage,
  totalVolume,
  salesCount
}: AgentPerformanceReportProps) {
  const roundedMonthlyAverage = roundMonthlyAverage(monthlyAverage);

  return (
    <div className="grid grid-cols-1 gap-6">
      {/* Main Stats Card */}
      <div className="bg-gradient-to-br from-blue-600 to-blue-800 rounded-xl p-4 sm:p-6 text-white">
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 mb-6">
          <div className="p-3 bg-white/10 rounded-xl w-fit">
            <Trophy className="w-8 h-8" />
          </div>
          <div>
            <h2 className="text-xl font-semibold">Performance Globale</h2>
            <p className="text-blue-200">#{rank} sur {totalAgents} agents</p>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <p className="text-blue-200 mb-2">Commission générée</p>
            <p className="text-2xl sm:text-3xl font-bold break-words">
              <BlurredNumber value={formatCurrency(agencyCommission)} />
            </p>
            <span className="inline-block mt-2 px-3 py-1 bg-white/10 rounded-full text-sm">
              <BlurredNumber value={formatEuro(agencyCommission)} />
            </span>
            {bonus > 0 && (
              <div className="mt-2 text-green-300">
                + <BlurredNumber value={formatCurrency(bonus)} /> bonus
              </div>
            )}
          </div>

          <div>
            <p className="text-blue-200 mb-2">Activité</p>
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4 flex-shrink-0" />
                <span className="text-sm sm:text-base">
                  {daysSinceLastSale !== null
                    ? `${daysSinceLastSale} jours depuis la dernière vente`
                    : 'Aucune vente'}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4 flex-shrink-0" />
                <span className="text-sm sm:text-base">
                  {roundedMonthlyAverage} ventes/mois en moyenne
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
        {/* Volume Card */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl border border-gray-200/50 p-4 sm:p-6"
        >
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-blue-100 rounded-lg">
              <Building className="w-6 h-6 text-blue-600" />
            </div>
            <h3 className="font-medium">Volume total</h3>
          </div>
          <p className="text-2xl sm:text-3xl font-bold text-gray-900 break-words">
            <BlurredNumber value={formatCurrency(totalVolume)} />
          </p>
          <span className="inline-block mt-2 px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm">
            <BlurredNumber value={formatEuro(totalVolume)} />
          </span>
        </motion.div>

        {/* Sales Count Card */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white rounded-xl border border-gray-200/50 p-4 sm:p-6"
        >
          <div className="flex items-center gap-3 mb-4">
            <div className="p-2 bg-green-100 rounded-lg">
              <TrendingUp className="w-6 h-6 text-green-600" />
            </div>
            <h3 className="font-medium">Nombre de ventes</h3>
          </div>
          <p className="text-2xl sm:text-3xl font-bold text-gray-900">{salesCount}</p>
          <p className="mt-2 text-gray-500">
            {roundedMonthlyAverage} ventes par mois
          </p>
        </motion.div>
      </div>
    </div>
  );
}