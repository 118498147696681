import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { ToastProvider } from './contexts/ToastContext';
import { verifyFirebaseSetup } from './lib/firebase'; 
import { initializeCollections } from './lib/firebase/init';
import './index.css';

async function initializeApp() {
  try {
    await verifyFirebaseSetup();
    await initializeCollections();
  } catch (error) {
    console.error('Firebase initialization error:', error);
  } finally {
    createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <AuthProvider>
          <LanguageProvider>
            <ThemeProvider>
              <ToastProvider>
                <App />
              </ToastProvider>
            </ThemeProvider>
          </LanguageProvider>
        </AuthProvider>
      </React.StrictMode>
    );
  }
}

initializeApp();