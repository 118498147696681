import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';

interface StatCardProps {
  title: string;
  value: React.ReactNode;
  subtitle?: string;
  euroValue?: React.ReactNode;
  icon: LucideIcon;
  color: 'green' | 'amber' | 'purple' | 'blue';
  delay?: number;
}

const colorStyles = {
  green: {
    background: 'bg-gradient-to-br from-emerald-500/10 to-emerald-500/5',
    border: 'border-emerald-500/20',
    icon: 'bg-emerald-500/20 text-emerald-500',
    text: 'text-emerald-700',
    badge: 'bg-emerald-500/10 border-emerald-500/20 text-emerald-700',
    ring: 'ring-emerald-400/30'
  },
  amber: {
    background: 'bg-gradient-to-br from-amber-500/10 to-amber-500/5',
    border: 'border-amber-500/20',
    icon: 'bg-amber-500/20 text-amber-500',
    text: 'text-amber-700',
    badge: 'bg-amber-500/10 border-amber-500/20 text-amber-700',
    ring: 'ring-amber-400/30'
  },
  purple: {
    background: 'bg-gradient-to-br from-purple-500/10 to-purple-500/5',
    border: 'border-purple-500/20',
    icon: 'bg-purple-500/20 text-purple-500',
    text: 'text-purple-700',
    badge: 'bg-purple-500/10 border-purple-500/20 text-purple-700',
    ring: 'ring-purple-400/30'
  },
  blue: {
    background: 'bg-gradient-to-br from-blue-500/10 to-blue-500/5',
    border: 'border-blue-500/20',
    icon: 'bg-blue-500/20 text-blue-500',
    text: 'text-blue-700',
    badge: 'bg-blue-500/10 border-blue-500/20 text-blue-700',
    ring: 'ring-blue-400/30'
  }
};

export default function StatCard({
  title,
  value,
  subtitle,
  euroValue,
  icon: Icon,
  color,
  delay = 0
}: StatCardProps) {
  const styles = colorStyles[color];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.5,
        delay,
        ease: [0.4, 0, 0.2, 1],
      }}
      whileHover={{ scale: 1.02 }}
      className={`relative overflow-hidden rounded-2xl border backdrop-blur-xl 
        ${styles.background} ${styles.border} ${styles.ring}
        transition-all duration-500 hover:ring-2 hover:shadow-lg
        group`}
    >
      {/* Animated gradient background */}
      <motion.div
        className={`absolute -inset-[100%] opacity-0 group-hover:opacity-100 ${styles.glow} blur-3xl`}
        animate={{
          transform: [
            'translate(100%, 100%)',
            'translate(0%, 0%)',
            'translate(-100%, -100%)'
          ]
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "linear"
        }}
      />

      {/* Content */}
      <div className="relative p-4 h-full backdrop-blur-sm">
        <div className="flex items-center gap-3 mb-4">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: delay + 0.1
            }}
            className={`p-2.5 rounded-xl ${styles.icon} 
              transition-transform duration-300 group-hover:scale-110 
              shadow-lg shadow-black/5`}
          >
            <Icon className="w-5 h-5" />
          </motion.div>
          <motion.h3
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: delay + 0.2 }}
            className="text-sm font-medium text-gray-600"
          >
            {title}
          </motion.h3>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: delay + 0.3 }}
          className="space-y-2"
        >
          <motion.div
            className={`text-2xl font-bold ${styles.text} tracking-tight`}
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              delay: delay + 0.4
            }}
          >
            {value}
          </motion.div>

          {euroValue && (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: delay + 0.5 }}
              className="flex items-center gap-2"
            >
              <span className={`inline-flex px-2.5 py-0.5 text-sm font-medium rounded-full 
                border backdrop-blur-sm ${styles.badge}
                transition-all duration-300 group-hover:scale-105`}>
                {euroValue}
              </span>
            </motion.div>
          )}

          {subtitle && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: delay + 0.6 }}
              className="mt-2 text-sm text-gray-500"
            >
              {subtitle}
            </motion.p>
          )}
        </motion.div>
      </div>
    </motion.div>
  );
}