import React from 'react';
import { motion } from 'framer-motion';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Phone, Mail, Calendar, MessageSquare, Bot, User } from 'lucide-react';
import type { Lead } from '../../types/leads';
import { formatDateDisplay } from '../../utils/date';
import Avatar from '../ui/Avatar';

interface LeadCardProps {
  lead: Lead;
  onClick: () => void;
}

export default function LeadCard({ lead, onClick }: LeadCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: lead.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: 'grab'
  };

  return (
    <motion.div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      onClick={onClick}
      className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50 hover:shadow-md transition-all duration-200"
    >
      <div className="flex items-start justify-between mb-3">
        <div className="flex items-center gap-3">
          <Avatar name={lead.name} size="sm" />
          <div>
            <h4 className="font-medium">{lead.name}</h4>
            <div className="flex items-center gap-2 mt-1">
              <span className="text-sm text-gray-500">{lead.source}</span>
              {lead.assignedTo && (
                <div className="flex items-center gap-1 px-2 py-0.5 bg-blue-50 text-blue-600 rounded-full text-xs">
                  <User className="w-3 h-3" />
                  <span>{lead.assignedTo}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {lead.automationStatus.call && (
            <div className="p-1.5 bg-green-100 text-green-600 rounded-lg" title="Appel IA effectué">
              <Phone className="w-4 h-4" />
            </div>
          )}
          {lead.automationStatus.message && (
            <div className="p-1.5 bg-blue-100 text-blue-600 rounded-lg" title="Message automatique envoyé">
              <MessageSquare className="w-4 h-4" />
            </div>
          )}
          {lead.source === 'Ancien client' && (
            <div className="px-2 py-1 bg-purple-100 text-purple-600 rounded-lg text-xs font-medium">
              Client existant
            </div>
          )}
        </div>
      </div>

      <div className="space-y-2">
        <a
          href={`mailto:${lead.email}`}
          className="flex items-center gap-2 text-sm text-gray-600 hover:text-blue-600"
          onClick={e => e.stopPropagation()}
        >
          <Mail className="w-4 h-4" />
          {lead.email}
        </a>
        <a
          href={`tel:${lead.phone}`}
          className="flex items-center gap-2 text-sm text-gray-600 hover:text-blue-600"
          onClick={e => e.stopPropagation()}
        >
          <Phone className="w-4 h-4" />
          {lead.phone}
        </a>
        <div className="flex items-center gap-2 text-sm text-gray-500">
          <Calendar className="w-4 h-4" />
          {formatDateDisplay(lead.createdAt)}
        </div>
      </div>

      {lead.notes && (
        <div className="mt-3 p-2 bg-gray-50 rounded-lg text-sm text-gray-600">
          {lead.notes}
        </div>
      )}
    </motion.div>
  );
}