// Global color cache that persists between renders
const colorCache = new Map<string, string>();

const colors = [
  '#FF6B6B', // Red
  '#4ECDC4', // Teal
  '#45B7D1', // Blue
  '#96CEB4', // Green
  '#FFEEAD', // Yellow
  '#D4A5A5', // Pink
  '#9B59B6'  // Purple
];

export function getInitials(name: string): string {
  if (!name) return '';
  return name
    .split(' ')
    .map(n => n[0])
    .join('')
    .toUpperCase();
}

// Deterministic hash function for consistent colors
function hashString(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return Math.abs(hash);
}

export function getAvatarColor(name: string): string {
  if (!name) return colors[0];
  
  // Check cache first
  if (colorCache.has(name)) {
    return colorCache.get(name)!;
  }

  // Generate deterministic color based on name
  const hash = hashString(name);
  const color = colors[hash % colors.length];
  
  // Cache the result
  colorCache.set(name, color);
  return color;
}

export type AvatarSize = 'sm' | 'md' | 'lg';

export const sizeClasses: Record<AvatarSize, string> = {
  sm: 'w-8 h-8 text-sm',
  md: 'w-12 h-12 text-lg',
  lg: 'w-16 h-16 text-xl'
};