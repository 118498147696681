import React from 'react';
import { motion } from 'framer-motion';
import { useVisibilityStore } from '../../store/visibilityStore';

interface BlurredNumberProps {
  value: string | number;
  className?: string;
}

export default function BlurredNumber({ value, className = '' }: BlurredNumberProps) {
  const hideNumbers = useVisibilityStore((state) => state.hideNumbers);

  // Ensure the value is converted to string
  const displayValue = String(value);

  return (
    <motion.span
      initial={false}
      animate={{ 
        filter: hideNumbers ? 'blur(6px)' : 'blur(0px)',
        opacity: hideNumbers ? 0.9 : 1
      }}
      transition={{ duration: 0.2 }}
      className={`${className} ${hideNumbers ? 'select-none pointer-events-none' : ''}`}
      style={{ 
        willChange: 'filter',
        display: 'inline-block', // Ensure proper rendering of blur effect
        whiteSpace: 'nowrap' // Prevent number from breaking across lines
      }}
    >
      {displayValue}
    </motion.span>
  );
}