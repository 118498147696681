import React from 'react';
import { Database, Mail, Server, Shield } from 'lucide-react';

export default function Install() {
  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <h1 className="text-2xl font-semibold mb-6">Guide d'Installation</h1>

      {/* Database Setup */}
      <div className="card">
        <div className="flex items-center gap-3 mb-4">
          <Database className="w-6 h-6 text-blue-600" />
          <h2 className="text-xl font-semibold">Configuration de la Base de Données</h2>
        </div>
        
        <div className="space-y-4">
          <p>1. Créez un fichier <code>.env</code> à la racine du projet avec les variables suivantes:</p>
          <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto">
            {`DATABASE_URL=sqlite://./data.db
# Ou pour MySQL/PostgreSQL:
# DATABASE_URL=mysql://user:password@localhost:3306/palmcrm
# DATABASE_URL=postgresql://user:password@localhost:5432/palmcrm`}
          </pre>
          
          <p>2. Exécutez les migrations:</p>
          <pre className="bg-gray-50 p-4 rounded-lg">
            {`npm run migrate`}
          </pre>
        </div>
      </div>

      {/* Email Setup */}
      <div className="card">
        <div className="flex items-center gap-3 mb-4">
          <Mail className="w-6 h-6 text-blue-600" />
          <h2 className="text-xl font-semibold">Configuration Email</h2>
        </div>
        
        <div className="space-y-4">
          <p>1. Créez un compte sur EmailJS et ajoutez ces variables dans <code>.env</code>:</p>
          <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto">
            {`VITE_EMAILJS_SERVICE_ID=votre_service_id
VITE_EMAILJS_PUBLIC_KEY=votre_public_key
VITE_EMAILJS_PRIVATE_KEY=votre_private_key`}
          </pre>
          
          <p>2. Créez un template EmailJS avec les variables suivantes:</p>
          <ul className="list-disc list-inside space-y-2 ml-4">
            <li><code>to_name</code> - Nom du destinataire</li>
            <li><code>to_email</code> - Email du destinataire</li>
            <li><code>message</code> - Contenu du message</li>
            <li><code>app_url</code> - URL de l'application</li>
          </ul>
        </div>
      </div>

      {/* Server Setup */}
      <div className="card">
        <div className="flex items-center gap-3 mb-4">
          <Server className="w-6 h-6 text-blue-600" />
          <h2 className="text-xl font-semibold">Configuration du Serveur</h2>
        </div>
        
        <div className="space-y-4">
          <p>1. Installation des dépendances:</p>
          <pre className="bg-gray-50 p-4 rounded-lg">npm install</pre>
          
          <p>2. Démarrage en développement:</p>
          <pre className="bg-gray-50 p-4 rounded-lg">npm run dev</pre>
          
          <p>3. Build pour production:</p>
          <pre className="bg-gray-50 p-4 rounded-lg">npm run build</pre>
          
          <p>4. Démarrage en production:</p>
          <pre className="bg-gray-50 p-4 rounded-lg">npm run start</pre>
        </div>
      </div>

      {/* Security */}
      <div className="card">
        <div className="flex items-center gap-3 mb-4">
          <Shield className="w-6 h-6 text-blue-600" />
          <h2 className="text-xl font-semibold">Sécurité</h2>
        </div>
        
        <div className="space-y-4">
          <p>1. Compte administrateur par défaut:</p>
          <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto">
            {`Email: hello@ioagency.ae
Password: Cba9967911@`}
          </pre>
          
          <p className="text-red-600">⚠️ Changez immédiatement ces identifiants après la première connexion!</p>
          
          <p>2. Configuration CORS dans <code>.env</code>:</p>
          <pre className="bg-gray-50 p-4 rounded-lg overflow-x-auto">
            {`CORS_ORIGIN=https://votre-domaine.com`}
          </pre>
        </div>
      </div>
    </div>
  );
}