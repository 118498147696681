import { FirebaseOptions } from 'firebase/app';

export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyD85I8BK2vBD92xKGkDK68DXYFnYXjfoK4",
  authDomain: "realestatecrm-84f20.firebaseapp.com",
  projectId: "realestatecrm-84f20",
  storageBucket: "realestatecrm-84f20.firebasestorage.app",
  messagingSenderId: "93743705924",
  appId: "1:93743705924:web:a76ff1c551d93f6d497111",
  measurementId: "G-SPQGNKXZP1"
};

export const COLLECTIONS = {
  USERS: 'users',
  AGENTS: 'agents',
  SALES: 'sales',
  SETTINGS: 'settings',
  LOGIN_LOGS: 'loginLogs', 
  LEADS: 'leads',
  COMMENTS: 'comments'
} as const;

export const FIREBASE_CONFIG = {
  maxRetries: 3,
  retryDelay: 1000,
  cacheDuration: 5 * 60 * 1000, // 5 minutes
  maxCacheSize: 100 * 1024 * 1024, // 100MB
  offlinePersistence: true,
  enableLogging: process.env.NODE_ENV === 'development',
  defaultCollections: [
    'users',
    'agents',
    'sales',
    'settings',
    'leads',
    'comments'
  ]
};