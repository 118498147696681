import type { Lead } from '../types/leads';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import OpenAI from 'openai';

export async function generateWhatsAppMessage(lead: Lead): Promise<boolean> {
  try {
    // Get OpenAI settings
    const settingsRef = doc(db, 'settings', 'general');
    const settingsDoc = await getDoc(settingsRef);
    const settings = settingsDoc.data();

    if (!settings?.openai?.enabled || !settings?.openai?.apiKey) {
      console.log('OpenAI not configured or disabled');
      return false;
    }

    const openai = new OpenAI({
      apiKey: settings.openai.apiKey,
      dangerouslyAllowBrowser: true // Only for demo purposes
    });

    const completion = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: settings.openai.messagePrompt.replace('{{name}}', lead.name)
        }
      ],
      temperature: 0.7,
      max_tokens: 200
    });

    const message = completion.choices[0].message.content?.trim();
    if (!message) {
      throw new Error('No message generated');
    }

    // Open WhatsApp with generated message
    const phone = lead.phone.replace(/[^0-9]/g, '');
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/${phone}?text=${encodedMessage}`, '_blank');

    return true;
  } catch (error) {
    console.error('Error generating WhatsApp message:', error);
    return false;
  }
}