import React, { useMemo } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useSales } from '../hooks/useSales';
import { Building, DollarSign, Clock, TrendingUp, AlertCircle, Calendar } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function MySales() {
  const { user } = useAuth();
  const { sales, calculateCommission } = useSales();

  const mySales = useMemo(() => {
    if (!user?.agentId) return [];
    return sales.filter(sale => sale.agentId === user.agentId || sale.referrerId === user.agentId);
  }, [sales, user]);

  const stats = useMemo(() => {
    return mySales.reduce((acc, sale) => {
      const commission = calculateCommission(sale);
      const isMainAgent = sale.agentId === user?.agentId;
      const myCommission = isMainAgent ? commission.agent : (commission.referrer || 0);

      // Update totals
      acc.totalSales += sale.propertyPrice;
      acc.totalCommission += myCommission;

      // Update status counts
      if (sale.status === 'agent_paid') {
        acc.paidCommission += myCommission;
      } else {
        acc.pendingCommission += myCommission;
      }

      // Group by month for chart
      const date = new Date(sale.createdAt);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      
      if (!acc.monthlyData[monthKey]) {
        acc.monthlyData[monthKey] = {
          month: monthKey,
          sales: 0,
          commission: 0,
        };
      }
      acc.monthlyData[monthKey].sales += 1;
      acc.monthlyData[monthKey].commission += myCommission;

      return acc;
    }, {
      totalSales: 0,
      totalCommission: 0,
      paidCommission: 0,
      pendingCommission: 0,
      monthCount: 0,
      monthlyData: {} as Record<string, { month: string; sales: number; commission: number; }>,
    });
  }, [mySales, user]);

  const chartData = Object.values(stats.monthlyData).slice(-6);
  const monthCount = Object.keys(stats.monthlyData).length || 1;

  // Calculate monthly averages
  const monthlyAverages = {
    sales: mySales.length / monthCount,
    volume: stats.totalSales / monthCount,
    commission: stats.totalCommission / monthCount,
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'AED',
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      <div className="bg-white/90 dark:bg-gray-800/90 rounded-lg shadow-sm overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <DollarSign className="w-6 h-6 text-blue-600" />
              <h2 className="text-xl font-semibold">Mes Ventes</h2>
            </div>
            <div className="text-sm text-gray-500">
              Total des ventes : {mySales.length}
            </div>
          </div>
        </div>

        {/* KPI Cards */}
        <div className="grid grid-cols-2 gap-6 p-6">
          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                <Building className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              </div>
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Volume total</p>
                <p className="text-xl font-semibold">{formatCurrency(stats.totalSales)}</p>
              </div>
            </div>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-green-50 dark:bg-green-900/20 rounded-lg">
                <DollarSign className="w-6 h-6 text-green-600 dark:text-green-400" />
              </div>
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Commission totale</p>
                <p className="text-xl font-semibold">{formatCurrency(stats.totalCommission)}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6 px-6 pb-6">
          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-purple-50 dark:bg-purple-900/20 rounded-lg">
                <Clock className="w-6 h-6 text-purple-600 dark:text-purple-400" />
              </div>
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Commission en attente</p>
                <p className="text-xl font-semibold">{formatCurrency(stats.pendingCommission)}</p>
              </div>
            </div>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-green-50 dark:bg-green-900/20 rounded-lg">
                <TrendingUp className="w-6 h-6 text-green-600 dark:text-green-400" />
              </div>
              <div>
                <p className="text-sm text-gray-600 dark:text-gray-400">Commission payée</p>
                <p className="text-xl font-semibold">{formatCurrency(stats.paidCommission)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Monthly Averages */}
      <div className="bg-white/90 dark:bg-gray-800/90 rounded-lg shadow-sm overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-3">
            <Calendar className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Moyennes mensuelles</h2>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6 p-6">
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Ventes par mois</p>
            <p className="text-xl font-semibold">{monthlyAverages.sales.toFixed(1)}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Volume mensuel</p>
            <p className="text-xl font-semibold">{formatCurrency(monthlyAverages.volume)}</p>
          </div>
          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400">Commission mensuelle</p>
            <p className="text-xl font-semibold">{formatCurrency(monthlyAverages.commission)}</p>
          </div>
        </div>
      </div>

      {/* Performance Chart */}
      <div className="bg-white/90 dark:bg-gray-800/90 rounded-lg shadow-sm overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-3">
            <TrendingUp className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Performance mensuelle</h2>
          </div>
        </div>
        <div className="h-[400px] p-6">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="month"
                tick={{ fontSize: 12 }}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                yAxisId="left"
                tick={{ fontSize: 12 }}
                tickLine={false}
                axisLine={false}
                tickFormatter={formatCurrency}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                tick={{ fontSize: 12 }}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value} ventes`}
              />
              <Tooltip
                formatter={(value: number, name: string) => [
                  name === 'sales' ? `${value} ventes` : formatCurrency(value),
                  name === 'sales' ? 'Ventes' : 'Commission'
                ]}
              />
              <Bar
                yAxisId="left"
                dataKey="commission"
                fill="#3B82F6"
                name="Commission"
                radius={[4, 4, 0, 0]}
              />
              <Bar
                yAxisId="right"
                dataKey="sales"
                fill="#8B5CF6"
                name="Ventes"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Recent Sales */}
      <div className="bg-white/90 dark:bg-gray-800/90 rounded-lg shadow-sm overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-3">
            <Building className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Dernières ventes</h2>
          </div>
        </div>
        {mySales.length > 0 ? (
          <div className="space-y-4 p-6">
            {mySales.slice(0, 5).map(sale => {
              const commission = calculateCommission(sale);
              const myCommission = sale.agentId === user?.agentId ? commission.agent : commission.referrer;

              return (
                <div key={sale.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div>
                    <h3 className="font-medium">{sale.project}</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Unité {sale.unitNumber}
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="font-medium">{formatCurrency(myCommission || 0)}</p>
                    <p className={`text-sm ${
                      sale.status === 'agent_paid' ? 'text-green-600' :
                      sale.status === 'commission_received' ? 'text-blue-600' :
                      'text-yellow-600'
                    }`}>
                      {sale.status === 'agent_paid' ? 'Payé' :
                       sale.status === 'commission_received' ? 'Commission reçue' :
                       'En attente'}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center py-12 p-6">
            <AlertCircle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-600 dark:text-gray-400">Aucune vente trouvée</p>
          </div>
        )}
      </div>
    </div>
  );
}