import type { Lead } from '../types/leads';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export async function triggerZapierWebhook(lead: Lead): Promise<boolean> {
  try {
    // Get Zapier settings
    const settingsRef = doc(db, 'settings', 'general');
    const settingsDoc = await getDoc(settingsRef);
    const settings = settingsDoc.data();

    if (!settings?.zapier?.enabled || !settings?.zapier?.webhookUrl) {
      console.log('Zapier not configured or disabled');
      return false;
    }

    const response = await fetch(settings.zapier.webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: lead.name,
        email: lead.email,
        phone: lead.phone,
        source: lead.source,
        stage: lead.stage,
        notes: lead.notes,
        createdAt: new Date().toISOString()
      })
    });

    if (!response.ok) {
      throw new Error('Failed to trigger Zapier webhook');
    }

    return true;
  } catch (error) {
    console.error('Error triggering Zapier webhook:', error);
    return false;
  }
}