import emailjs from '@emailjs/browser';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Agent, Sale } from '../types';
import { formatCurrency } from '../utils/format';

export async function sendSaleNotification(sale: Sale, agent: Agent, type: 'new' | 'status_change' = 'new') {
  try {
    const settingsRef = doc(db, 'settings', 'general');
    const settingsDoc = await getDoc(settingsRef);
    const settings = settingsDoc.data();

    if (!settings?.emailjs) {
      throw new Error('EmailJS configuration not found');
    }

    emailjs.init(settings.emailjs.publicKey);

    const templateParams = {
      to_name: agent.name,
      to_email: agent.email,
      subject: `${type === 'new' ? 'Nouvelle vente' : 'Mise à jour'} - ${sale.project}`,
      message: `
        Projet: ${sale.project}
        Unité: ${sale.unitNumber}
        Prix: ${formatCurrency(sale.propertyPrice)}
        Commission: ${formatCurrency(sale.developerCommission)}
      `,
      app_url: import.meta.env.VITE_APP_URL
    };

    await emailjs.send(
      settings.emailjs.serviceId,
      settings.emailjs.templateId,
      templateParams
    );
  } catch (error) {
    console.error('Error sending notification:', error);
    throw error;
  }
}

export async function sendEmailNotification(agent: Agent, password?: string) {
  try {
    const settingsRef = doc(db, 'settings', 'general');
    const settingsDoc = await getDoc(settingsRef);
    const settings = settingsDoc.data();

    if (!settings?.emailjs) {
      throw new Error('EmailJS configuration not found');
    }

    emailjs.init(settings.emailjs.publicKey);

    const templateParams = {
      to_name: agent.name,
      to_email: agent.email,
      subject: password ? 'Vos identifiants PalmCRM' : 'Modification de votre compte PalmCRM',
      message: password 
        ? `Votre compte a été créé avec succès.\n\nEmail: ${agent.email}\nMot de passe: ${password}`
        : `Votre compte a été mis à jour.\n\nVos nouvelles informations:\nNom: ${agent.name}\nEmail: ${agent.email}\nTéléphone: ${agent.phone || 'Non renseigné'}`,
      app_url: import.meta.env.VITE_APP_URL
    };

    await emailjs.send(
      settings.emailjs.serviceId,
      settings.emailjs.templateId,
      templateParams
    );

    return true;
  } catch (error) {
    console.error('Error sending email notification:', error);
    throw error;
  }
}

export async function sendWhatsAppNotification(agent: Agent, password?: string) {
  if (!agent.phone) return;
  
  const message = password
    ? `👋 Bonjour ${agent.name},\n\n` +
      `🔑 Voici vos identifiants de connexion:\n` +
      `📧 Email: ${agent.email}\n` +
      `🔒 Mot de passe: ${password}\n\n` +
      `🌐 Connectez-vous sur: ${import.meta.env.VITE_APP_URL}`
    : `👋 Bonjour ${agent.name},\n\n` +
      `✨ Votre compte a été mis à jour avec succès.\n\n` +
      `📧 Email: ${agent.email}\n` +
      `📱 Téléphone: ${agent.phone || 'Non renseigné'}\n\n` +
      `🌐 Connectez-vous sur: ${import.meta.env.VITE_APP_URL}`;
  
  const phone = agent.phone.replace(/[^0-9]/g, '');
  const encodedMessage = encodeURIComponent(message);
  window.open(`https://wa.me/${phone}?text=${encodedMessage}`, '_blank');
}