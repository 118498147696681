import { 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  type User as FirebaseUser 
} from 'firebase/auth';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from './init';
import { verifyPassword } from '../../utils/crypto';
import type { User } from '../../types';

export async function signOutUser() {
  await signOut(auth);
}

export async function getCurrentUser(firebaseUser: FirebaseUser): Promise<User | null> {
  try {
    // Check if it's the admin account
    const adminEmails = ['hello@ioagency.ae', 'sharaf@palmdubaiimmo.com'];
    if (adminEmails.includes(firebaseUser.email || '')) {
      return {
        id: firebaseUser.uid,
        email: firebaseUser.email,
        name: firebaseUser.email === 'sharaf@palmdubaiimmo.com' ? 'Sharaf' : 'Admin',
        role: 'admin',
        createdAt: new Date().toISOString()
      };
    }

    // Check if it's an agent
    const agentsRef = collection(db, 'agents');
    const q = query(agentsRef, where('email', '==', firebaseUser.email.toLowerCase()));
    const agentSnapshot = await getDocs(q);

    if (!agentSnapshot.empty) {
      const agentDoc = agentSnapshot.docs[0];
      const agentData = agentDoc.data();

      // Update agent's direct access status
      await updateDoc(agentDoc.ref, {
        directAccess: true,
        lastLogin: new Date().toISOString()
      });

      return {
        id: agentDoc.id,
        email: agentData.email,
        name: agentData.name,
        role: agentData.email === 'sharaf@palmdubaiimmo.com' ? 'admin' : 'agent',
        agentId: agentDoc.id,
        avatar: agentData.avatar || '',
        createdAt: agentData.createdAt || new Date().toISOString()
      };
    }

    return null;
  } catch (error) {
    console.error('Error getting current user:', error);
    return null;
  }
}