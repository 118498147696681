import emailjs from '@emailjs/browser';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export async function sendTestEmail(serviceId: string, templateId: string, publicKey: string): Promise<boolean> {
  try {
    emailjs.init(publicKey);

    const templateParams = {
      to_name: 'Test User',
      to_email: 'test@example.com',
      subject: 'Test Email from PalmCRM',
      message: 'This is a test email to verify your EmailJS configuration.',
      app_url: import.meta.env.VITE_APP_URL
    };

    await emailjs.send(serviceId, templateId, templateParams);
    return true;
  } catch (error) {
    console.error('Error sending test email:', error);
    throw error;
  }
}

export async function getEmailJSConfig() {
  try {
    const settingsRef = doc(db, 'settings', 'general');
    const settingsDoc = await getDoc(settingsRef);
    const settings = settingsDoc.data();

    return settings?.emailjs || null;
  } catch (error) {
    console.error('Error getting EmailJS config:', error);
    return null;
  }
}

export function initEmailJS(publicKey: string) {
  try {
    emailjs.init(publicKey);
    return true;
  } catch (error) {
    console.error('Error initializing EmailJS:', error);
    return false;
  }
}