import { format, formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

// Format a date object to YYYY-MM-DD string for input fields
export function formatDateForInput(date: Date | string): string {
  const d = date instanceof Date ? date : new Date(date);
  // Handle invalid dates
  if (isNaN(d.getTime())) {
    const today = new Date();
    return today.toISOString().split('T')[0];
  }
  // Format as YYYY-MM-DD
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// Format a date for display in French locale
export function formatDate(date: string | Date): string {
  const d = date instanceof Date ? date : new Date(date);
  // Handle invalid dates
  if (isNaN(d.getTime())) {
    return '';
  }
  return format(d, 'dd/MM/yyyy', { locale: fr });
}

// Format a date with full month name
export function formatDateDisplay(date: string | Date): string {
  const d = date instanceof Date ? date : new Date(date);
  // Handle invalid dates
  if (isNaN(d.getTime())) {
    return '';
  }
  return format(d, 'd MMMM yyyy', { locale: fr });
}

// Format relative time (e.g., "2 hours ago")
export function formatRelativeTime(date: string | Date): string {
  const d = date instanceof Date ? date : new Date(date);
  // Handle invalid dates
  if (isNaN(d.getTime())) {
    return '';
  }
  return formatDistanceToNow(d, { locale: fr, addSuffix: true });
}

// Parse a date string in any format to a Date object
export function parseDate(dateString: string): Date {
  // Handle YYYY-MM-DD format
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    if (!isNaN(date.getTime())) {
      return date;
    }
  }

  // Try standard date parsing
  const date = dateString ? new Date(dateString) : new Date();
  if (isNaN(date.getTime())) {
    return new Date();
  }
  return date;
}