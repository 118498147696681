import { useParams, useLocation } from 'react-router-dom';
import { useAgents } from './useAgents';
import { useAuth } from './useAuth';
import { agentCredentials } from '../lib/agents';

export const useAgentSlug = () => {
  const { agentSlug } = useParams<{ agentSlug: string }>();
  const { agents } = useAgents();
  const { user } = useAuth();
  const location = useLocation();

  if (!agentSlug) {
    return { agent: null, agentId: null, slug: null };
  }

  // Extract agent ID from slug (last part after the last dash)
  const parts = agentSlug.split('-');
  const agentId = parts[parts.length - 1];
  
  // First check hardcoded agents
  const hardcodedAgent = agentCredentials.find(a => a.id === agentId);
  
  // Then check Firebase agents
  const firebaseAgent = agents.find(a => a.id === agentId);
  
  // Use hardcoded agent if available, otherwise use Firebase agent
  const agent = hardcodedAgent || firebaseAgent;

  // Allow access if:
  // 1. User is authenticated and is the agent
  // 2. User is admin
  // 3. Direct access is enabled for this agent
  const hasAccess = 
    (user?.agentId === agentId) ||
    (user?.role === 'admin') ||
    (agent?.directAccess) ||
    hardcodedAgent; // Always allow access for hardcoded agents

  // Generate slug
  const slug = agent ? `${agent.name.toLowerCase().replace(/\s+/g, '-')}-${agentId}` : agentId;
  
  return {
    agent,
    agentId,
    slug,
    hasAccess
  };
};