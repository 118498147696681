import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Building, Calendar, Mail, Phone, ArrowLeft, FileText, DollarSign, User, TrendingUp, Download } from 'lucide-react';
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import { formatDateDisplay } from '../utils/date';
import { formatCurrency, formatEuro } from '../utils/format';
import { generateClientReport } from '../services/pdfService';
import Avatar from '../components/ui/Avatar';

export default function ClientProfile() {
  const { id } = useParams<{ id: string }>();
  const { sales, calculateCommission } = useSales();
  const { agents } = useAgents();

  // Get all sales for this client
  const clientSales = sales.filter(sale => sale.client.email === id || sale.client.phone === id);
  const client = clientSales[0]?.client;

  if (!client) {
    return <div>Client non trouvé</div>;
  }

  // Calculate total stats including agency commission
  const totalStats = clientSales.reduce((acc, sale) => {
    const commission = calculateCommission(sale);
    acc.totalVolume += sale.propertyPrice;
    acc.totalUnits += 1;
    acc.totalProjects.add(sale.project);
    acc.totalAgencyCommission += commission.agency;
    return acc;
  }, {
    totalVolume: 0,
    totalUnits: 0,
    totalProjects: new Set<string>(),
    totalAgencyCommission: 0
  });

  const handleExportPDF = () => {
    const doc = generateClientReport(client, clientSales, agents, totalStats);
    doc.save(`rapport-client-${client.name.toLowerCase()}-${new Date().toISOString().split('T')[0]}.pdf`);
  };

  const renderSaleCard = (sale: Sale) => {
    const commission = calculateCommission(sale);
    const agent = agents.find(a => a.id === sale.agentId);
    const referrer = sale.referrerId ? agents.find(a => a.id === sale.referrerId) : null;

    return (
      <div key={sale.id} className="block p-6 hover:bg-gray-50/50 transition-colors">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div>
            <Link to={`/sales/${sale.id}`} className="text-lg font-semibold hover:text-blue-600">
              {sale.project}
            </Link>
            <p className="text-sm text-gray-500">Unité {sale.unitNumber}</p>
            <div className="flex items-center gap-2 mt-2 text-sm text-gray-500">
              <Calendar className="w-4 h-4" />
              <span>{formatDateDisplay(sale.saleDate)}</span>
            </div>
            
            {/* Agents Info */}
            <div className="mt-3 space-y-2">
              <div className="flex items-center gap-2">
                <Avatar name={agent?.name || ''} size="sm" />
                <div>
                  <Link
                    to={`/agents/${agent?.id}`}
                    className="text-sm font-medium text-blue-600 hover:text-blue-700"
                  >
                    {agent?.name}
                  </Link>
                  <p className="text-xs text-gray-500">
                    Commission: {formatCurrency(commission.agent)}
                  </p>
                </div>
              </div>

              {referrer && (
                <div className="flex items-center gap-2">
                  <Avatar name={referrer.name} size="sm" />
                  <div>
                    <Link
                      to={`/agents/${referrer.id}`}
                      className="text-sm font-medium text-blue-600 hover:text-blue-700"
                    >
                      {referrer.name}
                    </Link>
                    <p className="text-xs text-gray-500">
                      Commission: {formatCurrency(commission.referrer || 0)}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col items-end gap-2">
            <div className="text-right">
              <p className="text-lg font-bold">{formatCurrency(sale.propertyPrice)}</p>
              <p className="text-sm text-gray-500">Commission agence: {formatCurrency(commission.agency)}</p>
            </div>
            <div className={`px-3 py-1 rounded-full text-sm font-medium ${
              sale.paymentStatus.developer 
                ? 'bg-green-100 text-green-800 border border-green-200' 
                : 'bg-amber-100 text-amber-800 border border-amber-200'
            }`}>
              {sale.paymentStatus.developer ? 'Commission reçue' : 'En attente'}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <Link
                to="/sales"
                className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
              </Link>
              <div>
                <h1 className="text-2xl font-bold">{client.name}</h1>
                <div className="mt-2 space-y-1">
                  {client.email && (
                    <a href={`mailto:${client.email}`} className="flex items-center gap-2 text-gray-600 hover:text-blue-600">
                      <Mail className="w-4 h-4" />
                      {client.email}
                    </a>
                  )}
                  {client.phone && (
                    <a href={`tel:${client.phone}`} className="flex items-center gap-2 text-gray-600 hover:text-blue-600">
                      <Phone className="w-4 h-4" />
                      {client.phone}
                    </a>
                  )}
                </div>
              </div>
            </div>
            <button
              onClick={handleExportPDF}
              className="px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center gap-2"
            >
              <Download className="w-4 h-4" />
              Exporter PDF
            </button>
          </div>
        </div>

        {/* Client Stats */}
        <div className="p-6 grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
            <div className="flex items-center gap-3 mb-3">
              <Building className="w-5 h-5 text-blue-600" />
              <p className="text-sm font-medium text-blue-900">Volume total</p>
            </div>
            <p className="text-2xl font-bold text-blue-900">{formatCurrency(totalStats.totalVolume)}</p>
            <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-blue-800 bg-white border border-blue-200 rounded-full">
              {formatEuro(totalStats.totalVolume)}
            </span>
          </div>

          <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
            <div className="flex items-center gap-3 mb-3">
              <DollarSign className="w-5 h-5 text-purple-600" />
              <p className="text-sm font-medium text-purple-900">Commission perçue par l'agence</p>
            </div>
            <p className="text-2xl font-bold text-purple-900">{formatCurrency(totalStats.totalAgencyCommission)}</p>
            <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-purple-800 bg-white border border-purple-200 rounded-full">
              {formatEuro(totalStats.totalAgencyCommission)}
            </span>
          </div>

          <div className="p-4 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50">
            <div className="flex items-center gap-3 mb-3">
              <Building className="w-5 h-5 text-green-600" />
              <p className="text-sm font-medium text-green-900">Unités achetées</p>
            </div>
            <p className="text-2xl font-bold text-green-900">{totalStats.totalUnits}</p>
            <p className="text-sm text-green-600 mt-2">
              Dans {totalStats.totalProjects.size} projet(s)
            </p>
          </div>

          <div className="p-4 bg-gradient-to-br from-amber-50 to-amber-100/50 rounded-xl border border-amber-200/50">
            <div className="flex items-center gap-3 mb-3">
              <TrendingUp className="w-5 h-5 text-amber-600" />
              <p className="text-sm font-medium text-amber-900">Moyenne par unité</p>
            </div>
            <p className="text-2xl font-bold text-amber-900">
              {formatCurrency(totalStats.totalVolume / totalStats.totalUnits)}
            </p>
            <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-amber-800 bg-white border border-amber-200 rounded-full">
              {formatEuro(totalStats.totalVolume / totalStats.totalUnits)}
            </span>
          </div>
        </div>
      </div>

      {/* Sales History */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-3">
            <Building className="w-6 h-6 text-blue-600" />
            <h2 className="text-xl font-semibold">Historique des achats</h2>
          </div>
        </div>

        <div className="divide-y divide-gray-200/50">
          {clientSales.map(renderSaleCard)}

          {clientSales.length === 0 && (
            <div className="text-center py-12">
              <Building className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500">Aucune vente trouvée</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}