// src/components/analytics/TopAgents.tsx
import React from 'react';
import { Users, Crown, Medal } from 'lucide-react';
import { useAgents } from '../../hooks/useAgents';
import { useSales } from '../../hooks/useSales';
import { formatCurrency } from '../../utils/format';
import Avatar from '../../components/ui/Avatar';
import { Link } from 'react-router-dom';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function TopAgents() {
  const { agents } = useAgents();
  const { sales, calculateCommission } = useSales();

  const agentPerformance = agents.map(agent => {
    const agentSales = sales.filter(sale => 
      sale.agentId === agent.id || sale.referrerId === agent.id
    );
    
    const totalStats = agentSales.reduce((acc, sale) => {
      const commission = calculateCommission(sale);
      if (sale.agentId === agent.id) {
        acc.agencyCommission += commission.agency;
      }
      if (sale.bonus) {
        acc.agencyCommission += commission.bonus.agency;
      }
      return acc;
    }, { agencyCommission: 0, salesCount: agentSales.length });

    return {
      agent,
      ...totalStats
    };
  }).sort((a, b) => b.agencyCommission - a.agencyCommission)
    .slice(0, 5);

  const getGradientStyle = (index: number) => {
    switch (index) {
      case 0:
        return 'bg-gradient-to-r from-yellow-50 to-amber-50 hover:from-yellow-100 hover:to-amber-100';
      case 1:
        return 'bg-gradient-to-r from-gray-50 to-slate-50 hover:from-gray-100 hover:to-slate-100';
      case 2:
        return 'bg-gradient-to-r from-orange-50 to-amber-50 hover:from-orange-100 hover:to-amber-100';
      case 3:
        return 'bg-gradient-to-r from-blue-50 to-indigo-50 hover:from-blue-100 hover:to-indigo-100';
      case 4:
        return 'bg-gradient-to-r from-purple-50 to-pink-50 hover:from-purple-100 hover:to-pink-100';
      default:
        return 'hover:bg-gray-50';
    }
  };

  return (
    <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
      <div className="p-6 border-b border-gray-200/50">
        <div className="flex items-center gap-3">
          <Users className="w-6 h-6 text-blue-600" />
          <h3 className="text-xl font-semibold">Top Agents</h3>
        </div>
      </div>

      <div className="divide-y divide-gray-100">
        {agentPerformance.map((data, index) => (
          <Link
            key={data.agent.id}
            to={`/agents/${data.agent.id}`}
            className={`block p-4 transition-all duration-200 ${getGradientStyle(index)}`}
          >
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-4">
                <div className="relative">
                  <Avatar name={data.agent.name} size="md" />
                  {index < 3 && (
                    <div className="absolute -top-1 -right-1 p-1.5 bg-white rounded-full shadow-sm">
                      {index === 0 ? (
                        <Crown className="w-4 h-4 text-yellow-500" />
                      ) : index === 1 ? (
                        <Medal className="w-4 h-4 text-gray-400" />
                      ) : (
                        <Medal className="w-4 h-4 text-amber-500" />
                      )}
                    </div>
                  )}
                </div>
                <div>
                  <h4 className="font-medium text-gray-900">{data.agent.name}</h4>
                  <p className="text-sm text-gray-500">{data.salesCount} ventes</p>
                </div>
              </div>
              <div className="text-right">
                <p className="text-lg font-bold text-gray-900">
                  <BlurredNumber value={formatCurrency(data.agencyCommission)} />
                </p>
                <p className="text-sm text-gray-500">Commission agence</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
