import React from 'react';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import AdGenerator from '../../components/marketing/AdGenerator';
import NewsStoryGenerator from '../../components/marketing/NewsStoryGenerator';
import { Image, Newspaper } from 'lucide-react';
import { useState } from 'react';

type MarketingTab = 'ads' | 'news';

export default function AgentMarketing() {
  const { agent } = useAgentSlug();
  const [activeTab, setActiveTab] = useState<MarketingTab>('ads');

  if (!agent) return null;

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-3">
            <Image className="w-6 h-6 text-blue-600" />
            <div>
              <h1 className="text-xl font-semibold">Marketing</h1>
              <p className="text-sm text-gray-500 mt-1">
                Créez des visuels publicitaires pour vos projets
              </p>
            </div>
          </div>
        </div>
        
        {/* Tabs */}
        <div className="p-4 border-t border-gray-200/50">
          <div className="flex gap-2">
            <button
              onClick={() => setActiveTab('ads')}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'ads'
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <Image className="w-4 h-4" />
              Publicités
            </button>
            <button
              onClick={() => setActiveTab('news')}
              className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'news'
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <Newspaper className="w-4 h-4" />
              News
            </button>
          </div>
        </div>
      </div>

      {/* Content */}
      {activeTab === 'ads' ? <AdGenerator /> : <NewsStoryGenerator />}
    </div>
  );
}