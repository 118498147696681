import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { Phone, Mail, Calendar, MessageSquare, Bot, ExternalLink, Edit } from 'lucide-react';
import type { Lead, LeadStage } from '../../types/leads';
import { formatDateDisplay } from '../../utils/date';
import LeadCard from './LeadCard';
import LeadDetailsModal from './LeadDetailsModal';

interface PipelineStageProps {
  stage: LeadStage;
  leads: Lead[];
  onUpdateLead: (id: string, updates: Partial<Lead>) => Promise<void>;
  onDeleteLead: (id: string) => Promise<void>;
  onAssignAgent: (leadId: string, agentId: string) => Promise<void>;
}

export const stageConfig = {
  new: {
    title: 'Nouveaux leads',
    color: 'blue'
  },
  contacted: {
    title: 'Contactés',
    color: 'purple'
  },
  meeting_scheduled: {
    title: 'RDV programmé',
    color: 'green'
  },
  negotiation: {
    title: 'En négociation',
    color: 'amber'
  },
  won: {
    title: 'Gagnés',
    color: 'green'
  },
  lost: {
    title: 'Perdus',
    color: 'red'
  }
} as const;

export default function PipelineStage({ 
  stage, 
  leads, 
  onUpdateLead, 
  onDeleteLead,
  onAssignAgent 
}: PipelineStageProps) {
  const config = stageConfig[stage];
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);

  const { setNodeRef, isOver } = useDroppable({
    id: stage,
    data: { type: 'stage', stage }
  });

  return (
    <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden h-full">
      <div className="p-4 border-b border-gray-200/50">
        <div className="flex items-center justify-between">
          <h3 className="font-medium">{config.title}</h3>
          <span className="px-2.5 py-1 text-sm font-medium bg-gray-100 rounded-full">
            {leads.length}
          </span>
        </div>
      </div>

      <div 
        ref={setNodeRef}
        className={`p-4 space-y-4 overflow-y-auto max-h-[calc(100vh-300px)] ${
          isOver ? 'bg-blue-50/50 border-2 border-blue-200 rounded-lg' : ''
        }`}
      >
        {leads.map((lead) => (
          <LeadCard
            key={lead.id}
            lead={lead}
            onClick={() => setSelectedLead(lead)}
          />
        ))}

        {leads.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            Aucun lead dans cette étape
          </div>
        )}
      </div>

      {selectedLead && (
        <LeadDetailsModal
          lead={selectedLead}
          onClose={() => setSelectedLead(null)}
          onUpdate={onUpdateLead}
          onDelete={onDeleteLead}
          onAssignAgent={onAssignAgent}
        />
      )}
    </div>
  );
}