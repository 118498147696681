// src/pages/AgentProfile.tsx
import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAgents } from '../hooks/useAgents';
import { useSales } from '../hooks/useSales';
import { differenceInDays } from 'date-fns';
import AgentProfileHeader from '../components/agent/AgentProfileHeader';
import AgentPerformanceReport from '../components/agent/AgentPerformanceReport';
import AgentSalesHistory from '../components/agent/AgentSalesHistory';
import ResetPasswordForm from '../components/auth/ResetPasswordForm';
import { Key } from 'lucide-react';

export default function AgentProfile() {
  const { id } = useParams<{ id: string }>();
  const { agents } = useAgents();
  const { sales, calculateCommission } = useSales();
  const [showResetPassword, setShowResetPassword] = useState(false);

  const agent = useMemo(() => agents.find(a => a.id === id), [agents, id]);
  
  const agentSales = useMemo(() => {
    if (!agent) return [];
    return sales
      .filter(sale => sale.agentId === agent.id || sale.referrerId === agent.id)
      .sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());
  }, [sales, agent]);

  const stats = useMemo(() => {
    if (!agent || !agentSales.length) {
      return {
        rank: 0,
        totalAgents: agents.length,
        agencyCommission: 0,
        bonus: 0,
        daysSinceLastSale: null,
        monthlyAverage: 0,
        totalVolume: 0,
        salesCount: 0
      };
    }

    // Calculate rank
    const rankings = agents
      .map(a => ({
        id: a.id,
        commission: sales
          .filter(s => s.agentId === a.id || s.referrerId === a.id)
          .reduce((sum, sale) => {
            const comm = calculateCommission(sale);
            return sum + comm.agency + (sale.bonus ? comm.bonus.agency : 0);
          }, 0)
      }))
      .sort((a, b) => b.commission - a.commission);

    const rank = rankings.findIndex(r => r.id === agent.id) + 1;

    // Calculate other stats
    const firstSale = agentSales[agentSales.length - 1];
    const lastSale = agentSales[0];
    const monthsDiff = differenceInDays(new Date(), new Date(firstSale.saleDate)) / 30;
    
    const totalStats = agentSales.reduce((acc, sale) => {
      const commission = calculateCommission(sale);
      acc.totalVolume += sale.propertyPrice;
      acc.agencyCommission += commission.agency;
      if (sale.bonus) {
        acc.bonus += commission.bonus.agency;
      }
      return acc;
    }, {
      totalVolume: 0,
      agencyCommission: 0,
      bonus: 0
    });

    return {
      rank,
      totalAgents: agents.length,
      agencyCommission: totalStats.agencyCommission,
      bonus: totalStats.bonus,
      daysSinceLastSale: lastSale ? differenceInDays(new Date(), new Date(lastSale.saleDate)) : null,
      monthlyAverage: agentSales.length / Math.max(1, monthsDiff),
      totalVolume: totalStats.totalVolume,
      salesCount: agentSales.length
    };
  }, [agent, agents, sales, agentSales, calculateCommission]);

  if (!agent) {
    return (
      <div className="max-w-7xl mx-auto p-6">
        <div className="bg-white rounded-xl p-6 text-center">
          <p className="text-gray-500">Agent non trouvé</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      <div className="flex items-center justify-between">
        <AgentProfileHeader agent={agent} />
        <button
          onClick={() => setShowResetPassword(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center gap-2"
        >
          <Key className="w-4 h-4" />
          Réinitialiser le mot de passe
        </button>
      </div>
      
      <AgentPerformanceReport {...stats} />
      <AgentSalesHistory sales={agentSales} calculateCommission={calculateCommission} />

      {showResetPassword && (
        <ResetPasswordForm
          agent={agent}
          onClose={() => setShowResetPassword(false)}
        />
      )}
    </div>
  );
}
