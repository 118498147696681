import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Image, Type, MessageSquare, MapPin, DollarSign, Download, Loader, Share2 } from 'lucide-react';
import html2canvas from 'html2canvas';
import { postToInstagram } from '../../services/instagramService';
import { useToast } from '../../contexts/ToastContext';

interface AdFormData {
  title: string;
  slogan: string;
  backgroundImage: File | null;
  backgroundImageUrl: string;
  price: string;
  location: string;
  features: string[];
}

type AdFormat = 'story' | 'square';

export default function AdGenerator() {
  const { showToast } = useToast();
  const [formData, setFormData] = useState<AdFormData>({
    title: '',
    slogan: '',
    backgroundImage: null,
    backgroundImageUrl: '',
    price: '',
    location: '',
    features: ['0% Tax', 'High ROI']
  });
  const [adFormat, setAdFormat] = useState<AdFormat>('story');
  const [isGenerating, setIsGenerating] = useState(false);
  const adRef = useRef<HTMLDivElement>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData({
        ...formData,
        backgroundImage: file,
        backgroundImageUrl: URL.createObjectURL(file)
      });
    }
  };

  const handleFeatureChange = (index: number, value: string) => {
    const newFeatures = [...formData.features];
    newFeatures[index] = value;
    setFormData({ ...formData, features: newFeatures });
  };

  const addFeature = () => {
    setFormData({
      ...formData,
      features: [...formData.features, '']
    });
  };

  const removeFeature = (index: number) => {
    const newFeatures = formData.features.filter((_, i) => i !== index);
    setFormData({ ...formData, features: newFeatures });
  };

  const handleDownload = async () => {
    if (!adRef.current) return;
    setIsGenerating(true);
    await generateAndDownload();
    setIsGenerating(false);
  };

  const handleInstagramShare = async () => {
    if (!adRef.current) return;
    setIsGenerating(true);

    try {
      const canvas = await html2canvas(adRef.current, {
        scale: 2,
        backgroundColor: null,
        logging: false
      });
      
      // Convert canvas to buffer
      const blob = await new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => resolve(blob!), 'image/jpeg', 0.95);
      });

      // Generate caption
      const caption = `${formData.title}\n${formData.slogan}\n\nPrix à partir de ${formData.price}\n\n#PalmDubai #Dubai #RealEstate`;

      // Post to Instagram
      await postToInstagram(blob, caption);
      showToast('success', 'Publication Instagram réussie');
    } catch (error) {
      console.error('Error sharing to Instagram:', error);
      showToast('error', 'Erreur lors de la publication Instagram');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Form */}
        <div className="space-y-6">
          <div className="bg-white rounded-xl border border-gray-200/50 overflow-hidden">
            <div className="p-6 border-b border-gray-200/50">
              <div className="flex items-center gap-3">
                <Image className="w-6 h-6 text-blue-600" />
                <h2 className="text-xl font-semibold">Générateur de publicité</h2>
              </div>
            </div>

            <div className="p-6 space-y-6">
              {/* Format Selection */}
              <div className="grid grid-cols-2 gap-4 p-1.5 bg-gray-100 rounded-xl">
                <button
                  onClick={() => setAdFormat('story')}
                  className={`px-4 py-2 rounded-lg transition-all ${
                    adFormat === 'story'
                      ? 'bg-white text-blue-600 shadow-sm'
                      : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  Story (9:16)
                </button>
                <button
                  onClick={() => setAdFormat('square')}
                  className={`px-4 py-2 rounded-lg transition-all ${
                    adFormat === 'square'
                      ? 'bg-white text-blue-600 shadow-sm'
                      : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  Carré (1:1)
                </button>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Titre du projet
                </label>
                <div className="relative">
                  <Type className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    value={formData.title}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="ex: Samana Resorts"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Slogan
                </label>
                <div className="relative">
                  <MessageSquare className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    value={formData.slogan}
                    onChange={(e) => setFormData({ ...formData, slogan: e.target.value })}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="ex: Piscine sur Balcon"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Image de fond
                </label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-blue-500 transition-colors">
                  <div className="space-y-1 text-center">
                    <Image className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="flex text-sm text-gray-600">
                      <label className="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500">
                        <span>Télécharger une image</span>
                        <input
                          type="file"
                          accept="image/*"
                          className="sr-only"
                          onChange={handleImageUpload}
                        />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG jusqu'à 10MB</p>
                  </div>
                </div>
                {formData.backgroundImageUrl && (
                  <div className="mt-2">
                    <img
                      src={formData.backgroundImageUrl}
                      alt="Preview"
                      className="h-20 w-20 object-cover rounded-lg"
                    />
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Prix
                </label>
                <div className="relative">
                  <DollarSign className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    value={formData.price}
                    onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="ex: 169.000€"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Localisation
                </label>
                <div className="relative">
                  <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  <input
                    type="text"
                    value={formData.location}
                    onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="ex: Dubai"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Caractéristiques
                </label>
                <div className="space-y-3">
                  {formData.features.map((feature, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <input
                        type="text"
                        value={feature}
                        onChange={(e) => handleFeatureChange(index, e.target.value)}
                        className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="ex: High ROI"
                      />
                      <button
                        onClick={() => removeFeature(index)}
                        className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={addFeature}
                    className="w-full px-4 py-2 text-sm text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
                  >
                    + Ajouter une caractéristique
                  </button>
                </div>
              </div>

              <div className="pt-4">
                <div className="flex gap-4">
                  <button
                    onClick={handleDownload}
                    disabled={isGenerating}
                    className="flex-1 px-4 py-3 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center justify-center gap-2"
                  >
                    {isGenerating ? (
                      <>
                        <Loader className="w-5 h-5 animate-spin" />
                        Génération...
                      </>
                    ) : (
                      <>
                        <Download className="w-5 h-5" />
                        Télécharger
                      </>
                    )}
                  </button>
                  <button
                    onClick={handleInstagramShare}
                    disabled={isGenerating}
                    className="flex-1 px-4 py-3 bg-gradient-to-r from-pink-600 to-purple-600 text-white rounded-lg hover:from-pink-700 hover:to-purple-700 transition-all duration-200 flex items-center justify-center gap-2"
                  >
                    <Share2 className="w-5 h-5" />
                    Partager sur Instagram
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Preview */}
        <div className="sticky top-8">
          <div 
            ref={adRef} 
            className={`${
              adFormat === 'story' ? 'aspect-[9/16]' : 'aspect-square'
            } rounded-3xl p-8 relative overflow-hidden bg-gradient-to-br from-blue-100 to-purple-100`}
          >
            {/* Background Image */}
            {formData.backgroundImageUrl && (
              <div 
                className="absolute inset-0 z-0"
                style={{
                  backgroundImage: `url(${formData.backgroundImageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <div className="absolute inset-0 bg-black/30" />
              </div>
            )}

            {/* Logo */}
            <div className="absolute -top-1 left-1/2 -translate-x-1/2 bg-black/80 backdrop-blur-sm px-4 py-2 flex items-center justify-center rounded-b-[32px] z-10">
              <img 
                src="https://palmdubai.fr/templates/Default/img/logo_white.svg"
                alt="Palm Logo"
                className="w-12 h-12 object-contain"
              />
            </div>

            {/* Website URL Watermark */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 text-white/80 text-sm font-medium tracking-wider z-10">
              www.PalmDubai.fr
            </div>

            {/* Content */}
            <div className="relative h-full flex flex-col items-center justify-center text-center text-white z-10">
              <motion.h1 
                className="text-5xl font-bold mb-1"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                {formData.title || 'SAMANA'}
              </motion.h1>
              <motion.h2
                className="text-6xl font-black tracking-wider mb-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className={`${
                  formData.backgroundImageUrl
                    ? 'text-white font-extrabold'
                    : 'bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent'
                } mb-8`}
              >
                {formData.slogan || 'RESORTS'}
              </motion.h2>

              {formData.location && (
                <motion.div
                  className="text-2xl font-light mt-4 mb-12"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  {formData.location}
                </motion.div>
              )}

              {/* Price */}
              {formData.price && (
                <motion.div
                  className="bg-black/60 backdrop-blur-sm text-white px-8 py-4 rounded-2xl mb-8 border border-white/10"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  <div className="text-sm mb-1">Prix à partir de</div>
                  <div className="text-4xl font-bold">{formData.price}</div>
                </motion.div>
              )}

              {/* Features */}
              <motion.div
                className="flex flex-wrap justify-center gap-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                {formData.features.map((feature, index) => (
                  <div
                    key={index}
                    className="bg-black/20 backdrop-blur-sm px-6 py-3 rounded-full text-white font-medium border border-white/20"
                  >
                    {feature}
                  </div>
                ))}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}