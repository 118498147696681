import React from 'react';
import { AlertTriangle, TrendingUp } from 'lucide-react';
import { motion } from 'framer-motion';

interface UnderperformingAlertProps {
  monthlyAverage: number;
}

export default function UnderperformingAlert({ monthlyAverage }: UnderperformingAlertProps) {
  if (monthlyAverage >= 2) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-br from-red-600 to-red-500 rounded-lg shadow-lg shadow-red-500/20 overflow-hidden relative transform hover:-translate-y-0.5 transition-all duration-300 h-12"
    >
      {/* Animated gradient background */}
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-white/10 to-white/5"
        animate={{
          opacity: [0.1, 0.2, 0.1],
          scale: [1, 1.02, 1],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="relative h-full p-3 flex items-center gap-3">
        <motion.div 
          className="p-1.5 bg-white/10 rounded-lg backdrop-blur-sm border border-white/20"
          animate={{
            scale: [1, 1.05, 1],
            rotate: [0, 3, 0, -3, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <TrendingUp className="w-4 h-4 text-white" />
        </motion.div>

        <div className="flex-1">
          <p className="text-sm font-medium text-white">
            Votre moyenne de <span className="font-bold">{monthlyAverage}</span> ventes 
            par mois est en dessous de l'objectif de <span className="font-bold">2 ventes</span> mensuelles
          </p>
        </div>
      </div>
      <motion.div
        className="absolute top-0 right-0 w-32 h-32 bg-red-400/5 rounded-full blur-2xl"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
    </motion.div>
  );
}