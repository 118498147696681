import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs, doc, setDoc, enableIndexedDbPersistence } from 'firebase/firestore';
import { COLLECTIONS, FIREBASE_CONFIG } from './config';

const firebaseConfig = {
  apiKey: "AIzaSyD85I8BK2vBD92xKGkDK68DXYFnYXjfoK4",
  authDomain: "realestatecrm-84f20.firebaseapp.com",
  projectId: "realestatecrm-84f20",
  storageBucket: "realestatecrm-84f20.appspot.com",
  messagingSenderId: "93743705924",
  appId: "1:93743705924:web:a76ff1c551d93f6d497111"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

// Enable offline persistence
try {
  enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Multiple tabs open, persistence enabled in first tab only');
    } else if (err.code === 'unimplemented') {
      console.warn('Browser doesn\'t support persistence');
    }
  });
} catch (error) {
  console.warn('Error enabling persistence:', error);
}

// Initialize leads collection with proper schema
async function initializeLeadsCollection() {
  try {
    const leadsRef = collection(db, COLLECTIONS.LEADS);
    const snapshot = await getDocs(leadsRef);
    
    if (snapshot.empty) {
      // Create schema document
      await setDoc(doc(leadsRef, '_schema'), {
        _type: 'schema',
        version: 1,
        fields: {
          name: 'string',
          email: 'string',
          phone: 'string',
          source: 'string',
          stage: 'string',
          notes: 'string',
          automationStatus: 'object',
          createdAt: 'timestamp',
          updatedAt: 'timestamp'
        },
        createdAt: new Date().toISOString()
      });
    }
  } catch (error) {
    console.error('Error initializing leads collection:', error);
  }
}

// Initialize collections with proper error handling
export async function initializeCollections() {
  try {
    // Initialize all required collections
    const collections = Object.values(COLLECTIONS).filter(Boolean);
    
    for (const collectionName of collections) {
      if (!collectionName) continue;
      
      // Special handling for leads collection
      if (collectionName === COLLECTIONS.LEADS) {
        await initializeLeadsCollection();
      } else if (FIREBASE_CONFIG.defaultCollections.includes(collectionName)) {
        const collectionRef = collection(db, collectionName);
        await getDocs(collectionRef);
      }
    }

    return true;
  } catch (error) {
    console.error('Error initializing collections:', error);
    throw error;
  }
}
