import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export async function postToInstagram(imageBlob: Blob, caption: string) {
  try {
    // Get Instagram credentials from settings
    const settingsRef = doc(db, 'settings', 'general');
    const settingsDoc = await getDoc(settingsRef);
    const settings = settingsDoc.data();

    if (!settings?.instagram?.accessToken) {
      throw new Error('Instagram access token not configured');
    }

    // Convert blob to base64
    const reader = new FileReader();
    const base64Image = await new Promise<string>((resolve) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    });

    // First create container
    const containerResponse = await fetch(`https://graph.facebook.com/v18.0/me/media`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${settings.instagram.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        image_url: base64Image,
        caption: caption
      })
    });

    const containerData = await containerResponse.json();
    if (!containerData.id) {
      throw new Error('Failed to create media container');
    }

    // Then publish the container
    const publishResponse = await fetch(`https://graph.facebook.com/v18.0/me/media_publish`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${settings.instagram.accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        creation_id: containerData.id
      })
    });

    const publishData = await publishResponse.json();
    return publishData.id;
  } catch (error) {
    console.error('Error posting to Instagram:', error);
    throw error;
  }
}