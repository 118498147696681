// src/pages/Dashboard.tsx
import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import KPICards from '../components/analytics/KPICards';
import TopAgents from '../components/analytics/TopAgents';
import TopProjects from '../components/analytics/TopProjects';
import UnderperformingAgents from '../components/analytics/UnderperformingAgents';
import LastSaleCard from '../components/LastSaleCard';
import AgencyRevenueHistory from '../components/analytics/AgencyRevenueHistory';
import { Calculator, Building } from 'lucide-react';
import { useSales } from '../hooks/useSales';
import { formatCurrency, formatEuro } from '../utils/format';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import BlurredNumber from '../components/ui/BlurredNumber';

export default function Dashboard() {
  const { sales, calculateCommission } = useSales();
  const startDate = new Date(2024, 6); // July 2024

  // Calculate total sales volume and count since July 2024
  const salesStats = sales.reduce((acc, sale) => {
    const saleDate = new Date(sale.createdAt);
    if (saleDate >= startDate) {
      acc.totalVolume += sale.propertyPrice;
      acc.totalCount += 1;
    }
    return acc;
  }, { totalVolume: 0, totalCount: 0 });

  // Get the most recent sale
  const lastSale = sales.length > 0 
    ? sales.sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime())[0]
    : null;

  // Calculate days since last sale
  const daysSinceLastSale = lastSale 
    ? formatDistanceToNow(new Date(lastSale.saleDate), { locale: fr, addSuffix: true })
    : 'Aucune vente';

  const revenueHistory = useMemo(() => {
    const monthlyData = sales.reduce((acc, sale) => {
      const date = new Date(sale.saleDate);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      const commission = calculateCommission(sale);
      const totalCommission = commission.agency + (sale.bonus ? commission.bonus.agency : 0);
      
      if (!acc[monthKey]) {
        acc[monthKey] = { revenue: 0, count: 0 };
      }
      acc[monthKey].revenue += totalCommission;
      acc[monthKey].count += 1;
      
      return acc;
    }, {} as Record<string, { revenue: number; count: number }>);

    // Convert to array and sort by date
    const sortedData = Object.entries(monthlyData)
      .map(([month, data]) => ({
        month,
        revenue: data.revenue,
        count: data.count
      }))
      .sort((a, b) => {
        const [monthA, yearA] = a.month.split(' ');
        const [monthB, yearB] = b.month.split(' ');
        return new Date(`${monthA} 1, ${yearA}`).getTime() - 
               new Date(`${monthB} 1, ${yearB}`).getTime();
      });

    // Calculate progression
    return sortedData.map((month, index) => {
      let progression = 0;
      if (index > 0) {
        const previousRevenue = sortedData[index - 1].revenue;
        if (previousRevenue > 0) {
          progression = ((month.revenue - previousRevenue) / previousRevenue) * 100;
        }
      }
      return { ...month, progression };
    });
  }, [sales, calculateCommission]);

  return (
    <div className="space-y-6">
      {/* Sales Stats & Last Sale Cards */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Total Sales Card */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-2xl overflow-hidden shadow-xl relative min-h-[400px]"
        >
          <div className="p-6 relative overflow-hidden h-full flex flex-col">
            {/* Animated background elements */}
            <motion.div 
              className="absolute top-0 right-0 w-64 h-64 bg-blue-500/10 rounded-full blur-3xl"
              animate={{ 
                scale: [1, 1.2, 1],
                opacity: [0.5, 0.8, 0.5] 
              }}
              transition={{ 
                duration: 8,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
            <motion.div 
              className="absolute bottom-0 left-0 w-64 h-64 bg-purple-500/10 rounded-full blur-3xl"
              animate={{ 
                scale: [1.2, 1, 1.2],
                opacity: [0.5, 0.8, 0.5] 
              }}
              transition={{ 
                duration: 8,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />

            <div className="relative flex-1">
              <div className="flex items-center gap-4 mb-8">
                <motion.div 
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: "spring", stiffness: 260, damping: 20 }}
                  className="p-4 bg-gradient-to-br from-blue-500/20 to-blue-600/20 rounded-xl border border-blue-500/20 backdrop-blur-sm"
                >
                  <Calculator className="w-8 h-8 text-blue-400" />
                </motion.div>
                <div>
                  <motion.h2 
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="text-xl font-semibold text-gray-200"
                  >
                    Nombre de ventes
                  </motion.h2>
                  <motion.p 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className="text-gray-400"
                  >
                    Depuis juillet 2024
                  </motion.p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 }}
                  className="space-y-4"
                >
                  <motion.div 
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", stiffness: 200, damping: 20 }}
                    className="text-7xl font-bold text-white"
                  >
                    <BlurredNumber value={salesStats.totalCount.toString()} />
                  </motion.div>
                  <div className="text-gray-400 text-lg">
                    ventes réalisées
                  </div>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.4 }}
                  className="space-y-4"
                >
                  <p className="text-gray-400">Volume total</p>
                  <motion.p 
                    className="text-1xl font-bold text-white"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                  >
                    <BlurredNumber value={formatCurrency(salesStats.totalVolume)} />
                  </motion.p>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6 }}
                    className="inline-block px-4 py-2 bg-blue-500/10 border border-blue-500/20 rounded-full text-blue-300 text-sm"
                  >
                    <BlurredNumber value={formatEuro(salesStats.totalVolume)} />
                  </motion.div>
                </motion.div>
              </div>
            </div>

            {/* Days since last sale */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
              className="mt-auto pt-6 border-t border-gray-700/50"
            >
              <div className="flex items-center justify-between">
                <span className="text-gray-400">Dernière vente</span>
                <span className="text-gray-300 font-medium">{daysSinceLastSale}</span>
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Last Sale Card */}
        {lastSale && <LastSaleCard sale={lastSale} />}
      </div>

      {/* KPI Cards */}
      <KPICards />

      {/* Agency Revenue History */}
      <AgencyRevenueHistory data={revenueHistory} />

      {/* Performance Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <TopAgents />
        <TopProjects />
      </div>

      {/* Underperforming Agents */}
      <UnderperformingAgents />
    </div>
  );
}
