// src/components/auth/ForgotPasswordForm.tsx
import React, { useState } from 'react';
import { Mail, ArrowLeft, Send } from 'lucide-react';
import { motion } from 'framer-motion';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { generatePassword, hashPassword } from '../../utils/crypto';
import { sendEmailNotification } from '../../services/notificationService';

interface ForgotPasswordFormProps {
  onBack: () => void;
}

export default function ForgotPasswordForm({ onBack }: ForgotPasswordFormProps) {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus('idle');

    try {
      // Find agent by email
      const agentsRef = collection(db, 'agents');
      const q = query(agentsRef, where('email', '==', email.toLowerCase()));
      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        setStatus('error');
        return;
      }

      const agent = {
        id: snapshot.docs[0].id,
        ...snapshot.docs[0].data()
      };

      // Generate new password
      const newPassword = generatePassword(12);
      const hashedPassword = await hashPassword(newPassword);

      // Update agent document
      await updateDoc(doc(db, 'agents', agent.id), {
        password: hashedPassword,
        updatedAt: new Date().toISOString()
      });

      // Send email notification
      await sendEmailNotification(agent, newPassword);

      setStatus('success');
    } catch (error) {
      console.error('Error resetting password:', error);
      setStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="max-w-md w-full"
    >
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-8">
        <button
          onClick={onBack}
          className="flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white transition-colors"
        >
          <ArrowLeft className="w-4 h-4" />
          Retour à la connexion
        </button>

        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            Mot de passe oublié?
          </h2>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            Entrez votre email pour réinitialiser votre mot de passe
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Email
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input
                id="email"
                name="email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full pl-10 px-4 py-3 bg-gray-50 dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors dark:text-white"
                placeholder="votre@email.com"
              />
            </div>
          </div>

          {status === 'success' && (
            <div className="bg-green-50 dark:bg-green-900/50 text-green-700 dark:text-green-200 p-4 rounded-xl text-sm">
              Un email contenant votre nouveau mot de passe a été envoyé.
            </div>
          )}

          {status === 'error' && (
            <div className="bg-red-50 dark:bg-red-900/50 text-red-700 dark:text-red-200 p-4 rounded-xl text-sm">
              Aucun compte trouvé avec cet email.
            </div>
          )}

          <button
            type="submit"
            disabled={isLoading}
            className="w-full flex items-center justify-center px-4 py-3 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl hover:from-blue-700 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all disabled:opacity-50 disabled:cursor-not-allowed font-medium"
          >
            {isLoading ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Envoi en cours...
              </>
            ) : (
              <>
                <Send className="w-5 h-5 mr-2" />
                Réinitialiser le mot de passe
              </>
            )}
          </button>
        </form>
      </div>
    </motion.div>
  );
}
