import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DollarSign, TrendingUp } from 'lucide-react';
import type { Sale } from '../../../types';
import { formatCurrency } from '../../../utils/format';
import { calculateCommission } from '../../../utils/commission';

interface AgentCommissionChartProps {
  sales: Sale[];
}

export default function AgentCommissionChart({ sales }: AgentCommissionChartProps) {
  const monthlyData = sales.reduce((acc, sale) => {
    const date = new Date(sale.saleDate);
    const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
    const commission = calculateCommission(sale);
    
    if (!acc[monthKey]) {
      acc[monthKey] = {
        month: monthKey,
        commission: 0
      };
    }
    
    if (sale.agentId === sale.agentId) {
      acc[monthKey].commission += commission.agent;
    } else if (sale.referrerId === sale.agentId) {
      acc[monthKey].commission += commission.referrer || 0;
    }
    
    return acc;
  }, {} as Record<string, { month: string; commission: number; }>);

  const data = Object.values(monthlyData).slice(-6);

  return (
    <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
      <div className="p-6 border-b border-gray-200/50">
        <div className="flex items-center gap-3">
          <DollarSign className="w-6 h-6 text-green-600" />
          <h2 className="text-lg font-semibold">Évolution des commissions</h2>
        </div>
      </div>
      
      <div className="p-6">
        <div className="h-[300px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis 
                dataKey="month" 
                tick={{ fontSize: 12 }}
                tickLine={false}
                axisLine={false}
              />
              <YAxis
                tick={{ fontSize: 12 }}
                tickLine={false}
                axisLine={false}
                tickFormatter={formatCurrency}
              />
              <Tooltip 
                formatter={(value: number) => [formatCurrency(value), 'Commission']}
              />
              <Area
                type="monotone"
                dataKey="commission"
                stroke="#22c55e"
                fill="url(#colorCommission)"
                strokeWidth={2}
              />
              <defs>
                <linearGradient id="colorCommission" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#22c55e" stopOpacity={0.1}/>
                  <stop offset="95%" stopColor="#22c55e" stopOpacity={0}/>
                </linearGradient>
              </defs>
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}