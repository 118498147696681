import type { Lead } from '../types/leads';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export async function handleSynthflowCall(lead: Lead): Promise<boolean> {
  try {
    // Get Synthflow settings
    const settingsRef = doc(db, 'settings', 'general');
    const settingsDoc = await getDoc(settingsRef);
    const settings = settingsDoc.data();

    if (!settings?.synthflow?.enabled || !settings?.synthflow?.apiKey) {
      console.log('Synthflow not configured or disabled');
      return false;
    }

    const response = await fetch('https://api.synthflow.com/v1/calls', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${settings.synthflow.apiKey}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        workspace_id: '1733562547007x899415973036032000',
        api_key: '1733562547616x972821354048436500',
        phone: lead.phone,
        name: lead.name,
        script: settings.synthflow.callScript.replace('{{name}}', lead.name)
      })
    });

    if (!response.ok) {
      throw new Error('Failed to initiate Synthflow call');
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error('Error making Synthflow call:', error);
    return false;
  }
}