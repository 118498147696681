import React, { createContext, useContext, useState, useEffect } from 'react';
import fr from '../locales/fr';
import en from '../locales/en';

interface Language {
  code: string;
  name: string;
  flag: string;
}

export const languages: Language[] = [
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'en', name: 'English', flag: '🇬🇧' }
];

const translations = { fr, en };

interface LanguageContextType {
  currentLanguage: Language;
  setLanguage: (code: string) => void;
  t: (key: string, params?: Record<string, string | number>) => string;
}

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(() => {
    const savedLang = localStorage.getItem('language');
    return languages.find(l => l.code === savedLang) || languages[0];
  });

  useEffect(() => {
    document.documentElement.lang = currentLanguage.code;
    localStorage.setItem('language', currentLanguage.code);
  }, [currentLanguage]);

  const setLanguage = (code: string) => {
    const language = languages.find(l => l.code === code);
    if (language) {
      setCurrentLanguage(language);
      localStorage.setItem('language', code);
      window.location.reload(); // Force reload to apply translations
    }
  };

  const t = (key: string, params?: Record<string, string | number>): string => {
    const keys = key.split('.');
    let value = translations[currentLanguage.code];
    
    for (const k of keys) {
      if (!value?.[k]) return key;
      value = value[k];
    }

    if (typeof value !== 'string') return key;

    if (params) {
      return value.replace(/\{\{(\w+)\}\}/g, (_, key) => 
        String(params[key] || `{{${key}}}`)
      );
    }

    return value;
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}