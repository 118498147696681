import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import type { LoginType } from '../types';

export function useAuth() {
  const context = useContext(AuthContext);
  
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return {
    ...context,
    login: (email: string, password: string, type: LoginType = 'agent') => 
      context.login(email, password, type)
  };
}