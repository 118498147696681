import { useState, useEffect } from 'react';
import { 
  collection, 
  onSnapshot,
  addDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  where
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useToast } from '../contexts/ToastContext';
import type { Lead } from '../types/leads';
import { handleSynthflowCall } from '../services/synthflow';
import { generateWhatsAppMessage } from '../services/openai';
import { triggerZapierWebhook } from '../services/zapier';

const LEADS_COLLECTION = 'leads';

export function useLeads() {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(true);
  const { showToast } = useToast();

  useEffect(() => {
    try {
      const leadsRef = collection(db, LEADS_COLLECTION);
      const q = query(
        leadsRef,
        orderBy('createdAt', 'desc')
      );
      
      const unsubscribe = onSnapshot(q, {
        next: (snapshot) => {
          const leadsData = snapshot.docs
            .map(doc => ({
              id: doc.id,
              ...doc.data()
            })) as Lead[];
          setLeads(leadsData);
          setLoading(false);
        },
        error: (error) => {
          console.error('Error fetching leads:', error);
          showToast('error', 'Erreur lors du chargement des leads');
          setLoading(false);
        }
      });
      
      return () => unsubscribe();
    } catch (error) {
      console.error('Error setting up leads listener:', error);
      showToast('error', 'Erreur lors de l\'initialisation');
      setLoading(false);
    }
  }, [showToast]);

  const addLead = async (leadData: Omit<Lead, 'id' | 'createdAt' | 'updatedAt' | 'automationStatus'>) => {
    try {
      const docRef = await addDoc(collection(db, LEADS_COLLECTION), {
        ...leadData,
        automationStatus: {
          call: false,
          message: false
        },
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });

      // Trigger automations
      try {
        // Zapier webhook
        await triggerZapierWebhook(leadData);

        // Synthflow call
        const callMade = await handleSynthflowCall(leadData);
        if (callMade) {
          await updateDoc(doc(db, 'leads', docRef.id), { 
            'automationStatus.call': true
          });
        }

        // WhatsApp message
        const messageSent = await generateWhatsAppMessage(leadData);
        if (messageSent) {
          await updateDoc(doc(db, 'leads', docRef.id), {
            'automationStatus.message': true
          });
        }
      } catch (automationError) {
        console.error('Error in automations:', automationError);
        showToast('error', "Erreur lors de l'exécution des automatisations");
      }

      showToast('success', 'Lead ajouté avec succès');
      return docRef.id;
    } catch (error) {
      console.error('Error adding lead:', error);
      showToast('error', 'Erreur lors de l\'ajout du lead');
      throw error;
    }
  };

  const updateLead = async (id: string, updates: Partial<Lead>) => {
    try {
      // Ensure the document exists first
      const leadRef = doc(db, LEADS_COLLECTION, id);
      
      // Add timestamps to updates
      const updatedData = {
        ...updates,
        updatedAt: new Date().toISOString()
      };

      await updateDoc(leadRef, updatedData);
      
      showToast('success', 'Lead mis à jour avec succès');
    } catch (error) {
      console.error('Error updating lead:', error);
      showToast('error', 'Erreur lors de la mise à jour du lead');
    }
  };

  const deleteLead = async (id: string) => {
    try {
      await deleteDoc(doc(db, LEADS_COLLECTION, id));
      showToast('success', 'Lead supprimé avec succès');
    } catch (error) {
      console.error('Error deleting lead:', error);
      showToast('error', 'Erreur lors de la suppression du lead');
      throw error;
    }
  };

  return {
    leads,
    loading,
    addLead,
    updateLead,
    deleteLead
  };
}