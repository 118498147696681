import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import type { UserRole } from '../../types';

interface PrivateRouteProps {
  children: React.ReactNode;
  requiredRole?: UserRole;
}

export default function PrivateRoute({ children, requiredRole }: PrivateRouteProps) {
  const { isAuthenticated, user, isLoading } = useAuth();
  const location = useLocation();
  const { agent, hasAccess } = useAgentSlug();

  // Allow access to agent pages without authentication
  if (location.pathname.startsWith('/agentcrm/') && agent) {
    if (!hasAccess) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    if (!hasAccess) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <>{children}</>;
  }

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!isAuthenticated || !user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const hasPermission = requiredRole ? (
    user.role === requiredRole || 
    user.role === 'admin' || 
    (requiredRole === 'agent' && user.role === 'superagent')
  ) : true;

  if (!hasPermission) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}