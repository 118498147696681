import React, { useState } from 'react';
import { X, Mail, Phone, User, Key } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { generatePassword } from '../utils/crypto';
import { sendEmailNotification, sendWhatsAppNotification } from '../services/notificationService';
import { useToast } from '../contexts/ToastContext';
import type { Agent } from '../types';

interface AgentFormProps {
  onSubmit: (agent: Omit<Agent, 'id' | 'totalSales' | 'totalCommission'>) => Promise<void>;
  onClose: () => void;
  initialData?: Agent;
  createAccount?: boolean;
}

export default function AgentForm({ onSubmit, onClose, initialData, createAccount }: AgentFormProps) {
  const { createUserAccount } = useAuth();
  const { showToast } = useToast();
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    email: initialData?.email || '',
    phone: initialData?.phone || '',
    avatar: initialData?.avatar || '',
    directAccess: initialData?.directAccess || true,
    createAccount: createAccount || false,
    changePassword: false,
    notifications: {
      email: true,
      whatsapp: true
    }
  });
  const [password, setPassword] = useState(generatePassword(12));
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      const agentData = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        avatar: formData.avatar,
        totalSales: initialData?.totalSales || 0,
        totalCommission: initialData?.totalCommission || 0,
        createdAt: initialData?.createdAt || new Date().toISOString(),
        updatedAt: new Date().toISOString()
      };

      await onSubmit(agentData);

      // Send notifications
      if (formData.createAccount || formData.changePassword) {
        try {
          if (formData.notifications.email) {
            await sendEmailNotification(
              { ...agentData, id: initialData?.id || '' } as Agent,
              password
            );
            showToast('success', 'Email de notification envoyé avec succès');
          }
          if (formData.notifications.whatsapp && formData.phone) {
            await sendWhatsAppNotification(
              { ...agentData, id: initialData?.id || '' } as Agent,
              password
            );
            showToast('success', 'Notification WhatsApp envoyée avec succès');
          }
        } catch (notifError) {
          console.error('Error sending notifications:', notifError);
          showToast('error', 'Erreur lors de l\'envoi des notifications');
        }
      }

      showToast('success', initialData ? 'Agent modifié avec succès' : 'Agent créé avec succès');
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error instanceof Error) {
        setError(error.message);
        showToast('error', error.message);
      } else {
        setError('Erreur lors de la création de l\'agent');
        showToast('error', 'Erreur lors de la création de l\'agent');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl w-full max-w-2xl p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold">
            {initialData ? 'Modifier l\'agent' : 'Nouvel agent'}
          </h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {error && (
            <div className="bg-red-50 text-red-600 p-4 rounded-lg text-sm">
              {error}
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Nom complet
              </label>
              <div className="relative">
                <User className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  id="name"
                  type="text"
                  required
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Nom et prénom"
                />
              </div>
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Email professionnel
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  id="email"
                  type="email"
                  required
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="email@palmdubaiimmo.com"
                />
              </div>
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
                Téléphone
              </label>
              <div className="relative">
                <Phone className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  id="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="+971 XX XXX XXXX"
                />
              </div>
            </div>
          </div>

          {/* Direct Access Toggle */}
          <div className="p-4 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50">
            <div className="flex items-center gap-3">
              <input
                type="checkbox"
                id="directAccess"
                checked={formData.directAccess}
                onChange={(e) => setFormData({ ...formData, directAccess: e.target.checked })}
                className="w-5 h-5 rounded border-gray-300 text-green-600 focus:ring-green-500"
              />
              <label htmlFor="directAccess" className="text-sm text-green-900">
                Autoriser l'accès direct sans authentification
              </label>
            </div>
          </div>

          {/* Account Settings */}
          {!initialData && (
            <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  id="createUserAccount"
                  checked={formData.createAccount}
                  onChange={(e) => setFormData({ ...formData, createAccount: e.target.checked })}
                  className="w-5 h-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="createUserAccount" className="text-sm text-blue-900">
                  Créer un compte utilisateur pour cet agent
                </label>
              </div>
            </div>
          )}

          {/* Password Section */}
          {(formData.createAccount || initialData) && (
            <div className="space-y-4">
              {initialData && (
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="changePassword"
                    checked={formData.changePassword}
                    onChange={(e) => {
                      setFormData({ ...formData, changePassword: e.target.checked });
                      if (e.target.checked) {
                        setPassword(generatePassword(12));
                      }
                    }}
                    className="w-4 h-4 rounded border-blue-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label htmlFor="changePassword" className="text-sm text-gray-700">
                    Générer un nouveau mot de passe
                  </label>
                </div>
              )}

              {(formData.createAccount || formData.changePassword) && (
                <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-2">
                      <Key className="w-4 h-4 text-gray-600" />
                      <label className="text-sm font-medium text-gray-700">
                        Mot de passe généré
                      </label>
                    </div>
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="text-sm text-blue-600 hover:text-blue-700"
                    >
                      {showPassword ? "Masquer" : "Afficher"}
                    </button>
                  </div>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      readOnly
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg bg-white font-mono text-sm"
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Notification Options */}
          {(formData.createAccount || formData.changePassword) && (
            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
                <div className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    id="sendEmail"
                    checked={formData.notifications.email}
                    onChange={(e) => setFormData({
                      ...formData,
                      notifications: { ...formData.notifications, email: e.target.checked }
                    })}
                    className="w-5 h-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label htmlFor="sendEmail" className="text-sm text-blue-900">
                    Envoyer par email
                  </label>
                </div>
              </div>

              <div className="p-4 bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl border border-green-200/50">
                <div className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    id="sendWhatsapp"
                    checked={formData.notifications.whatsapp}
                    onChange={(e) => setFormData({
                      ...formData,
                      notifications: { ...formData.notifications, whatsapp: e.target.checked }
                    })}
                    className="w-5 h-5 rounded border-gray-300 text-green-600 focus:ring-green-500"
                  />
                  <label htmlFor="sendWhatsapp" className="text-sm text-green-900">
                    Envoyer par WhatsApp
                  </label>
                </div>
              </div>
            </div>
          )}

          {/* Form Actions */}
          <div className="flex justify-end gap-3 pt-6 border-t border-gray-200/50">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
              disabled={isSubmitting}
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200"
            >
              {isSubmitting ? 'Enregistrement...' : initialData ? 'Mettre à jour' : 'Ajouter'} l'agent
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}