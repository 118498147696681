import React from 'react';
import { ArrowLeft, Mail, Phone } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '../ui/Avatar';
import type { Agent } from '../../types';

interface AgentProfileHeaderProps {
  agent: Agent;
}

export default function AgentProfileHeader({ agent }: AgentProfileHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200/50 overflow-hidden">
      <div className="p-4 sm:p-6">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
          <button
            onClick={() => navigate(-1)}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <ArrowLeft className="w-5 h-5" />
          </button>
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full">
            <Avatar name={agent.name} size="lg" />
            <div className="flex-1 min-w-0">
              <h1 className="text-xl sm:text-2xl font-bold truncate">{agent.name}</h1>
              <div className="mt-2 space-y-1">
                <a 
                  href={`mailto:${agent.email}`} 
                  className="flex items-center gap-2 text-gray-600 hover:text-blue-600 text-sm sm:text-base"
                >
                  <Mail className="w-4 h-4 flex-shrink-0" />
                  <span className="truncate">{agent.email}</span>
                </a>
                {agent.phone && (
                  <a 
                    href={`tel:${agent.phone}`} 
                    className="flex items-center gap-2 text-gray-600 hover:text-blue-600 text-sm sm:text-base"
                  >
                    <Phone className="w-4 h-4 flex-shrink-0" />
                    <span>{agent.phone}</span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}