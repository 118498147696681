import React from 'react';
import { Check, Clock } from 'lucide-react';
import { motion } from 'framer-motion';

interface PaymentToggleProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  date?: string;
}

export default function PaymentToggle({ label, checked, onChange, date }: PaymentToggleProps) {
  return (
    <div className="p-4 bg-white/80 backdrop-blur-sm rounded-xl border border-gray-100 hover:shadow-sm transition-all duration-200">
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-3">
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            className={`p-2 rounded-lg ${
              checked 
                ? 'bg-green-50 text-green-600' 
                : 'bg-amber-50 text-amber-600'
            }`}
          >
            {checked ? <Check className="w-4 h-4" /> : <Clock className="w-4 h-4" />}
          </motion.div>
          <span className="text-sm font-medium text-gray-800">{label}</span>
        </div>

        <button
          role="switch"
          aria-checked={checked}
          onClick={() => onChange(!checked)}
          className={`relative inline-flex h-7 w-[52px] items-center rounded-full transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
            checked 
              ? 'bg-green-500 focus:ring-green-500' 
              : 'bg-amber-200 focus:ring-amber-500'
          }`}
        >
          <motion.div
            initial={false}
            animate={{
              x: checked ? 26 : 2,
            }}
            transition={{ type: "spring", stiffness: 500, damping: 30 }}
            className="absolute h-6 w-6 rounded-full bg-white shadow-sm"
          />
          <span className="sr-only">{checked ? 'Activé' : 'Désactivé'}</span>
        </button>
      </div>

      {date && (
        <motion.p 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-2 text-xs text-gray-500 pl-9"
        >
          Payé le {new Date(date).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}
        </motion.p>
      )}
    </div>
  );
}