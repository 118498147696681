import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Building, Calendar, DollarSign, ArrowLeft, User, Mail, Phone } from 'lucide-react';
import { useSales } from '../../hooks/useSales';
import { useAgents } from '../../hooks/useAgents';
import Avatar from '../../components/ui/Avatar';
import { formatCurrency, formatEuro } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function AgentSaleDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { sales, calculateCommission } = useSales();
  const { agents } = useAgents();

  const sale = sales.find((s) => s.id === id);
  if (!sale) return <div>Vente non trouvée</div>;

  const agent = agents.find((a) => a.id === sale.agentId);
  const commission = calculateCommission(sale);

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center gap-4">
            <button
              onClick={() => navigate(-1)}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
            </button>
            <div>
              <h1 className="text-2xl font-bold">{sale.project}</h1>
              <p className="text-gray-500">Unité {sale.unitNumber}</p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Left Column */}
          <div className="space-y-6">
            {/* Property Info */}
            <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
              <div className="flex items-center gap-3 mb-4">
                <Building className="w-5 h-5 text-blue-600" />
                <h3 className="font-medium">Détails du bien</h3>
              </div>
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-gray-600 mb-1">Prix du bien</p>
                  <p className="text-2xl font-bold text-blue-900">
                    <BlurredNumber value={formatCurrency(sale.propertyPrice)} />
                  </p>
                  <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-blue-800 bg-white border border-blue-200 rounded-full">
                    <BlurredNumber value={formatEuro(sale.propertyPrice)} />
                  </span>
                </div>
                <div className="pt-4 border-t border-blue-200/50">
                  <p className="text-sm text-gray-600 mb-1">Date de vente</p>
                  <p className="font-medium">{formatDateDisplay(sale.saleDate)}</p>
                </div>
              </div>
            </div>

            {/* Client Info */}
            <div className="p-4 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
              <div className="flex items-center gap-3 mb-4">
                <User className="w-5 h-5 text-gray-600" />
                <h3 className="font-medium">Client</h3>
              </div>
              <div className="space-y-3">
                <h4 className="text-lg font-medium">{sale.client.name || 'Client non renseigné'}</h4>
                {sale.client.email && (
                  <a href={`mailto:${sale.client.email}`} className="flex items-center gap-2 text-gray-600 hover:text-blue-600">
                    <Mail className="w-4 h-4" />
                    {sale.client.email}
                  </a>
                )}
                {sale.client.phone && (
                  <a href={`tel:${sale.client.phone}`} className="flex items-center gap-2 text-gray-600 hover:text-blue-600">
                    <Phone className="w-4 h-4" />
                    {sale.client.phone}
                  </a>
                )}
                <div className="inline-block mt-2 px-3 py-1 bg-blue-50 text-blue-700 text-sm font-medium rounded-full">
                  Source: {sale.leadSource}
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            {/* Agent Commission */}
            <div className="p-6 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                  <Avatar name={agent?.name || ''} size="md" />
                  <div>
                    <h4 className="font-medium">{agent?.name}</h4>
                    <p className="text-sm text-gray-500">Agent principal</p>
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                <p className="text-3xl font-bold text-blue-900">{formatCurrency(commission.agent)}</p>
                <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-blue-800 bg-white border border-blue-200 rounded-full">
                  Estimation EUR : {formatEuro(commission.agent)}
                </span>
                <div className={`inline-block ml-2 px-3 py-1.5 text-sm font-medium rounded-full ${
                  sale.paymentStatus.agent
                    ? 'bg-green-50 text-green-700 border border-green-200'
                    : 'bg-amber-50 text-amber-700 border border-amber-200'
                }`}>
                  {sale.paymentStatus.agent ? 'Commission payée' : 'En attente de paiement'}
                </div>
              </div>
            </div>

            {/* Payment Status */}
            <div className="p-4 bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl border border-purple-200/50">
              <div className="flex items-center gap-3 mb-4">
                <DollarSign className="w-5 h-5 text-purple-600" />
                <h3 className="font-medium">Commission promoteur</h3>
              </div>
              <div className="space-y-2">
                <p className="text-2xl font-bold text-purple-900">{formatCurrency(sale.developerCommission)}</p>
                <span className="inline-block mt-2 px-2.5 py-1 text-xs font-medium text-purple-800 bg-white border border-purple-200 rounded-full">
                  Estimation EUR : {formatEuro(sale.developerCommission)}
                </span>
                <div className={`inline-block ml-2 px-3 py-1.5 text-sm font-medium rounded-full ${
                  sale.paymentStatus.developer
                    ? 'bg-green-50 text-green-700 border border-green-200'
                    : 'bg-amber-50 text-amber-700 border border-amber-200'
                }`}>
                  {sale.paymentStatus.developer ? 'Commission reçue' : 'En attente de réception'}
                </div>
              </div>
            </div>

            {/* Commission Split Info */}
            {sale.commissionType === 'shared_three_way' && (
              <div className="p-4 bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl border border-blue-200/50">
                <div className="flex items-center gap-3 mb-4">
                  <DollarSign className="w-5 h-5 text-blue-600" />
                  <h3 className="font-medium">Répartition de la commission</h3>
                </div>
                <div className="space-y-4">
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600">Agent principal (30%)</span>
                    <span className="font-medium">{formatCurrency(commission.agent)}</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-600">Agent secondaire (30%)</span>
                    <span className="font-medium">{formatCurrency(commission.referrer || 0)}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}