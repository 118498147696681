import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { useSales } from '../../hooks/useSales';
import { Building, Search, Filter, Calendar, DollarSign, Gift } from 'lucide-react';
import { formatCurrency } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function AgentSales() {
  const { agent, agentId, slug } = useAgentSlug();
  const { sales, calculateCommission } = useSales();
  const [searchQuery, setSearchQuery] = useState('');
  const [filterBy, setFilterBy] = useState<'all' | 'project' | 'status'>('all');

  if (!agent) return null;

  // Filter and sort sales
  const filteredSales = sales
    .filter(sale => sale.agentId === agent.id || sale.referrerId === agent.id)
    .filter(sale => {
      if (!searchQuery) return true;
      const query = searchQuery.toLowerCase();

      switch (filterBy) {
        case 'project':
          return sale.project.toLowerCase().includes(query);
        case 'status':
          const status = sale.agentId === agent.id ? 
            sale.paymentStatus.agent : 
            sale.paymentStatus.referrer;
          return status ? 'payée'.includes(query) : 'en attente'.includes(query);
        default:
          return (
            sale.project.toLowerCase().includes(query) ||
            sale.unitNumber.toLowerCase().includes(query)
          );
      }
    })
    .sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());

  // Group sales by month
  const salesByMonth = filteredSales.reduce((acc, sale) => {
    const date = new Date(sale.saleDate);
    const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
    
    if (!acc[monthKey]) {
      acc[monthKey] = [];
    }
    acc[monthKey].push(sale);
    return acc;
  }, {} as Record<string, typeof filteredSales>);

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      {/* Header with Search */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-6 border-b border-gray-200/50">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <Building className="w-6 h-6 text-blue-600" />
              <div>
                <h1 className="text-2xl font-bold">Mes Ventes</h1>
                <p className="text-gray-500 mt-1">
                  {filteredSales.length} ventes au total
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Rechercher une vente..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="relative">
              <Filter className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              <select
                value={filterBy}
                onChange={(e) => setFilterBy(e.target.value as 'all' | 'project' | 'status')}
                className="appearance-none pl-10 pr-8 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
              >
                <option value="all">Tous les filtres</option>
                <option value="project">Projet</option>
                <option value="status">Statut</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Sales by Month */}
      <div className="space-y-8">
        {Object.entries(salesByMonth).map(([month, monthSales]) => (
          <div key={month} className="space-y-4">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold text-gray-900">
                {month}
              </h2>
              <span className="px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm font-medium">
                {monthSales.length} ventes
              </span>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {monthSales.map(sale => {
                const commission = calculateCommission(sale);
                const isMainAgent = sale.agentId === agent.id;
                const myCommission = isMainAgent ? commission.agent : commission.referrer;
                const isPaid = isMainAgent ? sale.paymentStatus.agent : sale.paymentStatus.referrer;
                const hasBonus = isMainAgent ? commission.bonus.agent > 0 : commission.bonus.referrer > 0;

                return (
                  <Link
                    key={sale.id}
                    to={`/agentcrm/${slug}/sales/${sale.id}`}
                    className="group bg-white hover:bg-gradient-to-br hover:from-blue-50/50 hover:to-blue-100/20 border border-gray-200/50 hover:border-blue-200/50 rounded-xl overflow-hidden transition-all duration-200 hover:shadow-lg"
                  >
                    <div className="p-6">
                      <div className="flex flex-col gap-4">
                        <div className="flex items-start justify-between">
                          <div>
                            <h3 className="text-lg font-semibold group-hover:text-blue-600 transition-colors">
                              {sale.project}
                            </h3>
                            <p className="text-sm text-gray-500">Unité {sale.unitNumber}</p>
                            <div className="mt-2 flex items-center gap-2 text-sm text-gray-500">
                              <Calendar className="w-4 h-4" />
                              <span>{formatDateDisplay(sale.saleDate)}</span>
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="flex items-center gap-2 mb-2">
                              <DollarSign className="w-4 h-4 text-blue-600" />
                              <p className="text-lg font-bold">
                                <BlurredNumber value={formatCurrency(myCommission || 0)} />
                              </p>
                            </div>
                            <div className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${
                              isPaid 
                                ? 'bg-green-50 text-green-700 border border-green-200' 
                                : 'bg-amber-50 text-amber-700 border border-amber-200'
                            }`}>
                              {isPaid ? 'Payée' : 'En attente'}
                            </div>
                          </div>
                        </div>

                        {hasBonus && (
                          <div className="flex items-center justify-between pt-3 border-t border-gray-200/50">
                            <div className="flex items-center gap-2 text-purple-600">
                              <Gift className="w-4 h-4" />
                              <span className="text-sm font-medium">Bonus</span>
                            </div>
                            <span className="font-medium text-purple-600">
                              <BlurredNumber value={formatCurrency(
                                isMainAgent ? commission.bonus.agent : commission.bonus.referrer
                              )} />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        ))}

        {filteredSales.length === 0 && (
          <div className="text-center py-12 bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl">
            <Building className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">Aucune vente trouvée</p>
          </div>
        )}
      </div>
    </div>
  );
}