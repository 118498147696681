export function formatCurrency(amount: number): string {
  // Format with spaces as thousand separators and AED suffix
  const formattedNumber = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
  
  return `${formattedNumber} AED`;
}

export function formatEuro(aedAmount: number): string {
  // AED to EUR conversion rate (approximate)
  const eurAmount = aedAmount * 0.25;
  
  // Format with spaces as thousand separators and € symbol
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
  }).format(eurAmount);
}

export function formatDate(date: string | Date): string {
  const d = new Date(date);
  return d.toLocaleDateString('fr-FR');
}

export function formatPhoneNumber(phone: string): string {
  return phone.replace(/[^0-9+]/g, '');
}