import React, { useState, useMemo } from 'react';
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import { Building, Plus, Search, Filter, Calendar, DollarSign, TrendingUp, TrendingDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import SaleForm from '../components/SaleForm';
import Avatar from '../components/ui/Avatar';
import { formatCurrency } from '../utils/format';
import { formatDateDisplay } from '../utils/date';
import { motion } from 'framer-motion';

type TabType = 'all' | 'received';

export default function Sales() {
  const { sales, addSale, calculateCommission } = useSales();
  const { agents } = useAgents();
  const [isAddingSale, setIsAddingSale] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterBy, setFilterBy] = useState<'all' | 'project' | 'phone'>('all');
  const [activeTab, setActiveTab] = useState<TabType>('all');

  // Filter sales based on search query and active tab
  const filteredSales = useMemo(() => {
    return sales.filter(sale => {
      // Filter by tab
      if (activeTab === 'received' && !sale.paymentStatus.developer) {
        return false;
      }

      // Filter by search query
      if (!searchQuery) return true;

      const query = searchQuery.toLowerCase();
      switch (filterBy) {
        case 'project':
          return sale.project.toLowerCase().includes(query);
        case 'phone':
          return sale.client.phone?.toLowerCase().includes(query) || false;
        default:
          return (
            sale.project.toLowerCase().includes(query) ||
            sale.client.name?.toLowerCase().includes(query) ||
            sale.unitNumber.toLowerCase().includes(query)
          );
      }
    });
  }, [sales, searchQuery, filterBy, activeTab]);

  // Group sales by month
  const salesByMonth = useMemo(() => {
    const grouped: { [key: string]: typeof sales } = {};
    
    filteredSales.forEach(sale => {
      const date = new Date(sale.saleDate);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      
      if (!grouped[monthKey]) {
        grouped[monthKey] = [];
      }
      grouped[monthKey].push(sale);
    });
    
    return grouped;
  }, [filteredSales]);

  // Calculate monthly stats with progression
  const monthlyStats = useMemo(() => {
    const stats = new Map<string, { count: number; previousCount: number }>();
    
    Object.entries(salesByMonth).forEach(([month, monthSales]) => {
      const date = new Date(monthSales[0].saleDate);
      const previousDate = new Date(date);
      previousDate.setMonth(date.getMonth() - 1);
      const previousMonthKey = previousDate.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      
      stats.set(month, {
        count: monthSales.length,
        previousCount: salesByMonth[previousMonthKey]?.length || 0
      });
    });
    
    return stats;
  }, [salesByMonth]);

  // Calculate progression percentage
  const getProgression = (month: string) => {
    const stats = monthlyStats.get(month);
    if (!stats || stats.previousCount === 0) return 0;
    return ((stats.count - stats.previousCount) / stats.previousCount) * 100;
  };

  return (
    <div className="max-w-7xl mx-auto space-y-6">
      {/* Header */}
      <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
        <div className="p-4 sm:p-6 border-b border-gray-200/50">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <div className="flex-1">
              <div className="flex items-center gap-3">
                <Building className="w-6 h-6 text-blue-600" />
                <div>
                  <h1 className="text-xl font-semibold">Ventes</h1>
                  <p className="text-sm text-gray-500 mt-1">
                    {filteredSales.length} ventes • Volume total : {formatCurrency(filteredSales.reduce((sum, sale) => sum + sale.propertyPrice, 0))}
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={() => setIsAddingSale(true)}
              className="w-full sm:w-auto px-4 py-2 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-lg hover:from-blue-700 hover:to-blue-600 transition-all duration-200 flex items-center justify-center gap-2"
            >
              <Plus className="w-5 h-5" />
              Nouvelle vente
            </button>
          </div>
        </div>

 
<div className="px-4 sm:px-6 pt-2">
  <div className="flex gap-2 p-1.5 bg-gradient-to-br from-gray-50 to-gray-100/50 rounded-xl border border-gray-200/50">
    <button
      onClick={() => setActiveTab('all')}
      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-200 ${
        activeTab === 'all'
          ? 'bg-gradient-to-br from-blue-50 to-blue-100/50 border border-blue-200/50 text-blue-600'
          : 'text-gray-600 hover:bg-white/50'
      }`}
    >
      <Building className="w-4 h-4" />
      <span className="text-sm font-medium">Toutes les ventes</span>
    </button>
    <button
      onClick={() => setActiveTab('received')}
      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-200 ${
        activeTab === 'received'
          ? 'bg-gradient-to-br from-green-50 to-green-100/50 border border-green-200/50 text-green-600'
          : 'text-gray-600 hover:bg-white/50'
      }`}
    >
      <DollarSign className="w-4 h-4" />
      <span className="text-sm font-medium">Commission reçue</span>
    </button>
  </div>
</div>


        {/* Search & Filters */}
        <div className="p-4 sm:p-6">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Rechercher une vente..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div className="relative">
              <select
                value={filterBy}
                onChange={(e) => setFilterBy(e.target.value as 'all' | 'project' | 'phone')}
                className="w-full sm:w-auto appearance-none pl-8 pr-10 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
              >
                <option value="all">Tous les filtres</option>
                <option value="project">Projet</option>
                <option value="phone">Téléphone</option>
              </select>
              <Filter className="absolute left-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
            </div>
          </div>
        </div>
      </div>

      {/* Sales by Month */}
      <div className="space-y-6 pb-20 lg:pb-0">
        {Object.entries(salesByMonth).map(([month, monthSales]) => {
          const progression = getProgression(month);
          const isPositive = progression > 0;
          const isNeutral = progression === 0;
          
          return (
            <div key={month} className="space-y-4">
              <div className="flex items-center gap-4">
                <h2 className="text-lg font-semibold text-gray-900">
                  Ventes : {month}
                </h2>
                <div className="flex items-center gap-2">
                  <span className="px-3 py-1 bg-gray-100 rounded-lg text-gray-700 font-medium">
                    {monthSales.length} ventes
                  </span>
                  {!isNeutral && (
                    <div className={`flex items-center gap-1 px-3 py-1 rounded-lg text-sm font-medium ${
                      isPositive 
                        ? 'text-green-700 bg-green-50 border border-green-100' 
                        : 'text-red-700 bg-red-50 border border-red-100'
                    }`}>
                      {isPositive ? (
                        <TrendingUp className="w-4 h-4" />
                      ) : (
                        <TrendingDown className="w-4 h-4" />
                      )}
                      <span>{Math.abs(progression).toFixed(0)}%</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {monthSales.map(sale => {
                  const agent = agents.find(a => a.id === sale.agentId);
                  const referrer = sale.referrerId ? agents.find(a => a.id === sale.referrerId) : null;

                  return (
                    <Link
                      key={sale.id}
                      to={`/sales/${sale.id}`}
                      className="block bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden hover:shadow-lg transition-all duration-200"
                    >
                      <div className="p-4 sm:p-6">
                        <div className="flex flex-col gap-4">
                          <div className="flex items-start justify-between">
                            <div>
                              <h3 className="text-lg font-semibold">{sale.project}</h3>
                              <p className="text-sm text-gray-500">Unité {sale.unitNumber}</p>
                              <p className="mt-2 font-medium">{sale.client.name}</p>
                            </div>
                            <div className="text-right">
                              <p className="text-lg font-bold">{formatCurrency(sale.propertyPrice)}</p>
                              <div className="mt-2 flex items-center gap-2">
                                <Calendar className="w-4 h-4 text-gray-400" />
                                <span className="text-sm text-gray-600">
                                  {formatDateDisplay(sale.saleDate)}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="flex items-center gap-4">
                            {agent && (
                              <div className="flex items-center gap-2">
                                <Avatar name={agent.name} size="sm" />
                                <div>
                                  <p className="text-sm font-medium">{agent.name}</p>
                                  <p className="text-xs text-gray-500">Agent principal</p>
                                </div>
                              </div>
                            )}
                            {referrer && (
                              <div className="flex items-center gap-2">
                                <Avatar name={referrer.name} size="sm" />
                                <div>
                                  <p className="text-sm font-medium">{referrer.name}</p>
                                  <p className="text-xs text-gray-500">Parrain</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="px-4 sm:px-6 py-3 bg-gray-50 border-t border-gray-200/50 flex items-center justify-between">
                        <div className="flex gap-2">
                          <div className={`px-3 py-1 rounded-full text-sm font-medium ${
                            sale.paymentStatus.developer
                              ? 'bg-green-100 text-green-800 border border-green-200'
                              : 'bg-amber-100 text-amber-800 border border-amber-200'
                          }`}>
                            {sale.paymentStatus.developer ? 'Commission reçue' : 'En attente'}
                          </div>
                        </div>
                        <div className="text-sm text-gray-500">
                          Livraison {sale.expectedDeliveryYear}
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          );
        })}

        {Object.keys(salesByMonth).length === 0 && (
          <div className="text-center py-12 bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl">
            <Building className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <p className="text-gray-500">Aucune vente trouvée</p>
          </div>
        )}
      </div>

      {/* Add Sale Modal */}
      {isAddingSale && (
        <SaleForm
          onSubmit={async (saleData) => {
            await addSale(saleData);
            setIsAddingSale(false);
          }}
          onClose={() => setIsAddingSale(false)}
        />
      )}
    </div>
  );
}