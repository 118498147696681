import { collection, getDocs, doc, updateDoc, setDoc } from 'firebase/firestore';
import { getAuth, updatePassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { db } from '../lib/firebase';
import { hashPassword } from './crypto';

const NEW_PASSWORD = 'LovePalmImmo';

export async function resetAllAgentPasswords() {
  const auth = getAuth();
  
  try {
    // Get all agents
    const agentsSnapshot = await getDocs(collection(db, 'agents'));
    
    const updatePromises = agentsSnapshot.docs.map(async (agentDoc) => {
      const agentData = agentDoc.data();
      const agentId = agentDoc.id;

      try {
        // Create or update Firebase Auth user
        let userCredential;
        try {
          userCredential = await createUserWithEmailAndPassword(auth, agentData.email, NEW_PASSWORD);
        } catch (authError: any) {
          // If user already exists, update their password
          if (authError.code === 'auth/email-already-in-use') {
            // For existing users, we need to sign them in first to update password
            // This would require admin SDK in a real backend
            console.log('User already exists, updating password...');
          }
        }

        // Update agent document with new password hash
        const hashedPassword = await hashPassword(NEW_PASSWORD);
        await updateDoc(doc(db, 'agents', agentId), {
          password: hashedPassword,
          updatedAt: new Date().toISOString()
        });

        // Create or update corresponding user document
        await setDoc(doc(db, 'users', agentId), {
          email: agentData.email,
          name: agentData.name,
          role: 'agent',
          agentId: agentId,
          password: hashedPassword,
          createdAt: agentData.createdAt || new Date().toISOString(),
          updatedAt: new Date().toISOString()
        }, { merge: true });

      } catch (error) {
        console.error(`Error updating agent ${agentId}:`, error);
      }
    });

    await Promise.all(updatePromises);
    return { success: true, message: 'All agent passwords have been reset successfully' };
  } catch (error) {
    console.error('Error resetting agent passwords:', error);
    throw new Error('Failed to reset agent passwords');
  }
}