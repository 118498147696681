import React from 'react';
import { Building, Calendar } from 'lucide-react';
import Autocomplete from '../ui/Autocomplete';
import { useAutocomplete } from '../../hooks/useAutocomplete';

interface PropertySectionProps {
  formData: {
    project: string;
    unitNumber: string;
    expectedDeliveryYear: number;
    saleDate: string;
    leadSource: string;
  };
  onChange: (data: Partial<PropertySectionProps['formData']>) => void;
}

export default function PropertySection({ formData, onChange }: PropertySectionProps) {
  const { projects } = useAutocomplete();

  const leadSources = [
    'Direct',
    'Référence',
    'Site web',
    'Instagram',
    'Facebook',
    'LinkedIn',
    'Property Finder',
    'Bayut',
    'Autre'
  ];

  return (
    <div className="bg-white rounded-xl border border-gray-200 p-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Building className="w-5 h-5 text-blue-600" />
        </div>
        <h3 className="text-lg font-semibold text-gray-900">Information du bien</h3>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <Autocomplete
          label="Projet"
          value={formData.project}
          onChange={(value) => onChange({ project: value })}
          suggestions={projects}
          placeholder="Nom du projet"
          required
        />

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Numéro d'unité
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            type="text"
            required
            value={formData.unitNumber}
            onChange={(e) => onChange({ unitNumber: e.target.value })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Ex: A-123"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Source du lead
            <span className="text-red-500 ml-1">*</span>
          </label>
          <select
            required
            value={formData.leadSource}
            onChange={(e) => onChange({ leadSource: e.target.value })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
          >
            <option value="">Sélectionner une source</option>
            {leadSources.map(source => (
              <option key={source} value={source}>{source}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Année de livraison
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            type="number"
            required
            min={new Date().getFullYear()}
            value={formData.expectedDeliveryYear}
            onChange={(e) => onChange({ expectedDeliveryYear: parseInt(e.target.value) })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Date de vente
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            type="date"
            required
            value={formData.saleDate}
            onChange={(e) => onChange({ saleDate: e.target.value })}
            className="w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>
    </div>
  );
}