import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Users, ChevronDown, Search } from 'lucide-react';
import { useAgents } from '../hooks/useAgents';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import Avatar from './ui/Avatar';
import { create } from 'zustand';

interface AgentViewStore {
  selectedAgentId: string | null;
  setSelectedAgentId: (id: string | null) => void;
}

export const useAgentViewStore = create<AgentViewStore>((set) => ({
  selectedAgentId: null,
  setSelectedAgentId: (id) => set({ selectedAgentId: id }),
}));

export default function AgentSelector() {
  const { user } = useAuth();
  const { agents } = useAgents();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { selectedAgentId, setSelectedAgentId } = useAgentViewStore();

  // Extract agentId from URL if in agent view
  useEffect(() => {
    const match = location.pathname.match(/\/agentcrm\/([^\/]+)/);
    if (match) {
      const slug = match[1];
      const agentId = slug?.split('-').pop();
      setSelectedAgentId(agentId || null);
    } else if (!location.pathname.includes('/agentcrm/')) {
      setSelectedAgentId(null);
    }
  }, [location.pathname, setSelectedAgentId]);

  // Only show for admin users
  if (user?.role !== 'admin') return null;

  const selectedAgent = agents?.find(a => a.id === selectedAgentId);
  
  const filteredAgents = agents?.filter(agent =>
    agent?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    agent?.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAgentSelect = (agentId: string | null) => {
    setSelectedAgentId(agentId);
    setIsOpen(false);

    if (agentId) {
      const agent = agents?.find(a => a.id === agentId);
      if (agent) {
        const slug = `${agent.name.toLowerCase().replace(/\s+/g, '-')}-${agentId}`;
        navigate(`/agentcrm/${slug}`);
      }
    } else {
      navigate('/');
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center gap-3 p-4 hover:bg-gray-50 rounded-xl transition-colors border border-gray-200/50"
      >
        <div className="p-2 bg-purple-50 rounded-lg">
          <Users className="w-5 h-5 text-purple-600" />
        </div>
        <div className="flex-1 text-left">
          <p className="text-sm font-medium text-gray-900">
            {selectedAgent ? 'Vue agent' : 'Sélectionner un agent'}
          </p>
          {selectedAgent?.name && (
            <p className="text-xs text-gray-500">{selectedAgent.name}</p>
          )}
        </div>
        <ChevronDown className={`w-4 h-4 text-gray-400 transition-transform duration-200 ${
          isOpen ? 'rotate-180' : ''
        }`} />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute z-50 left-0 right-0 mt-2 bg-white rounded-xl shadow-lg border border-gray-200/50 overflow-hidden"
          >
            <div className="p-2">
              <div className="relative mb-2">
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Rechercher un agent..."
                  className="w-full pl-9 pr-4 py-2 text-sm border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                />
              </div>

              <div className="max-h-64 overflow-y-auto">
                <button
                  onClick={() => handleAgentSelect(null)}
                  className={`w-full flex items-center gap-3 px-3 py-2 rounded-lg text-sm ${
                    !selectedAgentId
                      ? 'bg-purple-50 text-purple-900'
                      : 'hover:bg-gray-50 text-gray-700'
                  }`}
                >
                  <Users className="w-4 h-4" />
                  Vue administrateur
                </button>

                {filteredAgents.map(agent => (
                  agent && <button
                    key={agent.id}
                    onClick={() => handleAgentSelect(agent.id)}
                    className={`w-full flex items-center gap-3 px-3 py-2 rounded-lg text-sm ${
                      selectedAgentId === agent.id
                        ? 'bg-purple-50 text-purple-900'
                        : 'hover:bg-gray-50 text-gray-700'
                    }`}
                  >
                    <Avatar name={agent.name} size="sm" />
                    <div className="flex-1 text-left">
                      <p className="font-medium">{agent.name}</p>
                      {agent.email && <p className="text-xs text-gray-500">{agent.email}</p>}
                    </div>
                  </button>
                ))}

                {filteredAgents.length === 0 && (
                  <div className="text-center py-3 text-sm text-gray-500">
                    Aucun agent trouvé
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}