import React from 'react';
import { Trophy, Medal } from 'lucide-react';
import { useAgents } from '../../../hooks/useAgents';
import { useSales } from '../../../hooks/useSales';
import Avatar from '../../../components/ui/Avatar';

interface TopAgentsProps {
  currentAgentId: string;
}

export default function TopAgents({ currentAgentId }: TopAgentsProps) {
  const { agents } = useAgents();
  const { sales } = useSales();

  // Calculate rankings based on number of sales
  const rankings = agents
    .map(agent => {
      const agentSales = sales.filter(sale => 
        sale.agentId === agent.id || sale.referrerId === agent.id
      );
      
      return {
        agent,
        salesCount: agentSales.length
      };
    })
    .sort((a, b) => b.salesCount - a.salesCount)
    .slice(0, 3); // Only show top 3

  const getRankStyle = (index: number) => {
    switch (index) {
      case 0:
        return {
          icon: <Trophy className="w-5 h-5 text-yellow-600" />,
          bg: 'bg-yellow-50',
          border: 'border-yellow-200',
          text: 'text-yellow-800',
          badge: 'bg-yellow-100 border-yellow-200 text-yellow-800'
        };
      case 1:
        return {
          icon: <Medal className="w-5 h-5 text-gray-600" />,
          bg: 'bg-gray-50',
          border: 'border-gray-200',
          text: 'text-gray-800',
          badge: 'bg-gray-100 border-gray-200 text-gray-800'
        };
      case 2:
        return {
          icon: <Medal className="w-5 h-5 text-amber-600" />,
          bg: 'bg-amber-50',
          border: 'border-amber-200',
          text: 'text-amber-800',
          badge: 'bg-amber-100 border-amber-200 text-amber-800'
        };
      default:
        return {
          bg: 'bg-blue-50',
          border: 'border-blue-200',
          text: 'text-blue-800',
          badge: 'bg-blue-100 border-blue-200 text-blue-800'
        };
    }
  };

  return (
    <div className="bg-white/95 backdrop-blur-xl border border-gray-200/50 rounded-xl overflow-hidden">
      <div className="p-6 border-b border-gray-200/50">
        <div className="flex items-center gap-3">
          <Trophy className="w-6 h-6 text-blue-600" />
          <h2 className="text-lg font-semibold">Top 3 Agents</h2>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 divide-y sm:divide-y-0 sm:divide-x divide-gray-200/50">
        {rankings.map((ranking, index) => {
          const style = getRankStyle(index);
          return (
            <div 
              key={ranking.agent.id}
              className={`p-6 ${ranking.agent.id === currentAgentId ? 'bg-blue-50/50' : style.bg}`}
            >
              <div className="flex flex-col items-center text-center">
                <div className="relative mb-3">
                  <Avatar name={ranking.agent.name} size="lg" />
                  <div className="absolute -top-1 -right-1 p-1.5 bg-white rounded-full shadow-sm">
                    {style.icon}
                  </div>
                </div>
                <div>
                  <h3 className={`font-medium ${style.text}`}>
                    {ranking.agent.name}
                  </h3>
                  <div className={`inline-block mt-2 px-3 py-1 text-sm font-medium rounded-full border ${style.badge}`}>
                    #{index + 1}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}