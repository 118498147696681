import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import type { Agent, Sale } from '../types';
import { formatCurrency, formatEuro } from '../utils/format';
import { calculateCommission } from '../utils/commission';
import { formatDateDisplay } from '../utils/date';

export function generateSaleReport(sale: Sale, agent?: Agent, referrer?: Agent) {
  const doc = new jsPDF();
  const commission = calculateCommission(sale);

  // Add header
  doc.setFontSize(20);
  doc.text('Rapport de Vente', 105, 20, { align: 'center' });
  
  // Add sale info
  doc.setFontSize(12);
  doc.text('Détails de la vente:', 20, 40);
  
  const saleInfo = [
    ['Projet', sale.project],
    ['Unité', sale.unitNumber],
    ['Prix', `${formatCurrency(sale.propertyPrice)} (${formatEuro(sale.propertyPrice)})`],
    ['Date', formatDateDisplay(sale.saleDate)],
    ['Agent', agent?.name || 'N/A'],
    ['Parrain', referrer?.name || 'N/A'],
  ];

  autoTable(doc, {
    startY: 50,
    head: [],
    body: saleInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add commission info
  doc.text('Commissions:', 20, doc.lastAutoTable.finalY + 20);

  const commissionInfo = [
    ['Commission totale', `${formatCurrency(sale.developerCommission)} (${formatEuro(sale.developerCommission)})`],
    ['Commission agent', `${formatCurrency(commission.agent)} (${formatEuro(commission.agent)})`],
    ['Commission parrain', referrer ? `${formatCurrency(commission.referrer || 0)} (${formatEuro(commission.referrer || 0)})` : 'N/A'],
    ['Commission agence', `${formatCurrency(commission.agency)} (${formatEuro(commission.agency)})`]
  ];

  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 30,
    head: [],
    body: commissionInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add footer
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(8);
  doc.text(
    `Généré le ${formatDateDisplay(new Date().toISOString())}`,
    20,
    pageHeight - 20
  );
  doc.text('PalmDubai.fr', 105, pageHeight - 20, { align: 'center' });

  return doc;
}

export function generateClientReport(
  client: { name: string; email?: string; phone?: string },
  sales: Sale[],
  agents: Agent[],
  stats: {
    totalVolume: number;
    totalUnits: number;
    totalAgencyCommission: number;
    totalProjects: Set<string>;
  }
) {
  const doc = new jsPDF();

  // Add header with logo
  doc.setFontSize(20);
  doc.text('Rapport Client', 105, 20, { align: 'center' });

  // Add client info
  doc.setFontSize(12);
  doc.text('Informations client:', 20, 40);

  const clientInfo = [
    ['Nom', client.name],
    ['Email', client.email || 'Non renseigné'],
    ['Téléphone', client.phone || 'Non renseigné'],
  ];

  autoTable(doc, {
    startY: 50,
    head: [],
    body: clientInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add stats
  doc.text('Statistiques:', 20, doc.lastAutoTable.finalY + 20);

  const statsInfo = [
    ['Volume total', `${formatCurrency(stats.totalVolume)} (${formatEuro(stats.totalVolume)})`],
    ['Unités achetées', `${stats.totalUnits} dans ${stats.totalProjects.size} projet(s)`],
    ['Commission agence', `${formatCurrency(stats.totalAgencyCommission)} (${formatEuro(stats.totalAgencyCommission)})`],
    ['Moyenne par unité', `${formatCurrency(stats.totalVolume / stats.totalUnits)} (${formatEuro(stats.totalVolume / stats.totalUnits)})`]
  ];

  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 30,
    head: [],
    body: statsInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add sales table
  doc.text('Historique des achats:', 20, doc.lastAutoTable.finalY + 20);

  const salesData = sales.map(sale => {
    const commission = calculateCommission(sale);
    const agent = agents.find(a => a.id === sale.agentId);
    const referrer = sale.referrerId ? agents.find(a => a.id === sale.referrerId) : null;
    
    return [
      formatDateDisplay(sale.saleDate),
      sale.project,
      sale.unitNumber,
      formatCurrency(sale.propertyPrice),
      agent?.name || 'N/A',
      formatCurrency(commission.agent),
      referrer?.name || '-',
      referrer ? formatCurrency(commission.referrer || 0) : '-',
      formatCurrency(commission.agency),
      sale.paymentStatus.developer ? 'Reçue' : 'En attente'
    ];
  });

  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 30,
    head: [['Date', 'Projet', 'Unité', 'Prix', 'Agent', 'Comm. Agent', 'Parrain', 'Comm. Parrain', 'Comm. Agence', 'Statut']],
    body: salesData,
    theme: 'striped',
    styles: { fontSize: 8 },
    headStyles: { fillColor: [59, 130, 246] }
  });

  // Add footer
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(8);
  doc.text(
    `Généré le ${formatDateDisplay(new Date().toISOString())}`,
    20,
    pageHeight - 20
  );
  doc.text('PalmDubai.fr', 105, pageHeight - 20, { align: 'center' });

  return doc;
}

export function generateAgentReport(agent: Agent, sales: Sale[]) {
  const doc = new jsPDF();

  // Add header
  doc.setFontSize(20);
  doc.text('Rapport Agent', 105, 20, { align: 'center' });
  
  // Add agent info
  doc.setFontSize(12);
  doc.text('Informations agent:', 20, 40);
  
  const agentInfo = [
    ['Nom', agent.name],
    ['Email', agent.email],
    ['Téléphone', agent.phone || 'Non renseigné'],
  ];

  autoTable(doc, {
    startY: 50,
    head: [],
    body: agentInfo,
    theme: 'plain',
    styles: { fontSize: 10 },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 40 },
      1: { cellWidth: 100 }
    }
  });

  // Add sales table
  doc.text('Ventes:', 20, doc.lastAutoTable.finalY + 20);

  const salesData = sales.map(sale => {
    const commission = calculateCommission(sale);
    return [
      formatDateDisplay(sale.saleDate),
      sale.project,
      sale.unitNumber,
      formatCurrency(sale.propertyPrice),
      formatCurrency(commission.agent),
      formatCurrency(commission.agency),
      sale.paymentStatus.developer ? 'Reçue' : 'En attente'
    ];
  });

  autoTable(doc, {
    startY: doc.lastAutoTable.finalY + 30,
    head: [['Date', 'Projet', 'Unité', 'Prix', 'Commission', 'Commission Agence', 'Statut']],
    body: salesData,
    theme: 'striped',
    styles: { fontSize: 8 },
    headStyles: { fillColor: [59, 130, 246] }
  });

  // Add footer
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(8);
  doc.text(
    `Généré le ${formatDateDisplay(new Date().toISOString())}`,
    20,
    pageHeight - 20
  );
  doc.text('PalmDubai.fr', 105, pageHeight - 20, { align: 'center' });

  return doc;
}