// src/components/agent/AgentSalesHistory.tsx
import React, { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, DollarSign, Clock, Check } from 'lucide-react';
import type { Sale } from '../../types';
import { formatCurrency } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../ui/BlurredNumber';

interface AgentSalesHistoryProps {
  sales: Sale[];
  calculateCommission: (sale: Sale) => any;
}

type TabType = 'all' | 'paid' | 'pending';

export default function AgentSalesHistory({ sales, calculateCommission }: AgentSalesHistoryProps) {
  const [activeTab, setActiveTab] = useState<TabType>('all');

  const { filteredSales, stats } = useMemo(() => {
    const filtered = sales.filter(sale => {
      if (activeTab === 'paid') {
        return sale.paymentStatus.developer;
      }
      if (activeTab === 'pending') {
        return !sale.paymentStatus.developer;
      }
      return true;
    });

    const totals = sales.reduce((acc, sale) => {
      const commission = calculateCommission(sale);
      const agencyCommission = commission.agency;
      const bonusCommission = sale.bonus ? commission.bonus.agency : 0;
      const totalCommission = agencyCommission + bonusCommission;

      if (sale.paymentStatus.developer) {
        acc.paidCommission += totalCommission;
      } else {
        acc.pendingCommission += totalCommission;
      }

      return acc;
    }, {
      paidCommission: 0,
      pendingCommission: 0
    });

    return { filteredSales: filtered, stats: totals };
  }, [sales, activeTab, calculateCommission]);

  const tabs = [
    { 
      id: 'all' as const, 
      label: 'Toutes les ventes',
      icon: DollarSign,
      count: sales.length,
      color: 'blue'
    },
    { 
      id: 'paid' as const, 
      label: 'Commissions reçues',
      icon: Check,
      count: sales.filter(s => s.paymentStatus.developer).length,
      color: 'green'
    },
    { 
      id: 'pending' as const, 
      label: 'En attente',
      icon: Clock,
      count: sales.filter(s => !s.paymentStatus.developer).length,
      color: 'amber'
    }
  ];

 return (
    <div className="bg-white rounded-xl border border-gray-200/50 overflow-hidden">
      {/* Header Section */}
      <div className="p-4 sm:p-6 border-b border-gray-200/50">
        <div className="flex flex-col gap-4">
          <h2 className="text-xl font-semibold">Historique des ventes</h2>
          
          {/* Responsive Tab Menu */}
          <div className="flex flex-wrap sm:flex-nowrap gap-2 w-full">
            {tabs.map(tab => (
              <motion.button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  flex-1 min-w-0 px-3 sm:px-4 py-2 rounded-lg
                  transition-all duration-200 relative
                  ${activeTab === tab.id ? 'text-white' : 'text-gray-600 hover:text-gray-900'}
                `}
              >
                {activeTab === tab.id && (
                  <motion.div
                    layoutId="activeTabBackground"
                    className={`absolute inset-0 rounded-lg ${
                      tab.color === 'blue' ? 'bg-blue-600' :
                      tab.color === 'green' ? 'bg-green-600' :
                      'bg-amber-600'
                    }`}
                    transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                  />
                )}
                <span className="relative flex items-center justify-center gap-1.5 sm:gap-2 text-sm sm:text-base">
                  <tab.icon className="w-4 h-4 flex-shrink-0" />
                  <span className="font-medium truncate">{tab.label}</span>
                  <span className={`
                    px-1.5 sm:px-2 py-0.5 text-xs rounded-full whitespace-nowrap
                    ${activeTab === tab.id 
                      ? 'bg-white/20 text-white' 
                      : 'bg-white text-gray-600'}
                  `}>
                    {tab.count}
                  </span>
                </span>
              </motion.button>
            ))}
          </div>
        </div>
      </div>

      {/* Sales List */}
      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
          className="divide-y divide-gray-200/50"
        >
          {filteredSales.map(sale => {
            const commission = calculateCommission(sale);
            const agencyCommission = commission.agency;
            const bonusCommission = sale.bonus ? commission.bonus.agency : 0;
            const totalAgencyCommission = agencyCommission + bonusCommission;

            return (
              <motion.div
                key={sale.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="group p-4 hover:bg-gradient-to-r hover:from-gray-50/50 hover:to-transparent transition-all duration-300"
              >
                <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                  <div className="min-w-0">
                    <h3 className="font-medium group-hover:text-blue-600 transition-colors truncate">
                      {sale.project}
                    </h3>
                    <p className="text-sm text-gray-500">Unité {sale.unitNumber}</p>
                    <div className="flex items-center gap-2 mt-2 text-sm text-gray-500">
                      <Calendar className="w-4 h-4 flex-shrink-0" />
                      <span>{formatDateDisplay(sale.saleDate)}</span>
                    </div>
                  </div>
                  <div className="flex flex-row sm:flex-col items-center sm:items-end gap-3 sm:gap-2">
                    <p className="text-lg font-bold order-2 sm:order-1">
                      <BlurredNumber value={formatCurrency(totalAgencyCommission)} />
                    </p>
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      className={`
                        order-1 sm:order-2
                        inline-flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium
                        ${sale.paymentStatus.developer
                          ? 'bg-green-100 text-green-800 border border-green-200'
                          : 'bg-amber-100 text-amber-800 border border-amber-200'}
                      `}
                    >
                      {sale.paymentStatus.developer ? (
                        <>
                          <Check className="w-4 h-4" />
                          <span>Reçue</span>
                        </>
                      ) : (
                        <>
                          <Clock className="w-4 h-4" />
                          <span>En attente</span>
                        </>
                      )}
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            );
          })}

          {filteredSales.length === 0 && (
            <div className="p-12 text-center text-gray-500">
              Aucune vente trouvée
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}